import { quick } from "~/utils/app_state";
import type { LichessAuthState } from "~/utils/user_state";
import { SidebarTemplate } from "./SidebarTemplate";

export const LichessNeedNewScopes = (props: { authState: LichessAuthState }) => {
	return (
		<SidebarTemplate
			header="Connect your Lichess account"
			bodyPadding={true}
			actions={[
				{
					onPress: () => {
						quick((s) => {
							s.userState.authWithLichess(props.authState);
						});
					},
					style: "focus",
					text: "Continue",
				},
			]}
		>
			<p class="body-text">This is required to access your study content.</p>
		</SidebarTemplate>
	);
};
