import type { Move, PieceSymbol, Square } from "@lubert/chess.ts/dist/types";
import { pieceSymbolToPieceName } from "./plans";

export type MoveHint =
	| {
			type: "squares";
			squares: string[];
			description: string;
	  }
	| {
			type: "piece";
			piece: PieceSymbol;
			square: Square;
			description: string;
	  };

export enum HintTypes {
	file = "file",
	rank = "rank",
	piece = "piece",
	diagonal = "diagonal",
	square = "square",
}

export const getHintsForMove = (move: Move) => {
	const hints: MoveHint[] = [];
	hints.push({
		type: "piece",
		piece: move.piece,
		square: move.from as Square,
		description: `The ${move.color === "w" ? "White" : "Black"} ${pieceSymbolToPieceName(
			move.piece,
		)} on ${move.from}`,
	});
	return hints;
};
