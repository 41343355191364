import { createEffect, createSignal, onCleanup } from "solid-js";
import { UI } from "./app_state";

export const [chessboardFrozen, setChessboardFrozen] = createSignal(false);

export const freezeWhileShown = (component: any) => {
	createEffect(() => {
		setChessboardFrozen(UI().currentView()?.component === component);
	});
	onCleanup(() => {
		setChessboardFrozen(false);
	});
};
