import { isNil } from "lodash-es";
import type { SuggestedMoveDTO } from "~/rspc";
import { GameResultsDistribution } from "./GameResults";
import type { PositionReport } from "./PositionReport";
import type { StockfishEval } from "./StockfishEval";

export type SuggestedMove = Omit<SuggestedMoveDTO, "stockfish"> & { stockfish: StockfishEval };

export namespace SuggestedMove {
	export const getPlayRate = (
		m: SuggestedMove,
		report: PositionReport,
		masters?: boolean,
	): number | null => {
		const k = masters ? "masterResults" : "results";
		const total = GameResultsDistribution.getTotalGames(report[k]);
		const divisor = GameResultsDistribution.getTotalGames(m[k]);
		if (isNil(total) || isNil(divisor) || total === 0) {
			return null;
		}
		return divisor / total;
	};

	export const getGameResultsPlayRate = (
		m: SuggestedMove,
		report: PositionReport,
		masters?: boolean,
	): number | null => {
		const k = masters ? "masterResults" : "results";
		const total = GameResultsDistribution.getTotalGames(report[k]);
		const divisor = GameResultsDistribution.getTotalGames(m[k]);
		if (isNil(total) || isNil(divisor) || total === 0) {
			return null;
		}
		return divisor / total;
	};
}
