import { type JSX, createSignal, onMount } from "solid-js";
import { c, stylex } from "~/utils/styles";

export type AnimateSidebar = (dir: "left" | "right") => void;

export const SidebarSlideContainer = (props: {
	children: JSX.Element;
	setAnimateSidebar: (animateSidebar: AnimateSidebar) => void;
	class?: string;
}) => {
	onMount(() => {
		props.setAnimateSidebar((dir: "left" | "right") => {
			if (!previousRef() || !currentRef() || animating()) {
				return;
			}
			setAnimating(true);
			const clone = currentRef().cloneNode(true);
			previousRef().replaceChildren(clone);
			const ms = 200;
			const duration = `${ms}ms`;
			previousRef().style.transform = "translateX(0px)";
			currentRef().style.transform = dir === "right" ? "translateX(40px)" : "translateX(-40px)";
			previousRef().style.transition = "";
			currentRef().style.transition = "";
			previousRef().style.opacity = "1";
			currentRef().style.opacity = "0";
			previousRef().offsetHeight; /* trigger reflow */
			// previousRef().style.transition = `opacity ${duration} ease-in, transform ${duration} ease-in`;
			// currentRef().style.transition = `opacity ${duration} ease-out ${duration}, transform ${duration} ease-out ${duration}`;
			previousRef().style.transition = `opacity ${duration}, transform ${duration}`;
			currentRef().style.transition = `opacity ${duration}  ${duration}, transform ${duration} ${duration}`;
			currentRef().style.opacity = "1";
			currentRef().style.transform = "translateX(0px)";
			previousRef().style.opacity = "0";
			previousRef().style.transform = dir === "left" ? "translateX(40px)" : "translateX(-40px)";
			setTimeout(() => {
				previousRef().replaceChildren();
				setAnimating(false);
			}, ms);
		});
	});
	const [animating, setAnimating] = createSignal(false);
	// @ts-ignore
	const [previousRef, setPreviousRef] = createSignal<HTMLElement>(null);
	// @ts-ignore
	const [currentRef, setCurrentRef] = createSignal<HTMLElement>(null);

	return (
		<div style={stylex(c.column, c.fullWidth, c.displayGrid, c.grow, c.right(0))}>
			<div
				id="prev-sidebar"
				ref={setPreviousRef}
				style={stylex(c.keyedProp("grid-area")("1/1"), c.displayFlex, c.noPointerEvents, {
					"will-change": "transform, opacity",
				})}
			/>
			<div
				ref={setCurrentRef}
				style={stylex(c.keyedProp("grid-area")("1/1"), c.displayFlex, {
					"will-change": "transform, opacity",
				})}
				class={props.class}
			>
				{props.children}
			</div>
		</div>
	);
};
