import { For } from "solid-js";
import type { CourseOverviewDTO } from "~/rspc";
import { clsx } from "~/utils/classes";
import { CourseAvatar } from "./CourseAvatar";

export const CourseAvatarStack = (props: { courses: CourseOverviewDTO[] }) => {
	return (
		<div class="flex row gap-0 items-center">
			<For each={props.courses}>
				{(course, i) => (
					<div
						class={clsx(
							"border-[2px] border-solid border-gray-20 rounded-full",
							i() > 0 ? `ml-[-11px]` : ``,
						)}
						style={{ "z-index": props.courses.length - i() }}
					>
						<CourseAvatar course={course} size={20} />
					</div>
				)}
			</For>
		</div>
	);
};
