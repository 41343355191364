import { createSignal } from "solid-js";
import { REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import client from "~/utils/client";
import { c, stylex } from "~/utils/styles";
import { SidebarTemplate } from "./SidebarTemplate";
import { TextInput } from "./TextInput";
import { InputError } from "./forms/InputError";

import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-yup";
import { capitalize, find, some } from "lodash-es";
import * as yup from "yup";
import { SIDES, type Side } from "~/types/Side";
import type { FetchRepertoireResponse } from "~/utils/repertoire_state";
import { SidebarSelectOneOf } from "./SidebarSelectOneOf";
import { Spacer } from "./Space";

type Form = {
	name: string;
	side: Side;
};

export const AddRepertoire = () => {
	const onSubmit = (values: Form) => {
		setServerError("");
		client
			.post("/api/v1/openings/create", {
				side: values.side,
				name: values.name,
			})
			.then(({ data }: { data: FetchRepertoireResponse }) => {
				quick((s) => {
					s.repertoireState.repertoireFetched(data);
					UI().clearViews();
					const newRepertoire = find(data.repertoires, (r) => r.name === values.name);
					if (!newRepertoire) {
						console.error("New repertoire not found!");
						return;
					}
					UI().backOne();
				});
			})
			.catch((err) => {
				setServerError(err?.response?.data?.error ?? "Something went wrong");
			});
	};
	const {
		form,
		data: formData,
		setFields,
		errors,
		createSubmitHandler,
	} = createForm<Form>({
		initialValues: { name: "", side: "white" },
		onSubmit: onSubmit,
		extend: [
			validator({
				schema: yup.object({
					side: yup.string().label("Side").required(),
					name: yup.string().label("Name").max(255).min(4).required(),
				}),
			}),
		],
	});
	const handleSubmit = createSubmitHandler({
		onSubmit,
	});

	const [serverError, setServerError] = createSignal("");
	const hasMultipleRepertoires = () => REPERTOIRE_STATE().hasMultipleRepertoires();
	const hasUnfinishedRepertoires = () =>
		some(
			REPERTOIRE_STATE().browsingState.repertoireProgressState,
			(progress) => progress.completed,
		);
	return (
		<>
			<SidebarTemplate
				actions={[
					{
						onPress: () => {
							handleSubmit();
						},
						text: "Create repertoire",
						style: "focus",
					},
				]}
				header={"Add new repertoire"}
			>
				<div class="col items-center">
					<p class="body-text padding-sidebar">
						Separate repertoires should be used when you want something entirely different, like a
						repertoire specifically for blitz. Otherwise we recommend adding alternate moves to an
						existing repertoire.
					</p>
					{!hasMultipleRepertoires() && hasUnfinishedRepertoires() && (
						<p class="body-text padding-sidebar pt-4">
							We strongly recommend completing a repertoire for each side, before adding a second
							repertoire.
						</p>
					)}
					<Spacer between={["body-text", "form"]} />
					<div class={" w-full self-stretch"}>
						<div style={stylex(c.br(4), c.px(0), c.py(0))}>
							<form ref={form} class={"col gap-8"}>
								<SidebarSelectOneOf
									choices={SIDES}
									title="Which color is this repertoire for?"
									renderChoice={(side) => capitalize(side)}
									activeChoice={formData().side}
									onSelect={(side: Side) => {
										setFields("side", side);
									}}
								/>
								<div class="padding-sidebar">
									<TextInput
										placeholder="Blitz"
										setFields={setFields}
										type="text"
										name="name"
										label="Name"
										errors={errors()}
									/>
								</div>
								<InputError name={"Server error"} error={serverError()} class={"inline-block"} />
								<input type="submit" hidden />
							</form>
						</div>
					</div>
				</div>
			</SidebarTemplate>
		</>
	);
};
