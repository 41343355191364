import { Show, createSignal } from "solid-js";
import { USER_STATE } from "~/utils/app_state";
import { getStatic } from "~/utils/assets";
import { trackEvent } from "~/utils/trackEvent";
import { LoadingSpinner } from "./LoadingSpinner";
import { Spacer } from "./Space";
import { ToastPromotion, ToastPromotionType } from "./ToastPromotion";

export const FreeMonthPromotion = () => {
	const [loading, setLoading] = createSignal(false);
	return (
		<ToastPromotion flag={ToastPromotionType.FreeMonth}>
			<h1 class="text-lg text-primary font-semibold pt-8">Try Chessbook Pro for free!</h1>
			<Spacer height={36} />
			<div class=" aspect-[1/1] grow h-auto self-center">
				<img src={getStatic("/gift.svg")} />
			</div>
			<Spacer height={36} />
			<div
				class="bg-gray-20 font-medium text-center &hover:bg-gray-30 cursor-pointer px-4 py-4 rounded-sm text-sm"
				onClick={() => {
					trackEvent("promo.free_month.clicked");

					setLoading(true);
					USER_STATE().setFlag("free_month_dismissed", true);
					USER_STATE()
						.getCheckoutLink(false, { trial: true })
						.then((url) => {
							window.location.href = url;
						});
				}}
			>
				<Show when={loading()}>
					<LoadingSpinner />
				</Show>
				<Show when={!loading()}>Start your 1 month free trial</Show>
			</div>
		</ToastPromotion>
	);
};
