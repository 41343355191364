import { Show } from "solid-js";
import { USER_STATE } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { AdminDebugMode } from "~/utils/frontend_settings";

export const AdminOnly = (props: { children: any; class?: string }) => {
	return (
		<Show
			when={
				USER_STATE().user?.isAdmin &&
				USER_STATE().getFrontendSetting("adminDebugMode").value === AdminDebugMode.All
			}
		>
			<div class={clsx("border-orange-50 border-solid border-px", props.class)}>
				{props.children}
			</div>
		</Show>
	);
};
