import { onCleanup, onMount } from "solid-js";

export const onKeyPress = (fn: (key: KeyboardEvent["key"], event: KeyboardEvent) => void) => {
	const keydownListener = (event: KeyboardEvent) => {
		const key = event.key;
		// @ts-ignore
		const tagName = event.target?.tagName?.toLowerCase();
		if (tagName === "input" || tagName === "textarea") {
			return;
		}
		fn(key, event);
	};
	onMount(() => {
		document?.addEventListener("keydown", keydownListener);
	});
	onCleanup(() => {
		document?.removeEventListener("keydown", keydownListener);
	});
};
