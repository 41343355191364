import dayjs from "dayjs";
import { USER_STATE } from "./app_state";
import { rspcClient } from "./rspc_client";

let lastDateFinished: number | null = null;

export const dailyTaskFinished = () => {
	if (lastDateFinished && lastDateFinished === dayjs().date()) {
		return;
	}
	lastDateFinished = dayjs().date();
	let date = dayjs();
	const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	rspcClient
		.query(["streaks.dailyTaskFinished", { date: date.format(), timezone: userTimezone }])
		.then((data) => {
			USER_STATE().setUser(data);
		});
};
