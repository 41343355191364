import { Chess } from "@lubert/chess.ts";
import { Epd } from "~/types/Epd";
import { Side } from "~/types/Side";
import { StockfishEval } from "~/types/StockfishEval";
import { START_EPD, moveToLan } from "./chess";
import { isDevelopment } from "./env";
import { MultiCallback } from "./multi_callback";

let ENGINE: Worker | null = null;
export const EVAL_NODES = isDevelopment ? 400_000 : 400_000;
let hasCalledLoad = false;

export const loadStockfish = async () => {
	if (hasCalledLoad) {
		return;
	}
	hasCalledLoad = true;
	// ENGINE = new Worker("/js/stockfish-nnue-16.js");
	// note: the js loads the wasm
	ENGINE = new Worker("/js/stockfish-nnue-16-single.js");
	ENGINE.onmessage = (e) => {
		// console.debug("[stockfish] on message:", e.data);
		handleUciMessage(e.data);
	};
	ENGINE.onerror = (_e) => {
		// console.debug("[stockfish] on error:", e);
	};
	ENGINE.onmessageerror = (_e) => {
		// console.debug("[stockfish] on message error:", e);
	};
	Stockfish.uciCmd("uci");

	Stockfish.uciCmd("setoption name Use NNUE value true");
	Stockfish.onReady().then(() => {
		// console.log("setting memory!");
		Stockfish.setMemory(128);
		setTimeout(() => {
			Stockfish.getOption("Hash");
		}, 100);
	});
};

const EVAL_CACHE: Record<string, StockfishEval> = {};

const toEvalCacheKey = (epd: string, nodes: number) => `${epd}|${nodes}`;

export type StockfishMove = {
	san: string;
	stockfishEval: StockfishEval;
	final?: boolean;
};

export const Stockfish = {
	position: new Chess() as Chess | null,
	loaded: false,
	nodeGoal: EVAL_NODES,
	isReady: false,
	running: false,
	readyCallback: new MultiCallback<() => void>(),
	loadedCallback: new MultiCallback<() => void>(),
	evalUpdatedCallback: new MultiCallback<(_: StockfishEval) => void>(),
	topMovesUpdatedCallback: new MultiCallback<(_: StockfishMove[]) => void>(),
	topMovesFinalCallback: new MultiCallback<(_: StockfishMove[]) => void>(),
	finalEvalCallback: new MultiCallback<(_: StockfishEval) => void>(),
	okCallback: new MultiCallback<() => void>(),
	// epd to map of uci lan moves to san moves
	legalMoves: {} as Record<string, string>,
	uciCmd: (cmd: string) => {
		// console.debug("[Stockfish postMessage]", cmd);
		ENGINE?.postMessage(cmd);
	},
	getOption: (name: string) => {
		return Stockfish.uciCmd(`option name ${name} value`);
	},
	setOption: (name: string, value: string) => {
		Stockfish.uciCmd(`setoption name ${name} value ${value}`);
	},
	setMemory: (mb: number) => {
		Stockfish.setOption("Hash", `${mb}`);
	},
	multiPvSetting: 1,
	setMultiPv: (multipv: number) => {
		Stockfish.setOption("MultiPV", `${multipv}`);
		Stockfish.multiPvSetting = multipv;
	},
	getTopMoves: (
		epd: string,
		pv: number,
		progressCallback?: (moves: StockfishMove[]) => void,
	): Promise<StockfishMove[]> => {
		Stockfish.cancel();
		Stockfish.setMultiPv(pv);
		Stockfish.setPosition(epd);
		if (progressCallback) {
			Stockfish.topMovesUpdatedCallback.add(progressCallback);
		}
		Stockfish.go(epd, {});
		const promise = new Promise<StockfishMove[]>((resolve) => {
			Stockfish.topMovesFinalCallback.add((stockfishMoves) => {
				resolve(stockfishMoves);
			});
		});
		return promise;
	},
	setPosition: (epd: string) => {
		Stockfish.uciCmd(`position fen ${epd}`);
	},
	epd: null as string | null,
	go: async (
		epd: string,
		opts: {
			nodes?: number;
		},
	): Promise<StockfishEval> => {
		Stockfish.running = false;
		const position = new Chess(Epd.toFen(epd));
		const moves = position.moves({ verbose: true });
		moves.forEach((move) => {
			Stockfish.legalMoves[moveToLan(move)] = move.san;
		});
		// console.debug("[Stockfish] legal moves", Stockfish.legalMoves);
		Stockfish.position = position;
		Stockfish.epd = epd;
		Stockfish.uciCmd(`go nodes ${opts.nodes || EVAL_NODES}`);
		Stockfish.nodeGoal = opts.nodes ?? EVAL_NODES;
		Stockfish.uciCmd(`position fen ${epd}`);
		const promise = new Promise<StockfishEval>((resolve) => {
			Stockfish.finalEvalCallback.add((stockfishEval) => {
				Stockfish.running = false;
				resolve(stockfishEval);
			});
		});
		return promise;
	},
	evalPos: async ({
		epd,
		nodes,
		cb,
	}: {
		epd: string;
		nodes?: number;
		cb?: (res: StockfishEval) => void;
	}): Promise<StockfishEval> => {
		Stockfish.cancel();
		Stockfish.finalEvalCallback.clear();
		Stockfish.evalUpdatedCallback.clear();
		const nodesValue = nodes ?? EVAL_NODES;

		const cacheKey = toEvalCacheKey(epd, nodesValue);
		if (EVAL_CACHE[cacheKey]) {
			return EVAL_CACHE[cacheKey];
		}

		Stockfish.setMultiPv(1);
		Stockfish.setPosition(epd);
		let positiion = new Chess(Epd.toFen(epd));
		Stockfish.position = positiion;
		Stockfish.uciCmd(`go nodes ${nodesValue}`);
		Stockfish.nodeGoal = nodesValue;
		if (cb) {
			Stockfish.evalUpdatedCallback.add(cb);
		}
		const start = performance.now();
		const promise = new Promise<StockfishEval>((resolve) => {
			Stockfish.finalEvalCallback.add((stockfishEval) => {
				console.debug(`Stockfish eval for ${nodesValue} took ${performance.now() - start}ms`);
				EVAL_CACHE[cacheKey] = stockfishEval;
				resolve(stockfishEval);
			});
		});
		return promise;
	},
	ready: () => {
		// console.log("ready!");
		Stockfish.isReady = true;
		Stockfish.readyCallback.call();
	},
	onBestMove: (_uci: string) => {
		if (Stockfish.lastInfo) {
			// console.log("saying we found the best move!");
			Stockfish.onInfo(Stockfish.lastInfo!, true);
		}
	},
	onReady: (): Promise<void> => {
		return new Promise<void>((resolve) => {
			Stockfish.readyCallback.add(() => {
				resolve();
			});
		});
	},
	lastInfo: null as StockfishInfo | null,
	pendingMoves: [] as StockfishMove[],
	cancel: () => {
		Stockfish.uciCmd("stop");
		Stockfish.finalEvalCallback.clear();
		Stockfish.evalUpdatedCallback.clear();
		Stockfish.topMovesFinalCallback.clear();
		Stockfish.topMovesUpdatedCallback.clear();
		Stockfish.pendingMoves = [];
		Stockfish.lastInfo = null;
		Stockfish.running = false;
		Stockfish.epd = null;
	},
	onInfo: (info: StockfishInfo, final = false) => {
		const { stockfishEval, multipv, pvLan } = info;
		if (final) {
			stockfishEval.thinking = false;
		}
		Stockfish.evalUpdatedCallback.call(stockfishEval);
		if (final) {
			Stockfish.finalEvalCallback.call(stockfishEval);
		}
		Stockfish.lastInfo = info;
		// console.debug("parsed info", info, Stockfish.legalMoves);
		const stockfishMove: StockfishMove = {
			stockfishEval,
			san: Stockfish.legalMoves[pvLan]!,
		};
		// console.debug("stockfishMove", stockfishMove);
		if (multipv === 1) {
			Stockfish.pendingMoves = [];
		}
		const legalMoves = Stockfish.legalMoves;
		Stockfish.pendingMoves.push(stockfishMove);
		if (multipv === Stockfish.multiPvSetting || multipv === Object.keys(legalMoves).length) {
			if (final) {
				Stockfish.pendingMoves = Stockfish.pendingMoves.map((sm) => {
					sm.stockfishEval.thinking = false;
					return { ...sm, final: true };
				});
			}
			Stockfish.topMovesUpdatedCallback.call(Stockfish.pendingMoves);
			if (final) {
				Stockfish.topMovesFinalCallback.call(Stockfish.pendingMoves);
			}
		}
	},
};

type StockfishInfo = {
	stockfishEval: StockfishEval;
	multipv: number;
	pvLan: string;
	nodes?: number;
	pv?: string;
};

const handleUciMessage = (msg: any) => {
	// console.log("handleUciMessage", msg);
	if (msg === "uciok") {
		Stockfish.loaded = true;
		Stockfish.loadedCallback.call();
		Stockfish.uciCmd("isready");
		return;
	}
	if (msg === "readyok") {
		Stockfish.isReady = true;
		Stockfish.ready();
		return;
	}
	let match = msg.match(/^bestmove ([a-h][1-8][a-h][1-8][qrbn]?)/);
	/// Did the AI move?
	if (match) {
		Stockfish.running = false;
		Stockfish.onBestMove(match[1]);
		// game.move({ from: match[1], to: match[2], promotion: match[3] });
		// prepareMove();
		// uciCmd("eval", evaler);
		// evaluation_el.textContent = "";
		//uciCmd("eval");
		/// Is it sending feedback?
	} else {
		match = msg.match(/^info .*\bdepth (\d+) .*\bnps (\d+)/);
		// engineStatus.search = "Depth: " + match[1] + " Nps: " + match[2];
	}

	match =
		/^info .*\b multipv (?<multipv>\d+).*score (?<evalType>\w+).* (?<score>-?\d+).* nodes (?<nodes>\d+) .* pv (?<pv>\w+)/.exec(
			msg,
		);
	if (match) {
		const matches: Record<string, string> = match.groups;
		let score = Number.parseInt(matches.score);
		if (Stockfish.position?.turn() === "b") {
			score = -score;
		}
		const stockfishEval = new StockfishEval("0");
		stockfishEval.thinking = true;
		/// Is it measuring in centipawns?
		if (matches.evalType === "cp") {
			stockfishEval.eval = score;
		} else if (matches.evalType === "mate") {
			stockfishEval.eval = undefined;
			stockfishEval.mate = [
				score,
				score === 0
					? Side.fromColor(Stockfish.position?.turn() ?? "w")
					: score < 0
						? "black"
						: "white",
			];
		}
		let pv = matches.pv;
		stockfishEval.pv = pv;

		Stockfish.onInfo({
			stockfishEval,
			nodes: matches.nodes ? Number.parseInt(matches.nodes) : undefined,
			multipv: Number.parseInt(matches.multipv),
			pvLan: matches.pv,
			pv: pv,
		});
	}
};

const _sortaTest = async () => {
	console.debug("TESTING STOCKFISH");
	await Stockfish.onReady();
	console.debug("Stockfish ready!");
	console.debug("Evaluating move!");
	const finalEval = await Stockfish.evalPos({
		epd: START_EPD,
		cb: (res) => {
			console.debug("eval move res:", res);
		},
	});
	console.debug("Final eval:", finalEval);
	console.debug("------ TOP MOVES ------");
	const moves = await Stockfish.getTopMoves(START_EPD, 100, (moves) => {
		console.debug("moves in progress", moves);
	});
	console.debug("final moves", moves);
};

// sortaTest();
