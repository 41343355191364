import dayjs from "dayjs";
import { drop, dropWhile, takeWhile } from "lodash-es";
import { createSignal, onMount } from "solid-js";
import toast from "solid-toast";
import type { Side } from "~/types/Side";
import type { User } from "~/types/User";
import type { Uuid } from "~/types/Uuid";
import { APP_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import client from "~/utils/client";
import { pluralize } from "~/utils/pluralize";
import { trackEvent } from "~/utils/trackEvent";
import { SideDot } from "./SideDot";
import { SidebarTable } from "./SidebarTable";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";

export const SelectLichessStudy = (props: { repertoireId?: Uuid }) => {
	const [isLoading, setIsLoading] = createSignal(true);
	const [studies, setStudies] = createSignal([] as LichessStudy[]);
	onMount(() => {
		trackEvent("select_lichess_study.shown");
		fetchStudies(APP_STATE().userState.user!).then((studies) => {
			setStudies(studies);
			setIsLoading(false);
		});
	});
	return (
		<SidebarTemplate
			header="Which study would you like to import?"
			loading={isLoading()}
			actions={[]}
		>
			<p class="body-text padding-sidebar">
				You'll be able to select specific chapters in the next step.
			</p>
			<Spacer between={["body-text", "table"]} />

			<SidebarTable
				rows={studies()}
				onClick={(study: LichessStudy) => {
					UI().pushView(SelectLichessChapters, {
						props: {
							study: study,
							repertoireId: props.repertoireId,
						},
					});
				}}
				leftColumns={[
					{
						label: "Name",
						render: (study: LichessStudy) => {
							return study.name;
						},
						width: "auto",
					},
				]}
				rightColumns={[
					{
						label: "Created",
						width: 100,
						render: (study: LichessStudy) => {
							return formatDate(study.createdAt);
						},
					},
					{
						label: "Last update",
						width: 100,
						render: (study: LichessStudy) => {
							return formatDate(study.updatedAt);
						},
					},
				]}
			/>
		</SidebarTemplate>
	);
};

const formatDate = (date: number) => {
	let format = "MMM D";
	if (dayjs(date).year() !== dayjs().year()) {
		format = "MMM D, YYYY";
	}
	return <div>{dayjs(date).format(format)}</div>;
};

export const SelectLichessChapters = (props: { study: LichessStudy; repertoireId?: Uuid }) => {
	const [isLoading, setIsLoading] = createSignal(true);
	const [chapters, setChapters] = createSignal([] as LichessChapter[]);
	const repertoire = () => REPERTOIRE_STATE().repertoires?.[props.repertoireId!];
	onMount(() => {
		trackEvent("select_lichess_study.shown");
		fetchStudyChapters(APP_STATE().userState.user!, props.study).then((chapters) => {
			setChapters(chapters);
			setSelectedChapterIds(
				new Set(
					chapters
						.filter((c) => {
							if (repertoire()) {
								return c.orientation === repertoire()!.side;
							}
							return true;
						})
						.map((c) => c.id),
				),
			);
			setIsLoading(false);
		});
	});
	const [selectedChapterIds, setSelectedChapterIds] = createSignal(new Set([] as string[]));
	return (
		<SidebarTemplate
			header="Which chapters would you like to import?"
			loading={isLoading()}
			actions={
				selectedChapterIds().size === 0
					? []
					: [
							{
								onPress: () => {
									quick((s) => {
										setIsLoading(true);
										s.repertoireState
											.importIntoRepertoire({
												repertoireId: props.repertoireId,
												studyId: props.study.id,
												studyChapterIds: Array.from(selectedChapterIds()),
											})
											.then(() => {
												setIsLoading(false);
											});
									});
								},
								style: "focus",
								text: `Import ${pluralize(selectedChapterIds().size, "chapter")}`,
							},
						]
			}
		>
			<SidebarTable
				rows={chapters()}
				onClick={(chapter: LichessChapter) => {
					setSelectedChapterIds((ids) => {
						let clonedIds = new Set(ids);
						if (clonedIds.has(chapter.id)) {
							clonedIds.delete(chapter.id);
						} else {
							clonedIds.add(chapter.id);
						}
						return clonedIds;
					});
				}}
				isSelected={(chapter) => selectedChapterIds().has(chapter.id)}
				maxRows={null}
				leftColumns={[
					{
						label: "Chapter name",
						labelStyle: "hidden",
						render: (chapter: LichessChapter, { index }) => {
							return (
								<div class="flex space-x-2 row items-center">
									<SideDot side={chapter.orientation} size={8} />
									<p>{chapter.name ? chapter.name : `Chapter ${index}`}</p>
								</div>
							);
						},
						width: "auto",
					},
				]}
				rightColumns={[]}
			/>
		</SidebarTemplate>
	);
};

export interface LichessStudy {
	id: string;
	createdAt: number;
	updatedAt: number;
	name: string;
}

const fetchStudies = async (user: User): Promise<LichessStudy[]> => {
	return client
		.get(`https://lichess.org/api/study/by/${user.lichessUsername!}`, {
			headers: {
				authorization: `Bearer ${user.lichessToken}`,
			},
		})
		.then((resp) => {
			let lines = resp.data.split("\n");
			let studies = lines
				.map((line) => {
					if (!line) {
						return;
					}
					return JSON.parse(line);
				})
				.filter((study) => study);
			return studies as LichessStudy[];
		})
		.catch((err) => {
			console.warn("Error fetching studies", err);
			toast.error("Failed to fetch studies");
			return [];
		});
};

export interface LichessChapter {
	id: string;
	name: string;
	orientation: Side;
}

const fetchStudyChapters = async (user: User, study: LichessStudy): Promise<LichessChapter[]> => {
	return client
		.get(`https://lichess.org/api/study/${study.id}.pgn?orientation=true&source=true`, {
			headers: {
				authorization: `Bearer ${user.lichessToken}`,
			},
		})
		.then((resp) => {
			let pgn = resp.data;
			let lines = pgn.split("\n") as string[];
			let chapters: LichessChapter[] = [];
			while (lines.length > 0) {
				let headers = takeWhile(lines, (line: string) => line.startsWith("["));
				let headersString = headers.join("\n");

				let name = /\[Event "(?<name>.*)"]/.exec(headersString)?.groups?.name;
				name = drop(name!.split(":"), 1).join(":");
				let orientation = /\[Orientation "(?<orientation>.*)"]/.exec(headersString)?.groups
					?.orientation;
				let link = /\[Source "(?<source>.*)"]/.exec(headersString)?.groups?.source;
				let white = /\[White "(?<white>.*)"]/.exec(headersString)?.groups?.white;
				let black = /\[Black "(?<black>.*)"]/.exec(headersString)?.groups?.black;
				if (!name && white && black) {
					name = `${white} – ${black}`;
				}

				let id = link!.split("/").pop();
				lines = lines.slice(headers.length);
				lines = dropWhile(lines, (line: string) => !line.startsWith("["));
				chapters.push({
					id: id!,
					name: name!,
					orientation: orientation === "white" ? "white" : "black",
				});
			}
			return chapters;
		})
		.catch((err) => {
			console.warn("Error fetching studies", err);
			toast.error("Failed to fetch studies");
			return [];
		});
};
