import { destructure } from "@solid-primitives/destructure";
import { isEmpty, isNil } from "lodash-es";
import { Show, createEffect } from "solid-js";
import { EcoCode } from "~/types/EcoCode";
import { APP_STATE, useMode } from "~/utils/app_state";
import type { ChessboardInterface } from "~/utils/chessboard_interface";
import { clsx } from "~/utils/classes";
import { isChesscards } from "~/utils/env";
import { c, stylex } from "~/utils/styles";
import { FadeInOut } from "./FadeInOut";
import { MoveLog } from "./MoveLog";
import { initTooltip } from "./Tooltip";

export const ChessboardFooter = (props: {
	chessboard: ChessboardInterface;
}) => {
	const mode = useMode();
	const [currentLine, ecoName, ecoFullName] = destructure(() => {
		const currentLine = props.chessboard.getMoveLog();
		const currentEcoCode = APP_STATE().repertoireState.getLastEcoCode(
			props.chessboard.get((s) => s.positionHistory),
		);
		return [
			currentLine,
			currentEcoCode ? EcoCode.getAppropriateEcoName(currentEcoCode.fullName)[0] : null,
			currentEcoCode?.fullName,
		];
	});
	return (
		<FadeInOut
			style={stylex(c.row)}
			class={clsx("row max-w-full  items-center <md:padding-sidebar w-full <md:min-h-6")}
			open={
				(!isEmpty(currentLine()) &&
					(mode() === "browse" ||
						mode() === "review" ||
						mode() === "build" ||
						mode() === "model_games" ||
						mode() === "openings_report")) ||
				(isChesscards && !!props.chessboard.get((s) => s.associatedCard))
			}
		>
			<Show when={ecoName()}>
				{(ecoName) => {
					return (
						<>
							<p
								class="text-xs lg:text-sm text-tertiary lg:text-secondary font-semibold shrink-0"
								ref={(ref) => {
									createEffect(() => {
										if (isEmpty(ecoFullName())) return;
										initTooltip({
											ref,
											content: () => {
												return ecoFullName();
											},
											maxWidth: 240,
										});
									});
								}}
							>
								{ecoName()}
							</p>
						</>
					);
				}}
			</Show>
			<MoveLog hideLeftDivider={isNil(ecoName())} chessboard={props.chessboard} />
		</FadeInOut>
	);
};
