import { Dialog } from "@capacitor/dialog";
import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-yup";
import * as yup from "yup";
import { UI, USER_STATE, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { displayServerError } from "~/utils/displayServerError";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";
import { TextInput } from "./TextInput";

const schema = yup.object({
	email: yup
		.string()
		.required()
		.test("email-not-empty", "Please enter your email", (value) => {
			return value !== "";
		})
		.test("matches-user-email", "Email does not match your account", (value) => {
			if (value === "") {
				return true;
			}
			return value === USER_STATE().user?.email;
		}),
});

type DeleteAccountForm = yup.InferType<typeof schema>;

export const DeleteAccountView = () => {
	const onSubmit = (_values: DeleteAccountForm) => {
		return quick((s) => {
			s.userState
				.deleteAccount()
				.then(() => {
					Dialog.alert({
						title: "Account deleted!",
						message: "Your account has been deleted and you have been logged out.",
					}).then(() => {
						quick((_s) => {
							UI().backOne();
						});
					});
				})
				.catch((err) => {
					displayServerError(err);
				});
		});
	};

	const { form, setFields, errors, createSubmitHandler } = createForm<DeleteAccountForm>({
		initialValues: { email: "" },
		onSubmit,
		extend: validator({ schema }),
	});

	const handleSubmit = createSubmitHandler({ onSubmit });

	return (
		<SidebarTemplate
			actions={[
				{
					onPress: () => {
						handleSubmit();
					},
					text: "Delete my account",
					style: "primary",
				},
			]}
			header={"Delete your account?"}
			bodyPadding={true}
			loading={false}
		>
			<p class={clsx("text-secondary leading-5 pb-2")}>
				You will lose access to your repertoire, and will not be able to recover your account. Make
				sure to cancel your subscription before deleting your account.
			</p>
			<Spacer between={["body-text", "form"]} />
			<form ref={form}>
				<TextInput
					label="Type your email to confirm"
					placeholder={USER_STATE().user?.email ?? "example@example.com"}
					name="email"
					setFields={setFields}
					errors={errors()}
				/>
			</form>
		</SidebarTemplate>
	);
};
