import { some } from "lodash-es";
import { type SidebarAction, SidebarActions } from "./SidebarActions";

export const SelectMultiple = <T,>(props: {
	choices: T[];
	activeChoices: T[];
	equality?: (a: T, b: T) => boolean;
	renderChoice: (x: T, active: boolean, i: number) => SidebarAction;
}) => {
	return (
		<SidebarActions
			actions={props.choices.map((x, i) => {
				return props.renderChoice(
					x,
					some(props.activeChoices, (y) => x === y || props.equality?.(x, y)),
					i,
				);
			})}
		/>
	);
};
