import type { CourseOverviewDTO } from "~/rspc";
import { CourseAvatar } from "./CourseAvatar";
import { SidebarHeader } from "./RepertoireEditingHeader";

export const CourseTitle = (props: { course: CourseOverviewDTO }) => {
	return (
		<SidebarHeader>
			<div class="flex items-center space-x-4 row">
				<CourseAvatar course={props.course} size={40} />
				<p>{props.course.name}</p>
			</div>
		</SidebarHeader>
	);
};
