import { forEach } from "lodash-es";
import type { Side } from "./Side";

export type Line = string[];

export namespace Line {
	export function sideOfLastMove(_line: Line | string): Side {
		let line = _line;
		if (typeof _line === "string") {
			line = _line.split(" ");
		}
		if (line.length % 2 === 1) {
			return "white";
		}
		return "black";
	}

	export function toPgn(line: Line): string {
		let pgn = "";
		forEach(line, (m, j) => {
			const i = j / 2 + 1;
			if (j % 2 === 1) {
				pgn = `${pgn} ${m}`;
			} else {
				pgn = `${pgn} ${i}.${m}`;
			}
		});

		return pgn.trim();
	}

	export function fromPgn(line: string): Line {
		return line
			.replaceAll(/\d+\./g, "")
			.split(" ")
			.filter((x) => x !== "");
	}

	export function getMoveNumber(line: Line) {
		return Math.floor(line.length / 2 + 1);
	}
}
