import { BROWSING_STATE, REPERTOIRE_STATE, UI } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import { SidebarTemplate } from "./SidebarTemplate";
import { TextArea } from "./TextInput";

import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-yup";
import { destructure } from "@solid-primitives/destructure";
import { Show, onMount } from "solid-js";
import * as yup from "yup";
import { Kep } from "~/types/kep";
import { onBack } from "~/utils/signals/onBack";
import { MAX_ANNOTATION_LENGTH } from "./AnnotationEditor";
import { CheckboxInput } from "./CheckboxInput";

const schema = yup.object({
	annotation: yup.string().required().label("Annotation").max(MAX_ANNOTATION_LENGTH).min(5),
	saveToAll: yup.boolean().label("Save to all my repertoires"),
});

type AnnotationForm = yup.InferType<typeof schema>;

export const AnnotationEditSidebar = (props: { san: string; epd: string }) => {
	const chessboard = () => REPERTOIRE_STATE().getChessboard();
	const { annotation: currentAnnotation } = destructure(() => {
		let annotation = REPERTOIRE_STATE().getAnnotation(
			Kep.toKey({ epd: props.epd, san: props.san }),
		)?.text;
		return { annotation };
	});
	const onSubmit = (values: AnnotationForm) => {
		const repertoireId = values.saveToAll ? undefined : BROWSING_STATE().getActiveRepertoire()?.id;
		REPERTOIRE_STATE()
			.uploadMoveAnnotation({
				epd: props.epd,
				san: props.san,
				text: values.annotation,
				repertoireId,
			})
			.then(() => {
				UI().backOne();
			});
	};
	const { form, isSubmitting, createSubmitHandler, data, setFields, errors } =
		createForm<AnnotationForm>({
			initialValues: { annotation: currentAnnotation() ?? "", saveToAll: true },
			onSubmit: onSubmit,
			extend: validator({
				schema,
			}),
		});
	onMount(() => {
		chessboard().makeMove(props.san, { animate: true, sound: "move" });
	});
	onBack(() => {
		chessboard().backOne();
	});
	const handleSubmit = createSubmitHandler({
		onSubmit,
	});

	return (
		<>
			<SidebarTemplate
				actions={[
					{
						onPress: () => {
							handleSubmit();
						},
						text: "Save annotation",
						style: "focus",
					},
				]}
				header={"What are the ideas behind this move?"}
				loading={isSubmitting()}
			>
				<div class={clsx(isSubmitting() && "opacity-0")}>
					<div class="col items-center">
						<div class={"min-w-80 padding-sidebar w-full self-stretch"}>
							<div style={stylex(c.br(4), c.px(0), c.py(0))}>
								<form ref={form} class={"col gap-8"}>
									<TextArea
										errors={errors()}
										data={data}
										inputClass={"min-h-[100px]"}
										maxLength={MAX_ANNOTATION_LENGTH}
										name="annotation"
										placeholder={
											"e.g. Controlling the center and preparing to develop the queenside bishop"
										}
									/>
									<Show when={false}>
										<CheckboxInput
											setFields={setFields}
											errors={errors()}
											name="saveToAll"
											data={data}
											label={`Save this annotation to all my repertoires`}
											placeholder={
												"e.g. Controlling the center and preparing to develop the queenside bishop"
											}
										/>
									</Show>
									<input type="submit" hidden />
								</form>
							</div>
						</div>
					</div>
				</div>
			</SidebarTemplate>
		</>
	);
};
