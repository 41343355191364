import { createSignal, onCleanup } from "solid-js";
import type { RepertoireMove } from "~/types/RepertoireMove";
import { BROWSING_STATE, UI, quick } from "~/utils/app_state";
import { c, stylex } from "~/utils/styles";
import { Bullet } from "./Bullet";
import { SidebarTemplate } from "./SidebarTemplate";

export const DisableMoveConfirmation = (props: {
	move: RepertoireMove;
	disable: boolean;
}) => {
	const [loading, setLoading] = createSignal(false);
	const currentLine = () => BROWSING_STATE().chessboard.get((v) => v).moveLog;

	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	onCleanup(() => {
		quick((s) => {
			s.repertoireState.browsingState.chessboard.backOne();
		});
	});
	return (
		<SidebarTemplate
			loading={loading()}
			header={
				props.disable
					? `Disable ${props.move.sanPlus} and the moves after it?`
					: `Re-enable ${props.move.sanPlus}?`
			}
			bodyPadding
			actions={[
				{
					text: props.disable ? "Yes, disable these moves" : "Yes, re-enable",
					style: "primary",
					onPress: () => {
						setLoading(true);
						quick((s) => {
							s.repertoireState
								.toggleMove(props.move, props.disable, currentLine(), activeRepertoireId())
								.then(() => {
									quick((_s) => {
										UI().backOne();
									});
								});
						});
					},
				},
				{
					text: "No, I've changed my mind",
					style: "primary",
					onPress: () => {
						quick((_s) => {
							UI().backOne();
						});
					},
				},
			]}
		>
			<div>
				<p class="body-text">
					{props.disable ? (
						<>
							This means that:
							<div style={stylex(c.gridColumn({ gap: 8 }), c.pt(12))}>
								{[
									"These moves won't appear in practice",
									"They won't count towards your coverage goal",
								].map((bullet) => (
									<Bullet>{bullet}</Bullet>
								))}
							</div>
						</>
					) : (
						<>This move and the moves after it will start to show up for review again.</>
					)}
				</p>
			</div>
		</SidebarTemplate>
	);
};
