import { flatten, map } from "lodash-es";
import type { RepertoireDTO, RepertoireMoveDTO } from "~/rspc";
import type { RepertoireMove } from "./RepertoireMove";
import type { Replace } from "./Replace";
import type { Side } from "./Side";
import type { Uuid } from "./Uuid";

export type Repertoire = Replace<RepertoireDTO, RepertoireMoveDTO, RepertoireMove>;

export type ByRepertoireId<T> = Record<Uuid, T>;
export type ByRepertoireReviewId<T> = Record<Uuid | Side, T>;

export type Repertoires = Record<Uuid, Repertoire>;

export namespace Repertoire {
	export const isEmpty = (r: Repertoire): boolean => {
		return Object.keys(r.positionResponses).length === 0;
	};
	export function getAllEnabledRepertoiresMoves(r: Repertoires | undefined): RepertoireMove[] {
		if (!r) {
			return [];
		}
		return flatten(
			map(r, (r: Repertoire) => {
				return getAllEnabledRepertoireMoves(r);
			}),
		);
	}

	export function getAllEnabledRepertoireMoves(r: Repertoire | undefined): RepertoireMove[] {
		if (!r) {
			return [];
		}
		return flatten(Object.values(r.positionResponses)).filter((m) => !m.isDisabled);
	}
}
