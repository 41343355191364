export const APPLE_APP_ID = "6466343415";
export const APP_STORE_LINK = "https://apps.apple.com/us/app/chessbook/id6466343415";
export const PLAY_STORE_LINK =
	"https://play.google.com/store/apps/details?id=com.chessbook.android";

export const ALL_ELO_RANGES = [
	"0-1000",
	"1000-1200",
	"1200-1400",
	"1400-1600",
	"1600-1800",
	"1800-2000",
	"2000-2200",
	"2200-2500",
	"2500+",
];

export const DEFAULT_LICHESS_SCOPES = ["study:read"];
