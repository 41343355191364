import { cloneDeep, isEmpty } from "lodash-es";
import { type Accessor, For, Show, createEffect, onCleanup, onMount } from "solid-js";
import { Spacer } from "~/components/Space";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, quick, useMode } from "~/utils/app_state";
import { freezeWhileShown } from "~/utils/freeze_chessboard";
import { isMobile } from "~/utils/isMobile";
import { onBack } from "~/utils/signals/onBack";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { CMText } from "./CMText";
import { CollapsibleSidebarSection } from "./CollapsibleSidebarSection";
import type { SidebarAction } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTemplate } from "./SidebarTemplate";

export const TargetCoverageReachedView = (props: { auto: boolean }) => {
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const planSections = () => cloneDeep(BROWSING_STATE().planSections);
	const showPlansState = () => BROWSING_STATE().showPlansState;
	onCleanup(() => {
		BROWSING_STATE().chessboard.set((s) => {
			s.showPlans = false;
		});
	});
	onMount(() => {
		if (!REPERTOIRE_STATE().onboarding.isOnboarding) {
			BROWSING_STATE().chessboard.set((s) => {
				s.showPlans = true;
			});
		}
	});
	const actions = usePlayFromHereActions();
	createEffect(() => {
		if (onboarding().isOnboarding) {
			freezeWhileShown(TargetCoverageReachedView);
		}
	});
	onBack(() => {
		if (props.auto) {
			quick(() => {
				UI().backOne();
				BROWSING_STATE().chessboard.backOne();
			});
		} else {
			UI().backOne();
		}
	});

	const showPlayFromHere = () => !isEmpty(planSections()) && !onboarding().isOnboarding;
	const showPrompt = () => !showPlayFromHere() && !onboarding().isOnboarding;

	return (
		<SidebarTemplate
			header={
				onboarding().isOnboarding
					? "That's enough for now, let's save your progress"
					: showPlansState().coverageReached
						? "You've reached your coverage goal!"
						: "How to play from here"
			}
			actions={actions()}
			bodyPadding={false}
		>
			<Show when={onboarding().isOnboarding}>
				<p class="body-text padding-sidebar">
					You'll be able to come back to change these at any time.
				</p>
			</Show>
			<Show when={showPlayFromHere()}>
				<Show
					when={showPlansState().coverageReached && isMobile()}
					fallback={
						<div class="padding-sidebar">
							<PlayFromHere />
						</div>
					}
				>
					<CollapsibleSidebarSection header="How to play from here">
						<div class="padding-sidebar">
							<PlayFromHere />
						</div>
					</CollapsibleSidebarSection>
				</Show>
			</Show>
			<Show when={showPlayFromHere() && showPrompt()}>
				<Spacer between={["body-text", "body-text"]} />
			</Show>
			<Show when={showPrompt()}>
				<CMText
					style={stylex(c.weightRegular, c.fontSize(14), c.fg(c.colors.text.primary))}
					class="padding-sidebar"
				>
					Do you want to keep adding moves, or save your progress?
				</CMText>
			</Show>
		</SidebarTemplate>
	);
};

export const PlayFromHere = () => {
	const planSections = () => cloneDeep(BROWSING_STATE().planSections);

	return (
		<>
			<div class={"space-y-2 lg:space-y-4"}>
				<For each={planSections()}>
					{(section) => {
						return (
							<div style={stylex(c.row, c.alignStart)}>
								<i
									class="fa-solid fa-circle"
									style={stylex(c.fontSize(6), c.fg(c.gray[70]), c.mt(6))}
								/>
								<Spacer width={8} />
								<p class={"text-secondary text-sm leading-5"}>{section()}</p>
							</div>
						);
					}}
				</For>
			</div>
		</>
	);
};

const usePlayFromHereActions = () => {
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const mode = useMode();

	const actions: Accessor<SidebarAction[]> = () => {
		if (!BROWSING_STATE().hasPendingLineToAdd) {
			return [];
		}
		const acts: SidebarAction[] = [
			{
				onPress: () => {
					trackEvent(`${mode()}.save_line`);
					quick((s) => {
						s.repertoireState.browsingState.requestToAddCurrentLine();
					});
				},
				style: "focus",
				text: "Save these moves to my repertoire",
			},
		];
		if (onboarding().isOnboarding) {
			acts.push({
				onPress: () => {
					quick((s) => {
						trackEvent(`${mode()}.onboarding.start_over`);
						UI().popView();
						s.repertoireState.browsingState.chessboard.resetPosition();
					});
				},
				style: "primary",
				text: "I've changed my mind, start over",
			});
		}
		if (!onboarding().isOnboarding) {
			acts.push({
				onPress: () => {
					quick(() => {
						trackEvent(`${mode()}.plans_view.keep_adding`);
						animateSidebar("right");
						UI().cutView();
					});
				},
				style: "primary",
				text: "Keep adding moves",
			});
		}
		return acts;
	};
	return actions;
};
