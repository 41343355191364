export const formatPlayPercentage = (x: number) => {
	return `${(x * 100).toFixed(0)}%`;
};

export const formatWinPercentage = (x: number) => {
	return `${Math.round(x * 100)}`;
};

export const isNegligiblePlayrate = (playRate: number) => {
	return !playRate || Number.isNaN(playRate) || formatPlayPercentage(playRate) === "0%";
};
