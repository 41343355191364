import { every, isEmpty, some } from "lodash-es";
import { Show } from "solid-js";
import { ALL_ELO_RANGES } from "~/constants";
import type { Uuid } from "~/types/Uuid";
import { REPERTOIRE_STATE, USER_STATE, quick } from "~/utils/app_state";
import client from "~/utils/client";
import { onBack } from "~/utils/signals/onBack";
import { SelectMultiple } from "./SelectMultiple";
import { SidebarActions } from "./SidebarActions";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";

export const RepertoireChooseEloRanges = (props: {
	repertoireId: Uuid;
}) => {
	const repertoire = () => REPERTOIRE_STATE().repertoires![props.repertoireId]!;
	onBack(() => {
		quick((s) => {
			s.repertoireState.fetchRepertoire();
		});
	});
	return (
		<>
			<SidebarTemplate bodyPadding={false} actions={[]} header="What level is this repertoire for?">
				<p class="body-text padding-sidebar">
					This determines the moves you need to cover (based on what people at this level play).
				</p>
				<Spacer between={["body-text", "form"]} />
				<SelectMultiple
					equality={eloRangesEqual}
					choices={["ACCOUNT", ...ALL_ELO_RANGES] as (string | "ACCOUNT")[]}
					activeChoices={isEmpty(repertoire().eloRanges) ? ["ACCOUNT"] : repertoire().eloRanges!}
					renderChoice={(choice, active) => {
						let text = choice;
						if (choice === "ACCOUNT") {
							text = `My elo range (${USER_STATE().user!.eloRange})`;
						}
						return {
							text,
							onPress: () => {
								quick((s) => {
									let eloRanges = [...(repertoire().eloRanges ?? [])];
									if (some(eloRanges, (x) => eloRangesEqual(x, choice))) {
										eloRanges = eloRanges.filter((x) => x !== choice);
									} else {
										eloRanges = [...eloRanges, choice];
										eloRanges.sort();
									}
									if (choice === "ACCOUNT") {
										eloRanges = [];
									}
									s.repertoireState.repertoires![repertoire().id].eloRanges = eloRanges;
									client.post("/api/v1/openings/update", {
										eloRanges,
										repertoireId: props.repertoireId,
									});
									// .then(({ data }: { data: FetchRepertoireResponse }) => {
									// 	quick((s) => {
									// 		s.repertoireState.repertoireFetched(data);
									// 	});
									// });

									// todo: actually update repertoire
								});
							},
							off: !active,
							style: "secondary",
						};
					}}
				/>
				<Show when={repertoire().eloRanges.length < ALL_ELO_RANGES.length}>
					<Spacer between={["table", "actions"]} />
					<SidebarActions
						actions={[
							{
								onPress: () => {
									quick((s) => {
										s.repertoireState.repertoires![repertoire().id].eloRanges = ALL_ELO_RANGES;
										client.post("/api/v1/openings/update", {
											eloRanges: ALL_ELO_RANGES,
											repertoireId: props.repertoireId,
										});
									});
								},
								text: "Select all",
								style: "secondary",
							},
						]}
					/>
				</Show>
			</SidebarTemplate>
		</>
	);
};

const eloRangesEqual = (a: string, b: string) => {
	if (a === b) {
		return true;
	}
	if (every([a, b], (x) => x === "2100-2800" || x === "2100+")) {
		return true;
	}
	if (every([a, b], (x) => x === "2500-2800" || x === "2500+")) {
		return true;
	}
	return false;
};
