import type { Chess, Color, Move } from "@lubert/chess.ts";
import { isEmpty, noop } from "lodash-es";
import type { LichessPuzzle } from "~/types/LichessPuzzle";
import { isCheckmate } from "../utils/chess";
import type { ChessboardDelegate, ChessboardInterface } from "./chessboard_interface";
import type { StateGetter, StateSetter } from "./state_setters_getters";
import { type ProgressMessage, ProgressMessageType } from "./visualization_state";

export interface PuzzleState extends ChessboardDelegate {
	puzzlePosition: Chess;
	turn: Color;
	solutionMoves: Move[];
	puzzle: LichessPuzzle | null;
	progressMessage: ProgressMessage | null;
	delegate: PuzzleStateDelegate;
	shouldMakeMove: (move: Move) => boolean;
}

export interface PuzzleStateDelegate {
	chessboard: ChessboardInterface;
	onPuzzleMoveSuccess: () => void;
	onPuzzleMoveFailure: (move: Move) => void;
	onPuzzleSuccess: () => void;
}

export const getInitialPuzzleState = (
	_set: StateSetter<PuzzleState, any>,
	_get: StateGetter<PuzzleState, any>,
) => {
	const set = <T,>(fn: (s: PuzzleState) => T, _id?: string): T => {
		return _set((s) => fn(s));
	};
	// const _setOnly = <T,>(fn: (stack: PuzzleState) => T, _id?: string): T => {
	// 	return _set((s) => fn(s));
	// };
	// const _get = <T,>(fn: (stack: PuzzleState) => T, _id?: string): T => {
	// 	return _get((s) => fn(s));
	// };
	return {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		delegate: null as PuzzleStateDelegate,
		// @ts-ignore
		puzzlePosition: null as Chess,
		turn: "w" as Color,
		shouldMakeMove: (move: Move) =>
			set((s) => {
				if (move.san === s.solutionMoves[0].san || isCheckmate(move, s.puzzlePosition)) {
					const otherSideMove = s.solutionMoves[1];
					s.puzzlePosition.move(move);
					if (otherSideMove) {
						s.delegate.chessboard.setPosition(s.puzzlePosition);
						s.puzzlePosition.move(otherSideMove);
						s.delegate.chessboard.makeMove(otherSideMove, {
							animate: true,
							sound: "move",
						});
					}
					s.solutionMoves.shift();
					s.solutionMoves.shift();
					if (!isEmpty(s.solutionMoves)) {
						s.delegate.onPuzzleMoveSuccess();
						s.progressMessage = {
							message: "Keep going...",
							// onPromptPress: noop,
							// prompt: "Give up?",
							type: ProgressMessageType.Error,
						};
					} else {
						s.progressMessage = null;
						s.delegate.onPuzzleMoveSuccess();
						s.delegate.onPuzzleSuccess();
					}
					return true;
				}
				s.delegate.onPuzzleMoveFailure(move);
				s.progressMessage = {
					message: `${move.san} was not the right move, try again.`,
					// onPromptPress: noop,
					// prompt: "Give up?",
					type: ProgressMessageType.Error,
				};
				return false;
			}),
		madeMove: noop,
		completedMoveAnimation: noop,
		puzzle: null,
		solutionMoves: [] as Move[],
		progressMessage: null,
	} as PuzzleState;
};
