import { clsx } from "~/utils/classes";

export const HomePageCTA = (props: { onClick: () => void }) => {
	return (
		<div
			class={clsx(
				"px-[18px] lg:px-[24px] py-[9px] lg:py-[12px] flex justify-center",
				"items-center bg-orangeBright-60 text-gray-10 transition-all hover:bg-orange-45",
				"rounded font-medium tracking-wide cursor-pointer self-center text-xl",
			)}
			onClick={props.onClick}
		>
			Try it for free
		</div>
	);
};
