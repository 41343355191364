import { capitalize } from "lodash-es";
import { Show } from "solid-js";
import type { CourseOverviewDTO } from "~/rspc";
import { BROWSING_STATE, UI } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { CourseAvatar } from "./CourseAvatar";
import { CourseView } from "./CourseView";
import { OfficalRepertoireTag } from "./OfficialRepertoireTag";
import { SidebarHeader } from "./RepertoireEditingHeader";
import { SidebarTable } from "./SidebarTable";
import { Spacer } from "./Space";

export const CourseListView = (props: { courses: CourseOverviewDTO[] }) => {
	const isStartEpd = () => BROWSING_STATE().chessboard.getCurrentEpd() === START_EPD;
	return (
		<div class="">
			<div class="padding-sidebar">
				<SidebarHeader>
					Pre-made repertoires{" "}
					{isStartEpd() ? `for ${capitalize(BROWSING_STATE().currentSide)}` : "from this position"}
				</SidebarHeader>
				<Spacer between={["header", "table"]} />
			</div>
			<SidebarTable
				maxRows={null}
				class="!py-4"
				leftColumns={[
					{
						label: "Course avatar",
						labelStyle: "hidden",
						alignVertical: "top",
						width: 32,
						align: "left",
						render: (course) => <CourseAvatar course={course} size={32} />,
					},
					{
						label: "Course name",
						labelStyle: "hidden",
						width: "auto",
						align: "left",
						render: (course) => (
							<div class="flex flex-col gap-4 w-full">
								<p class="text-primary font-semibold text-sm">{course.name}</p>
								<p class="text-body font-medium whitespace-pre-wrap line-clamp-8 leading-relaxed max-w-120">
									{course.descriptionShort ?? course.description}
								</p>
								<Show when={course.official}>
									<OfficalRepertoireTag />
								</Show>
							</div>
						),
					},
				]}
				onClick={(row) => {
					UI().pushView(CourseView, { props: { course: row } });
				}}
				rightColumns={[]}
				rows={props.courses}
			/>
		</div>
	);
};
