import { Capacitor } from "@capacitor/core";

export const isDevelopment = import.meta.env.DEV;
export const isStaging = !import.meta.env.SSR && window.location.hostname.includes("staging");
export const isChessmadra =
	import.meta.env.VITE_SITE === "chessmadra" ||
	(!import.meta.env.SSR && window.location.hostname === "chessmadra.com");
export const isChesscards =
	import.meta.env.VITE_SITE === "chesscards" ||
	(!import.meta.env.SSR &&
		(window.location.hostname === "chesscards.com" ||
			window.location.hostname === "cards.chessbook.com"));
export const isChessbook = !isChessmadra && !isChesscards;

export const isNative = Capacitor.getPlatform() !== "web";
export const isWeb = Capacitor.getPlatform() === "web";
export const isIos = Capacitor.getPlatform() === "ios";
export const isAndroid = Capacitor.getPlatform() === "android";
