import { colors } from "~/utils/design-system.tsx";

export const ProgressCircle = (props: {
	progress: number;
	backgroundColor?: string;
	color?: string;
	strokeWidth?: string;
}) => {
	const radius = 42;
	const circumference = 2 * Math.PI * radius;
	const dashOffset = () => {
		const rawProgress = props.progress;
		const calculateDashOffset = (n: number) => {
			return (1 - n) * circumference * -1;
		};
		if (rawProgress === 1 || rawProgress === 0) {
			return calculateDashOffset(rawProgress);
		}
		return calculateDashOffset(Math.min(Math.max(rawProgress, 0.05), 0.88));
	};
	return (
		<svg
			id="svg"
			width="100%"
			height="100%"
			viewBox="0 0 100 100"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				r="42"
				stroke-width={props.strokeWidth ?? "16px"}
				cx="50"
				cy="50"
				fill="transparent"
				stroke={props.backgroundColor ?? colors.gray[30]}
				stroke-dashoffset="0"
			></circle>
			<circle
				id="bar"
				stroke={props.color ?? colors.green[50]}
				r="42"
				stroke-width={props.strokeWidth ?? "16px"}
				cx="50"
				cy="50"
				fill="transparent"
				stroke-linecap="round"
				stroke-dashoffset={-dashOffset()}
				transform="rotate(-90 100 100)"
				style={{
					transform: "rotate(-90deg)",
					"transform-origin": "50% 50%",
					transition: "stroke-dashoffset 0.2s ease-in-out",
				}}
				stroke-dasharray={`${circumference}`}
			></circle>
		</svg>
	);
};
