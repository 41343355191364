import { destructure } from "@solid-primitives/destructure";
import { createEffect } from "solid-js";
import { GameResultsDistribution } from "~/types/GameResults";
import type { Side } from "~/types/Side";
import { clsx } from "~/utils/classes";
import { formatWinPercentage } from "~/utils/results_distribution";
import { c, stylex } from "~/utils/styles";
import { CMText } from "./CMText";

const adjustPercentages = (percentages: GameResultsDistribution) => {
	const fudgePower = 1.6;
	const whiteAdjusted = (percentages.white * 100) ** fudgePower;
	const blackAdjusted = (percentages.black * 100) ** fudgePower;
	const total = whiteAdjusted + blackAdjusted;
	const _draw = percentages.draw * total;
	// console.log({
	// 	whiteAdjusted,
	// 	blackAdjusted,
	// 	total,
	// 	draw,
	// 	whiteFinal: whiteAdjusted / total - draw / 2,
	// 	blackFinal: blackAdjusted / (total - draw),
	// 	drawFinal: percentages.draw,
	// });
	return {
		white: whiteAdjusted / total - percentages.draw / 2,
		black: blackAdjusted / total - percentages.draw / 2,
		draw: percentages.draw,
	};
};

export const GameResultsBar = (props: {
	gameResults: GameResultsDistribution;
	lowConfidence: boolean;
	hideNumbers?: boolean;
	activeSide: Side;
}) => {
	const total = GameResultsDistribution.getTotalGames(props.gameResults) ?? 0;
	const threshold = 0.3;
	const showPercentageThreshold = 0.4;
	const fontSize = 10;
	const { adjustedPercentages, realPercentages } = destructure(() => {
		let realPercentages: GameResultsDistribution = {
			white: props.gameResults.white / total,
			black: props.gameResults.black / total,
			draw: props.gameResults.draw / total,
		};
		let adjustedPercentages: GameResultsDistribution = adjustPercentages(realPercentages);
		return {
			adjustedPercentages,
			realPercentages,
		};
	});
	createEffect(() => {});
	const whiteResults = (
		<div
			style={stylex(
				c.width(`${adjustedPercentages().white * 100}%`),
				c.bg(c.gray[90]),
				c.px(4),
				c.alignCenter,
				c.row,
				props.activeSide === "black" ? c.justifyEnd : c.justifyStart,
			)}
		>
			{adjustedPercentages().white > threshold && !props.hideNumbers && (
				<>
					<CMText
						class={clsx(
							props.lowConfidence ? "text-gray-50" : "text-gray-10",
							"text-[10px] font-bold",
						)}
					>
						{formatWinPercentage(realPercentages().white)}
						{adjustedPercentages().white > showPercentageThreshold && <span class="">%</span>}
					</CMText>
				</>
			)}
		</div>
	);
	const blackResults = (
		<div
			style={stylex(
				c.width(`${adjustedPercentages().black * 100}%`),
				c.bg(c.gray[6]),
				c.alignCenter,
				c.row,
				c.px(4),
				props.activeSide === "black" ? c.justifyStart : c.justifyEnd,
			)}
		>
			{adjustedPercentages().black > threshold && !props.hideNumbers && (
				<>
					<CMText
						class={clsx(
							props.lowConfidence ? "text-gray-40" : "text-gray-90",
							"text-[10px] font-bold",
						)}
					>
						{formatWinPercentage(realPercentages().black)}
						{adjustedPercentages().black > showPercentageThreshold && <span class="">%</span>}
					</CMText>
				</>
			)}
		</div>
	);
	const [first, last] =
		props.activeSide === "white" ? [whiteResults, blackResults] : [blackResults, whiteResults];
	return (
		<div
			style={stylex(c.row, c.fullWidth, c.fullHeight, c.height(18), c.br(2), c.overflowHidden)}
			class="tabular-nums"
		>
			{first}
			<div
				style={stylex(c.width(`${adjustedPercentages().draw * 100}%`), c.bg(c.gray[40]), c.center)}
			>
				{adjustedPercentages().draw > threshold && !props.hideNumbers && !props.activeSide && (
					<CMText style={stylex(c.fg(c.gray[75]), c.weightBold, c.fontSize(fontSize))}>
						{formatWinPercentage(realPercentages().draw)}
					</CMText>
				)}
			</div>
			{last}
		</div>
	);
};

type WinrateMovement = "up" | "down" | null;

export const getWinrateMovement = (props: {
	results: GameResultsDistribution;
	previous?: GameResultsDistribution;
	side: Side;
}): WinrateMovement => {
	if ((GameResultsDistribution.getTotalGames(props.results) ?? 0) < 10 || !props.previous) {
		return null;
	}

	const threshold = 0.02;
	const oldWr = GameResultsDistribution.getWinRate(props.previous, props.side);
	const newWr = GameResultsDistribution.getWinRate(props.results, props.side);
	if (newWr < oldWr - threshold) {
		if (
			GameResultsDistribution.getDrawAdjustedWinRate(props.results, props.side) <
			GameResultsDistribution.getDrawAdjustedWinRate(props.previous, props.side) - threshold
		) {
			return "down";
		}
	} else if (newWr > oldWr + threshold) {
		return "up";
	}
	return null;
};
