import { isEmpty } from "lodash-es";
import { Show, onCleanup, onMount } from "solid-js";
import { Spacer } from "~/components/Space";
import { BROWSING_STATE, quick } from "~/utils/app_state";
import { CollapsibleSidebarSection } from "./CollapsibleSidebarSection";
import { SidebarTemplate } from "./SidebarTemplate";
import { PlayFromHere } from "./TargetCoverageReachedView";

export const TransposedView = () => {
	const planSections = () => BROWSING_STATE().planSections;

	onCleanup(() => {
		BROWSING_STATE().chessboard.set((s) => {
			s.showPlans = false;
		});
	});
	onMount(() => {
		BROWSING_STATE().chessboard.set((s) => {
			s.showPlans = true;
		});
	});
	return (
		<SidebarTemplate
			header={"You've transposed into an existing line"}
			actions={[
				{
					onPress: () => {
						quick((s) => {
							s.repertoireState.browsingState.addPendingLine();
						});
					},
					style: "primary",
					text: "Save this move order to my repertoire",
				},
			]}
			bodyPadding={false}
		>
			<>
				<p class={"body-text padding-sidebar"}>
					You don't need to add anything else. All of your moves from this position will still apply
				</p>
				<Show when={!isEmpty(planSections)}>
					<>
						<Spacer between={["body-text", "bullets"]} />
						<CollapsibleSidebarSection header="How to play from here">
							<div class="padding-sidebar">
								<PlayFromHere />
							</div>
						</CollapsibleSidebarSection>
					</>
				</Show>
			</>
		</SidebarTemplate>
	);
};
