import { PushNotifications } from "@capacitor/push-notifications";
import { APP_STATE } from "./app_state";
import { isIos } from "./env";

export namespace Notifications {
	export const addListeners = async () => {
		await PushNotifications.addListener("registration", (token) => {
			// biome-ignore lint/suspicious/noConsoleLog: <explanation>
			console.log("Got a device token!", token);
			APP_STATE().userState.updateUserSettings({
				iosDeviceToken: isIos ? token.value : undefined,
				androidDeviceToken: isIos ? undefined : token.value,
			});
		});

		await PushNotifications.addListener("registrationError", (err) => {
			console.error("Registration error: ", err?.error);
		});

		await PushNotifications.addListener("pushNotificationReceived", (notification) => {
			// biome-ignore lint/suspicious/noConsoleLog: <explanation>
			console.log("Push notification received: ", notification);
		});

		await PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
			// biome-ignore lint/suspicious/noConsoleLog: <explanation>
			console.log(
				"Push notification action performed",
				notification.actionId,
				notification.inputValue,
			);
		});
	};

	export const registerNotifications = async () => {
		let permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive === "prompt") {
			permStatus = await PushNotifications.requestPermissions();
		}

		if (permStatus.receive !== "granted") {
			console.info("User denied permissions!");
		}

		await PushNotifications.register();
	};
}
