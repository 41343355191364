import type { Side } from "../types/Side";

const pows: Record<Side, Record<number, number>> = {
	white: {
		900: 0.904595770219748,
		1100: 0.926906594980799,
		1300: 0.94870009731246,
		1500: 0.957122253788326,
		1700: 0.978972623335415,
		1900: 0.994605739431931,
		2100: 0.99229529080723,
		2350: 1.04361983342534,
		2500: 1.07734097268626,
	},
	black: {
		900: 0.987651185910299,
		1100: 1.02380205579267,
		1300: 1.02380205579267,
		1500: 1.05338232568022,
		1700: 1.06038569306811,
		1900: 1.07824199223558,
		2100: 1.12169426346782,
		2350: 1.13035158193851,
		2500: 1.16738186609566,
	},
};

export const getExpectedNumMovesBetween = (
	current: number,
	destination: number,
	side: Side,
	rating: number,
): number => {
	const pow = pows[side][rating];
	if (!pow) {
		console.warn(`No pow found for ${side} ${rating}`);
	}
	let distance = 1 / destination;
	distance = distance ** pow * current;
	// if (current !== 1) {
	// 	const adjustment = 1;
	// 	distance *= adjustment;
	// }
	distance = Math.max(0, distance);
	return distance;
};

export function getCoverageProgress(progress: number) {
	const linearCutoff = 0.9;
	if (progress <= linearCutoff) {
		return progress;
	}
	const transformedProgress = progress - linearCutoff;

	// lower = more margin
	// higher = closer to 1 as progress approaches 1
	const easeOutProgress = 1 - Math.exp(-20 * transformedProgress);

	return linearCutoff + easeOutProgress * (1 - linearCutoff);
}
