export const HomePageHeader = () => {
	const ctas = () => {
		return getHomepageHeadersCopy();
	};
	return <span> {ctas().header}</span>;
};
export const HomePageSubheader = () => {
	const ctas = () => {
		return getHomepageHeadersCopy();
	};
	return <span> {ctas().subheader}</span>;
};

const getHomepageHeadersCopy = () => {
	// if (getFeature("homepage-cta-v2") === "ready-to-learn") {
	// 	return {
	// 		header: "You're ready to learn openings",
	// 		subheader: "Chessbook is the fastest way to build a bulletproof opening repertoire.",
	// 	};
	// }
	return {
		header: "Your personal opening book",
		subheader: "Chessbook is the fastest way to build a bulletproof opening repertoire. ",
	};
};
