import type { Side } from "~/types/Side";
import { c, stylex } from "~/utils/styles";

export const SideDot = (props: {
	side: Side;
	size?: number;
}) => {
	return (
		<div
			style={stylex(
				props.side === "white"
					? stylex(c.bg("#F2F2F3"))
					: stylex(c.bg("#2B2C2D"), c.border("1px solid #778288")),
				props.size && c.size(props.size),
			)}
			class={"square-[12px] rounded-full shrink-0"}
		></div>
	);
};
