import type { JSXElement } from "solid-js";
import { c, stylex } from "~/utils/styles";

export const Pressable = (props: {
	style?: any;
	class?: string;
	onPress?: (e: Event) => unknown;
	children?: JSXElement;
	disabled?: boolean;
	id?: string;
}) => {
	return (
		<button
			type="button"
			{...props}
			class={props.class}
			style={stylex(!props.disabled && c.clickable, props.style ?? {})}
			use:onClick={(e) => {
				props.onPress?.(e);
			}}
		/>
	);
};
