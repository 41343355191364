import { Intersperse } from "./Intersperse";
import { type SidebarAction, SidebarActions, SidebarSectionHeader } from "./SidebarActions";
import { Spacer } from "./Space";

export type SidebarActionGroup = {
	actions: SidebarAction[];
	title?: string;
};

export const SidebarActionGroups = (props: {
	groups: SidebarActionGroup[];
}) => {
	return (
		<Intersperse
			each={() => props.groups}
			separator={() => <Spacer between={["actions", "actions"]} />}
		>
			{(section) => {
				return (
					<div>
						{section().title && <SidebarSectionHeader text={section().title!} />}
						<SidebarActions actions={section().actions} />
					</div>
				);
			}}
		</Intersperse>
	);
};
