import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-yup";
import { noop } from "lodash-es";
import { Show, onMount } from "solid-js";
import { Puff } from "solid-spinner";
import * as yup from "yup";
import type { Uuid } from "~/types/Uuid";
import { APP_STATE, CARDS_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { isChessbook } from "~/utils/env";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { Bullet } from "./Bullet";
import { LoadingView } from "./LoadingView";
import { LoginSidebar } from "./LoginSidebar";
import { SeeMoreActions, SidebarFullWidthButton } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { ChooseToCreateAccountOnboarding, ImportSuccessOnboarding } from "./SidebarOnboarding";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";
import { TextInput } from "./TextInput";
import { InputError } from "./forms/InputError";

export const ConnectAccountsSetting = () => {
	const user = () => APP_STATE().userState?.user;
	const onboardingChessbook = () => REPERTOIRE_STATE().onboarding.isOnboarding;
	const onboardingChesscards = () => CARDS_STATE().onboarding;
	const onboardingAny = () => onboardingChessbook() || onboardingChesscards();
	onMount(() => {
		trackEvent("connect_accounts_setting.shown");
	});
	return (
		<SidebarTemplate
			header={
				onboardingChessbook() || onboardingChesscards()
					? "Connect your online accounts"
					: "Connected accounts"
			}
			bodyPadding={false}
			actions={
				onboardingChessbook()
					? [
							{
								style: "primary",
								onPress: () => {
									quick((_s) => {
										UI().pushView(LoginSidebar, {
											props: { authType: "register" },
										});
									});
								},
								text: "Skip this step for now",
							},
						]
					: []
			}
		>
			<Show when={onboardingChessbook()}>
				<p class="body-text mb-8 md:mb-12 padding-sidebar">
					This will let you review the opening mistakes in your online games.
				</p>
			</Show>
			<Show when={onboardingChesscards()}>
				<p class="body-text mb-8 md:mb-12 padding-sidebar">
					This will let you review the mistakes in your online games.
				</p>
			</Show>
			<Show when={!onboardingAny() && isChessbook}>
				<p class="body-text  padding-sidebar">
					We’ll compare your online games against your Chessbook repertoire and:
				</p>
				<Spacer between={["body-text", "bullets"]} />
				<div style={stylex(c.gridColumn({ gap: 8 }), c.pt(12))} class="padding-sidebar">
					{[
						"Count correct moves as practice (reducing your study time)",
						"Let you know when you make a mistake",
					].map((bullet) => (
						<Bullet>{bullet}</Bullet>
					))}
				</div>
				<Spacer between={["body-text", "table"]} />
			</Show>
			<ConnectedAccount
				username={user()?.lichessUsername}
				platform="Lichess"
				onClick={() => {
					quick((s) => {
						s.userState.authWithLichess({
							source: onboardingAny() ? "onboarding" : "setting",
						});
					});
				}}
				onDisconnect={() => {
					quick((s) => {
						s.userState.setLichessToken(null, null, []);
					});
				}}
			/>
			<div class={clsx(onboardingAny() ? "" : "h-8 md:h-12")} />
			<ConnectedAccount
				username={user()?.chesscomUsername}
				platform="Chess.com"
				hideTopBorder={onboardingAny()}
				onClick={() => {
					quick((_s) => {
						UI().pushView(ConnectChesscom);
					});
				}}
				onDisconnect={() => {
					quick((s) => {
						s.userState.setChesscomUsername(null);
					});
				}}
			/>
		</SidebarTemplate>
	);
};

const ConnectedAccount = (props: {
	username?: string | null;
	platform: string;
	hideTopBorder?: boolean;
	onClick: () => void;
	onDisconnect: () => void;
}) => {
	const onboarding = () => REPERTOIRE_STATE().onboarding.isOnboarding;
	return (
		<div class="flex">
			<SidebarFullWidthButton
				action={{
					style: "secondary",
					text: props.platform,
					class: !props.hideTopBorder ? "border-t-1 border-t-border border-t-solid" : "",
					static: !!props.username,
					onPress: !props.username ? props.onClick : noop,
					right: (
						<ConnectedAccountIconAndText
							text={props.username ?? "Connect…"}
							connected={!!props.username}
							hideIcon={!props.username}
						/>
					),
				}}
			/>
			<Show when={props.username && !onboarding()}>
				<SeeMoreActions text="Disconnect" onClick={props.onDisconnect} />
			</Show>
		</div>
	);
};

export const ConnectedAccountIconAndText = (props: {
	text: string;
	connected: boolean;
	hideIcon?: boolean;
}) => {
	return (
		<div
			class={clsx(
				"flex center row text-xs font-semibold",
				props.connected ? "text-primary" : "text-tertiary",
			)}
		>
			<p>{props.text}</p>
			{!props.hideIcon && (
				<i
					class={clsx("ml-2", "fa fa-solid text-[12px]", props.connected ? "fa-check" : "fa-xmark")}
				/>
			)}
		</div>
	);
};

type ConnectChesscomForm = {
	username: string;
};

export const ConnectChesscom = (props: { intention: "import"; repertoireId?: Uuid }) => {
	const onSubmit = async (values: ConnectChesscomForm) => {
		quick((s) => {
			s.userState.setChesscomUsername(values.username).then(() => {
				quick((s) => {
					if (props.intention === "import") {
						UI().pushView(LoadingView);
						s.repertoireState
							.importIntoRepertoire({
								repertoireId: props.repertoireId,
								siteGamesSource: "chesscom",
							})
							.then(() => {
								quick((_s) => {
									animateSidebar("right");
									UI().clearViews();
									UI().replaceView(ImportSuccessOnboarding);
								});
							});
					} else if (REPERTOIRE_STATE().onboarding.isOnboarding) {
						UI().pushView(ChooseToCreateAccountOnboarding);
					} else {
						s.repertoireState.backToOverview();
					}
				});
			});
			s.repertoireState.needsToRefetchLichessMistakes = true;
		});
	};
	const { form, setFields, isSubmitting, errors, createSubmitHandler } =
		createForm<ConnectChesscomForm>({
			initialValues: { username: "" },
			onSubmit,
			extend: [
				validator({
					schema: yup.object({
						username: yup
							.string()
							.required()
							.label("Username")
							.test({
								name: "is-not-email",
								skipAbsent: true,
								test(value, ctx) {
									if (value.includes("@")) {
										return ctx.createError({
											message: "Please enter your username, not your email",
										});
									}
									return true;
								},
							}),
					}),
				}),
			],
		});
	const handleSubmit = createSubmitHandler({
		onSubmit,
	});

	return (
		<>
			<SidebarTemplate
				actions={[
					{
						onPress: handleSubmit,
						text: "Connect",
						style: "focus",
					},
				]}
				header={"What is your chess.com username?"}
				bodyPadding={true}
			>
				<Show when={isSubmitting()}>
					<div class={"row w-full justify-center"}>
						<Puff />
					</div>
				</Show>
				<div class={clsx(isSubmitting() ? "hidden" : "")}>
					<div class="col items-center">
						<div class={"self-stretch"}>
							<form ref={form} class={"col gap-8"}>
								<TextInput
									setFields={setFields}
									placeholder="username"
									type="text"
									name="username"
									errors={errors()}
								/>
								<InputError name={"Server error"} error={null} class={"inline-block"} />
								<input type="submit" hidden />
							</form>
						</div>
					</div>
				</div>
			</SidebarTemplate>
		</>
	);
};
