import { BlunderPuzzleTraining } from "~/components/BlunderPuzzleTraining";
import { VisionTraining } from "~/components/VisionTraining";
import { VisualizationTraining } from "~/components/VisualizationTraining";
import { type AppState, UI } from "./app_state";
import { type BlunderPuzzlesState, getInitialBlunderPuzzlesState } from "./blunder_puzzles_state";
import { createQuick } from "./quick";
import type { StateGetter, StateSetter } from "./state_setters_getters";
import { type VisionState, getInitialVisionState } from "./vision_state";
import { type VisualizationState, getInitialVisualizationState } from "./visualization_state";

type TrainerTool = "visualization" | "board-vision" | "blunder-puzzles" | null;

export interface TrainersState {
	quick: (fn: (_: TrainersState) => void) => void;
	getActiveTool: () => TrainerTool;
	visualizationState: VisualizationState;
	visionState: VisionState;
	blunderPuzzlesState: BlunderPuzzlesState;
}

export enum AuthStatus {
	Authenticated = "Authenticated",
	Unauthenticated = "Unauthenticated",
	Initial = "Initial",
	Authenticating = "Authenticating",
}

type Stack = [TrainersState, AppState];
const selector = (s: AppState): Stack => [s.trainersState, s];

export const getInitialTrainersState = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	_set: StateSetter<AppState, any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	_get: StateGetter<AppState, any>,
) => {
	// const _set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
	// 	return _set((s) => fn(selector(s)));
	// };
	const setOnly = <T,>(fn: (stack: TrainersState) => T, _id?: string): T => {
		return _set((s) => fn(s.trainersState));
	};
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _get((s) => fn(selector(s)));
	};
	const initialState = {
		...createQuick<TrainersState>(setOnly),
		visualizationState: getInitialVisualizationState(_set, _get, false),
		visionState: getInitialVisionState(_set, _get, false),
		blunderPuzzlesState: getInitialBlunderPuzzlesState(),
		getActiveTool: () => {
			return get(([_s]): TrainerTool => {
				for (const view of UI().sidebarStack) {
					if (view.component === VisualizationTraining) {
						return "visualization";
					}
					if (view.component === VisionTraining) {
						return "board-vision";
					}
					if (view.component === BlunderPuzzleTraining) {
						return "blunder-puzzles";
					}
				}
				return null;
			});
		},
	} as TrainersState;

	return initialState;
};
