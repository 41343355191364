import { destructure } from "@solid-primitives/destructure";
import { clamp, isNil } from "lodash-es";
import { initTooltip } from "~/components/Tooltip";
import type { TableResponse } from "~/types/TableResponse";
import { BROWSING_STATE, REPERTOIRE_STATE } from "~/utils/app_state";
import { getCoverageProgress } from "~/utils/coverage_estimation";
import { c, stylex } from "~/utils/styles";

export const TableCoverageProgressBar = (props: { tableResponse: TableResponse }) => {
	const epdAfter = () =>
		props.tableResponse.suggestedMove?.epdAfter ??
		(props.tableResponse.repertoireMove?.epdAfter as string);
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const hasResponse = () =>
		(BROWSING_STATE().getActiveRepertoire()?.positionResponses[epdAfter()]?.length ?? 0) > 0;
	const numMovesFromHere = () =>
		REPERTOIRE_STATE().numMovesFromEpd[activeRepertoireId()][epdAfter()];
	const expectedNumMovesNeeded = () =>
		REPERTOIRE_STATE().expectedNumMovesFromEpd[activeRepertoireId()][epdAfter()];
	const missFromHere = () =>
		REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()].biggestMisses[epdAfter()];

	const backgroundColor = c.gray[28];
	const completedColor = c.colors.success;
	const { completed, progress } = destructure(() => {
		let completed = isNil(missFromHere());
		let progress = clamp(
			getCoverageProgress(numMovesFromHere() / expectedNumMovesNeeded()) * 100,
			5,
			90,
		);
		if (!hasResponse()) {
			progress = 0;
			completed = false;
		}
		return { completed, progress };
	});
	const inProgressColor = () => (progress() < 20 ? c.red[65] : c.orange[65]);
	return (
		<div
			style={stylex(c.column, c.fullWidth)}
			class="py-1"
			ref={(ref) => {
				initTooltip({
					ref,
					content: () => {
						if (completed()) {
							return "You've reached your coverage goal for this move";
						}
						if (progress() === 0) {
							return "You haven't added any responses to this move";
						}
						return "Your coverage of this move is incomplete";
					},
					maxWidth: 160,
				});
			}}
		>
			<div class="w-full rounded-full overflow-hidden h-1" style={stylex(c.bg(backgroundColor))}>
				<div
					style={stylex(
						c.width(completed() ? "100%" : `${progress()}%`),
						c.bg(completed() ? completedColor : inProgressColor()),
						c.fullHeight,
					)}
				/>
			</div>
		</div>
	);
};
