import { For, createContext } from "solid-js";
import { Dynamic } from "solid-js/web";
import { UI } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import type { UiState } from "~/utils/ui_state";

export const SidebarStackContext = createContext<number>();

export const SidebarStack = (props: { stack: UiState["sidebarStack"] }) => {
	return (
		<For each={props.stack}>
			{(view, i) => {
				return (
					<div class={clsx(i() === UI().sidebarStack.length - 1 ? "" : "hidden")}>
						<SidebarStackContext.Provider value={i()}>
							<Dynamic component={view.component} {...(view.props ?? {})} />
						</SidebarStackContext.Provider>
					</div>
				);
			}}
		</For>
	);
};
