import { Show } from "solid-js";
import { REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { isChessmadra, isNative } from "~/utils/env";
import { c, stylex } from "~/utils/styles";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { AuthStatus } from "~/utils/user_state";
import { LoginSidebar } from "./LoginSidebar";
import { Pressable } from "./Pressable";
import { SidebarSettings } from "./SidebarSettings";
import { TextAndIcon } from "./TextAndIcon";

export const SettingsButtons = () => {
	const responsive = useResponsiveV2();
	const user = () => USER_STATE().user;
	const authStatus = () => USER_STATE().authStatus;
	const needsLogin = () =>
		authStatus() === AuthStatus.Unauthenticated ||
		(authStatus() === AuthStatus.Authenticated && user()?.temporary);
	const mobile = () => responsive().isMobile;
	const breadcrumbs = () => UI().getBreadCrumbs();
	const hasLongBreadcrumbs = () => breadcrumbs().length > 1;
	const isOnboarding = () => REPERTOIRE_STATE().onboarding.isOnboarding;
	const showSettings = () => {
		if (isChessmadra || isOnboarding()) {
			return false;
		}
		if (mobile() && hasLongBreadcrumbs()) {
			return !needsLogin();
		}
		return true;
	};

	return (
		<div style={stylex(c.row)} class="gap-4">
			<Show when={needsLogin() && (!isOnboarding() || isNative)}>
				<Pressable
					onPress={() =>
						quick((s) => {
							s.repertoireState.backToOverview();
							s.repertoireState.onboarding.isOnboarding = false;
							UI().pushView(LoginSidebar, {
								props: {
									authType: "register",
								},
							});
						})
					}
				>
					<TextAndIcon
						text="Sign up"
						icon={"fa-sharp fa-user text-xs"}
						class="font-medium text-tertiary hover:text-primary transition-colors text-sm md:text-xs"
					/>
				</Pressable>
			</Show>
			<Show when={showSettings()}>
				<Pressable
					onPress={() =>
						quick((s) => {
							s.repertoireState.backToOverview();
							UI().pushView(SidebarSettings);
						})
					}
				>
					<TextAndIcon
						text="Settings"
						icon={"fa fa-gear text-xs"}
						class="font-medium text-tertiary hover:text-primary transition-colors text-sm md:text-xs"
					/>
				</Pressable>
			</Show>
		</div>
	);
};
