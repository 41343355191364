import type { Side } from "./Side";
import type { Uuid } from "./Uuid";

export type OpeningsReport = {
	mode: "rated" | "unrated";
	id: string;
	worstOpenings: OpeningReport[];
	bestOpenings: OpeningReport[];
	gamesCount: number;
	platforms: string;
	minStrength: number;
	maxStrength: number;
	timeClass: string;
};

export type OpeningReport = {
	minStrength: number;
	maxStrength: number;
	side: Side;
	epd: string;
	epds: string[];
	line: string[];
	wins: number;
	losses: number;
	draws: number;
	winrateUpperBound: number;
	winrateLowerBound: number;
	games: OpeningReportGame[];
	insightId: Uuid;
	reviewed: boolean;
};

export type OpeningReportGame = {
	playedAt: string;
	opponentUsername: string;
	myUsername: string;
	result: "Win" | "Draw" | "Loss";
	link: string;
	myElo: number;
	opponentElo: number;
};

export namespace OpeningsReport {
	export const getAllReports = (o: OpeningsReport): OpeningReport[] => {
		return [...o.bestOpenings, ...o.worstOpenings];
	};
	export const isEmpty = (o: OpeningsReport): boolean => {
		return o.bestOpenings.length === 0 && o.worstOpenings.length === 0;
	};
	export const countInsights = (o: OpeningsReport): number => {
		return OpeningsReport.getAllReports(o).filter((or) => !or.reviewed).length;
	};
}
