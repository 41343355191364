import { UpgradeSubscriptionView } from "~/components/UpgradeSubscriptionView";
import type { Side } from "~/types/Side";
import { APP_STATE, REPERTOIRE_STATE, UI } from "./app_state";
import { isWeb } from "./env";

export const getPricing = () => {
	if (!isWeb) {
		return [5, 4];
	}
	return [8, 80];
};

export const showUpgradeIfNeeded = (side: Side) => {
	const subscribed = APP_STATE().userState.isSubscribed();
	let pastLimit = REPERTOIRE_STATE().pastFreeTier(side);
	if (!subscribed && pastLimit) {
		UI().pushView(UpgradeSubscriptionView, {
			props: { pastLimit: true },
		});
		return true;
	}
	return false;
};
