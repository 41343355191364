import type { JSXElement } from "solid-js";
import type { SidebarAction } from "~/components/SidebarActions";
import { FrontendSettingView } from "~/components/SidebarSettings";
import { PlaybackSpeed } from "~/types/PlaybackSpeed";
import { UI, USER_STATE, quick } from "~/utils/app_state";
import { isDevelopment } from "./env";
import { trackEvent } from "./trackEvent";

export enum PieceAnimation {
	DebugSlow = "debug-slow",
	Disabled = "disabled",
	Slow = "slow",
	Normal = "normal",
	Fast = "fast",
	VeryFast = "very-fast",
	None = "none",
}

export enum ReviewStyle {
	EntireLineOnce = "Entire line",
	EntireLineEvery = "Entire line - every time",
	Selective = "Selective",
}

export enum SoundSetting {
	Enabled = "enabled",
	Muted = "muted",
}

export enum RecommendedMovesSize {
	Small = "10",
	Original = "25",
}

export enum OpeningsReportTimeRange {
	ThirtyDays = "30d",
	NinetyDays = "90d",
}

export enum ModelGamesSource {
	Lichess = "lichess",
	Masters = "masters",
	All = "all",
}

export enum BlunderPuzzlesMode {
	Standard = "standard",
	Storm = "storm",
	Streak = "streak",
}

export enum BlunderPuzzlesEval {
	Winning = "winning",
	Even = "even",
	Mixed = "mixed",
}

export enum BlunderPuzzleGamePhase {
	Any = "any",
	Opening = "opening",
	Middlegame = "middlegame",
	Endgame = "endgame",
}

export enum AdminDebugMode {
	None = "none",
	All = "all",
}

export type FrontendSetting<T> = {
	key: string;
	title: string;
	default: T;
	description?: string | JSXElement;
	options: FrontendSettingOption<T>[];
};

export type FrontendSettingOption<T> = {
	value: T;
	label: string;
	description?: string;
};

export const SETTINGS = {
	adminDebugMode: {
		default: AdminDebugMode.None,
		key: "adminDebugMode",
		title: "Admin debug views",
		options: [
			{ value: AdminDebugMode.None, label: "Off" },
			{ value: AdminDebugMode.All, label: "On" },
		],
	},
	openingsTimeRange: {
		default: OpeningsReportTimeRange.NinetyDays,
		key: "openingsTimeRange",
		title: "Time period",
		options: [
			{ value: OpeningsReportTimeRange.ThirtyDays, label: "Last 30 days" },
			{ value: OpeningsReportTimeRange.NinetyDays, label: "Last 90 days" },
		],
	} as FrontendSetting<OpeningsReportTimeRange>,
	pieceAnimation: {
		default: PieceAnimation.Normal,
		key: "pieceAnimation",
		title: "Piece animation",
		options: [
			...(isDevelopment ? [{ value: PieceAnimation.DebugSlow, label: "Debug Slow" }] : []),
			{ value: PieceAnimation.Slow, label: "Slow" },
			{ value: PieceAnimation.Normal, label: "Normal" },
			{ value: PieceAnimation.Fast, label: "Fast" },
			{ value: PieceAnimation.VeryFast, label: "Very Fast" },
			{ value: PieceAnimation.Disabled, label: "Disabled" },
		],
	} as FrontendSetting<PieceAnimation>,
	reviewStyle: {
		default: ReviewStyle.Selective,
		key: "reviewStyle",
		title: "Practice",
		options: [
			{ value: ReviewStyle.Selective, label: "Individual moves" },
			{ value: ReviewStyle.EntireLineEvery, label: "Complete lines (every time)" },
			{ value: ReviewStyle.EntireLineOnce, label: "Complete lines (once per practice)" },
		],
	} as FrontendSetting<ReviewStyle>,
	recommendedMovesSize: {
		default: RecommendedMovesSize.Small,
		key: "recommendedMovesSize",
		title: "Recommended moves review size",
		description: <>This is the number of moves in a single recommended review session.</>,
		options: [
			{ value: RecommendedMovesSize.Small, label: "10 moves" },
			{ value: RecommendedMovesSize.Original, label: "25 moves" },
		],
	} as FrontendSetting<RecommendedMovesSize>,
	modelGamesSource: {
		default: ModelGamesSource.Masters,
		key: "modelGamesSource",
		title: "Model games source",
		description: (
			<>
				Master games are from over-the-board tournaments and feature the strongest resistance and
				most accurate play. Online games are lower quality but illustrate how to punish common
				mistakes.
				<br />
				<br />
				Both sources feature instructive games where your side doesn't make any significant mistakes
				and wins decisively.
			</>
		),
		options: [
			{ value: ModelGamesSource.Lichess, label: "High-level online games" },
			{ value: ModelGamesSource.Masters, label: "Master games" },
			{ value: ModelGamesSource.All, label: "Online and master games" },
		],
	},
	blunderPuzzlesGamePhase: {
		default: BlunderPuzzleGamePhase.Any,
		key: "blunderPuzzlesGamePhase",
		title: "Game phase",
		options: [
			{ value: BlunderPuzzleGamePhase.Any, label: "Mixed" },
			{ value: BlunderPuzzleGamePhase.Opening, label: "Opening" },
			{ value: BlunderPuzzleGamePhase.Middlegame, label: "Middlegame" },
			{ value: BlunderPuzzleGamePhase.Endgame, label: "Endgame" },
		],
	} as FrontendSetting<BlunderPuzzleGamePhase>,
	blunderPuzzlesMode: {
		default: BlunderPuzzlesMode.Streak,
		key: "blunderPuzzlesMode",
		title: "Training mode",
		options: [
			{ value: BlunderPuzzlesMode.Standard, label: "Standard" },
			// { value: BlunderPuzzlesMode.Storm, label: "Storm" },
			{ value: BlunderPuzzlesMode.Streak, label: "Streak" },
		],
	} as FrontendSetting<BlunderPuzzlesMode>,
	blunderPuzzlesEval: {
		default: BlunderPuzzlesEval.Mixed,
		key: "blunderPuzzlesEval",
		title: "Position evaluation",
		options: [
			{ value: BlunderPuzzlesEval.Mixed, label: "Mixed" },
			{ value: BlunderPuzzlesEval.Even, label: "Even" },
			{ value: BlunderPuzzlesEval.Winning, label: "Winning" },
		],
	} as FrontendSetting<BlunderPuzzlesEval>,
	sound: {
		default: "enabled",
		key: "sound",
		title: "Sound effects",
		options: [
			{ value: SoundSetting.Muted, label: "Muted" },
			{ value: SoundSetting.Enabled, label: "Enabled" },
		],
	} as FrontendSetting<SoundSetting>,
};

export type FrontendSettings = Record<keyof typeof SETTINGS, string>;

export const createFrontendSettingActions = <T,>(
	setting: FrontendSetting<T>,
	onChange?: (value: T) => void,
) => {
	return {
		onPress: () => {
			quick((_s) => {
				trackEvent(`settings.${setting.key}.clicked`);
				UI().pushView(FrontendSettingView as typeof FrontendSettingView<T>, {
					props: { setting: SETTINGS[setting.key], onChange },
				});
			});
		},
		text: setting.title,
		right: USER_STATE().getFrontendSetting(setting.key as any).label,
		style: "secondary",
	} as SidebarAction;
};

export const pieceAnimationToPlaybackSpeed = (pieceAnimation: PieceAnimation): PlaybackSpeed => {
	switch (pieceAnimation) {
		case PieceAnimation.DebugSlow:
			return PlaybackSpeed.DebugSlow;
		case PieceAnimation.Slow:
			return PlaybackSpeed.Slow;
		case PieceAnimation.Normal:
			return PlaybackSpeed.Normal;
		case PieceAnimation.Fast:
			return PlaybackSpeed.Fast;
		case PieceAnimation.VeryFast:
			return PlaybackSpeed.Ludicrous;
	}
	return PlaybackSpeed.Fast;
};
