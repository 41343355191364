import { APP_STORE_LINK, PLAY_STORE_LINK } from "~/constants";
import { getStatic } from "~/utils/assets";
import { trackEvent } from "~/utils/trackEvent";
import { Spacer } from "./Space";
import { ToastPromotion, ToastPromotionType } from "./ToastPromotion";

export const MobileAppPromotion = () => {
	return (
		<ToastPromotion flag={ToastPromotionType.MobileApps}>
			<h1 class="text-lg text-primary font-semibold pt-8">Get the Chessbook app!</h1>
			<Spacer height={36} />
			<div class=" aspect-[1/2] grow h-auto self-center">
				<img src={getStatic("/app_promo/screenshot.svg")} />
			</div>
			<Spacer height={36} />
			<div class="row">
				<a
					href={APP_STORE_LINK}
					use:onClick={() => {
						trackEvent("app_promotion.clicked", { store: "app_store" });
					}}
					target="_blank"
					rel="noopener noreferrer"
					class="h-[30px] grow"
				>
					<img class=" h-full w-full" src={getStatic("/app_promo/app_store.svg")} />
				</a>
				<Spacer width={32} />
				<a
					href={PLAY_STORE_LINK}
					use:onClick={() => {
						trackEvent("app_promotion.clicked", { store: "play_store" });
					}}
					target="_blank"
					rel="noopener noreferrer"
					class="h-[30px] grow"
				>
					<img class=" grow h-full w-full" src={getStatic("/app_promo/play_store.svg")} />
				</a>
			</div>
		</ToastPromotion>
	);
};
