import { createPageVisibility } from "@solid-primitives/page-visibility";
import { Match, Switch, createEffect, createMemo, createSignal } from "solid-js";
import { APP_STATE, REPERTOIRE_STATE, UI, useMode } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import dayjs from "~/utils/dayjs";
import { isNative } from "~/utils/env";
import { Notifications } from "~/utils/notifications";
import { c, stylex } from "~/utils/styles";
import { BackSection } from "./BackSection";
import { ChessboardFooter } from "./ChessboardFooter";
import { RepertoireHome } from "./RepertoireHome";
import { SettingsButtons } from "./Settings";
import { NavBreadcrumbs, SidebarLayout } from "./SidebarLayout";
import { SidebarStack } from "./SidebarStack";
import { ChessboardView, PiecesSvgSheet } from "./chessboard/Chessboard";

export const ChessbookRoot = () => {
	const mode = useMode();

	const openingsReportChessboardInterface = () =>
		APP_STATE().repertoireState.openingReportsState.chessboard;
	const reviewChessboardInterface = () => APP_STATE().repertoireState.reviewState.chessboard;
	const modelGamesChessboardInterface = () =>
		APP_STATE().repertoireState.modelGamesState.chessboard;
	const browsingChessboardInterface = () => APP_STATE().repertoireState.browsingState.chessboard;
	const currentView = createMemo(() => UI().currentView());

	const repertoireLoading = () => REPERTOIRE_STATE().repertoires === undefined;

	const sidebarContent = (
		<>
			<div
				id="sidebar-inner"
				style={stylex(c.relative, c.zIndex(100))}
				class={clsx("md:pt-[12px]")}
			>
				<Switch fallback={<RepertoireHome />}>
					<Match when={currentView()}>
						<SidebarStack stack={UI().sidebarStack}></SidebarStack>
					</Match>
				</Switch>
			</div>
		</>
	);
	const visibility = createPageVisibility();
	const [lastVisible, setLastVisible] = createSignal(dayjs());
	createEffect((previousVisibility) => {
		if (visibility() && previousVisibility === false) {
			if (dayjs.duration(dayjs().diff(lastVisible())).hours() >= 1) {
				// refresh page
				window.location.reload();
			}
		}
		if (visibility()) {
			setLastVisible(dayjs());
		}
		return visibility();
	});
	// const sidebarChessboardInterface = createMemo(() => last(CHESSBOARD_STACK()));
	const visibleChessboardSource = () => {
		if (mode() === "openings_report") {
			return "openings_report";
		}
		return mode() === "review" ? "review" : mode() === "model_games" ? "model_games" : "browsing";
	};

	const visibleChessboard = createMemo(() => {
		if (visibleChessboardSource() === "openings_report") {
			return openingsReportChessboardInterface();
		}
		if (visibleChessboardSource() === "review") {
			return reviewChessboardInterface();
		}
		if (visibleChessboardSource() === "model_games") {
			return modelGamesChessboardInterface();
		}
		return browsingChessboardInterface();
	});

	createEffect(() => {
		if (!REPERTOIRE_STATE().onboarding.isOnboarding && !repertoireLoading()) {
			setTimeout(() => {
				if (isNative) {
					Notifications.registerNotifications();
				}
			}, 500);
		}
	});

	return (
		<>
			<PiecesSvgSheet />
			<SidebarLayout
				loading={repertoireLoading()}
				breadcrumbs={<NavBreadcrumbs />}
				sidebarContent={sidebarContent}
				settings={<SettingsButtons />}
				chessboardView={
					<>
						<ChessboardView
							class={clsx(visibleChessboardSource() !== "browsing" && "hidden")}
							chessboardInterface={browsingChessboardInterface()}
						/>
						<ChessboardView
							class={clsx(visibleChessboardSource() !== "review" && "hidden")}
							chessboardInterface={reviewChessboardInterface()}
						/>
						<ChessboardView
							class={clsx(visibleChessboardSource() !== "openings_report" && "hidden")}
							chessboardInterface={openingsReportChessboardInterface()}
						/>
						<ChessboardView
							class={clsx(visibleChessboardSource() !== "model_games" && "hidden")}
							chessboardInterface={modelGamesChessboardInterface()}
						/>
					</>
				}
				backSection={<BackSection chessboard={visibleChessboard()} />}
				belowChessboard={<ChessboardFooter chessboard={visibleChessboard()} />}
			/>
		</>
	);
};
