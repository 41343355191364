import { type JSX, createEffect, createSignal, onMount } from "solid-js";
import { Portal } from "solid-js/web";
import { USER_STATE, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { Pressable } from "./Pressable";

export const APP_PROMOTION_DISMISSED = "app_promotion_dismissed";

export enum ToastPromotionType {
	MobileApps = "app_promotion",
	FreeMonth = "free_month",
}

export type ToastPromotionDismissed = `${ToastPromotionType}_dismissed`;

export const ToastPromotion = (props: {
	children: JSX.Element;
	flag: ToastPromotionType;
}) => {
	const [mounted, setMounted] = createSignal(false);
	onMount(() => {
		setTimeout(() => {
			setMounted(true);
		}, 1000);
	});
	const hidden = () => USER_STATE().user?.flags.includes(`${props.flag}_dismissed`);
	createEffect(() => {
		if (!hidden() && mounted()) {
			trackEvent(`promotion.${props.flag}.shown`);
		}
	});

	return (
		<Portal mount={document.body}>
			<div
				class={clsx(
					"fixed  z-1000 bottom-0 right-0 transition-all transition-duration-300",
					hidden() ? "translate-x-full" : mounted() ? "translate-x-0" : "translate-x-full",
				)}
				id={`promotion-${props.flag}`}
			>
				<div
					class={clsx(
						"md:h-[400px] w-[300px] bg-gray-14 rounded-xl relative p-6 pt-0 column mr-4 mb-4 transition-all transition-duration-300",
					)}
					style={stylex(
						hidden() || !mounted()
							? c.shadow(0, 0, 0, 0, c.hsl(0, 0, 0, 0))
							: c.shadow(0, 10, 60, 0, c.hsl(0, 0, 0, 50)),
					)}
				>
					<Pressable
						onPress={() => {
							quick((s) => {
								trackEvent(`promotion.${props.flag}.dismissed`);
								if (!s.userState.user) return;
								s.userState.setFlag(`${props.flag}_dismissed`, true);
							});
						}}
						class="absolute  top-2 right-2 p-2"
					>
						<i class=" fa fa-xmark text-secondary text-base" />
					</Pressable>
					{props.children}
				</div>
			</div>
		</Portal>
	);
};
