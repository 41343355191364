import { isEmpty } from "lodash-es";
import { Line } from "~/types/Line";
import type { Side } from "~/types/Side";

export const genLichessLinkToEpd = (pos: string): string => {
	const path = encodeURIComponent(pos).replace(/%2F/g, "/");
	return `https://lichess.org/analysis/${path}`;
};

export const getLichessLink = (line: string[], options: { side?: Side; ply?: number }) => {
	if (isEmpty(line)) {
		// TODO: figure out a way to open up analysis from black side
		return "https://lichess.org/analysis";
	}
	const side = options.side ?? Line.sideOfLastMove(line);
	let link = `https://lichess.org/analysis/pgn/${line.join("_")}?color=${side}`;
	if (options.ply) {
		link += `#${options.ply}`;
	}
	return link;
};
