import type { SanPlus } from "~/types/SanPlus";
import type { BySide, Side } from "~/types/Side";

export type MoveIdentifier = string;

export interface PendingLine {
	knownLine: SanPlus[];
	pendingLine: SanPlus[];
}

export const formatIncidence = (incidence: number) => {
	const format = (i: number) => {
		return `${removeTrailingZeros((incidence * 100).toFixed(i))}%`;
	};
	const x = format(1);
	if (x === "0%") {
		return format(2);
	}
	return x;
};

const removeTrailingZeros = (n: string) => {
	return n.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1");
};

export const bySide = <T,>(f: (_: Side) => T): BySide<T> => {
	return {
		white: f("white"),
		black: f("black"),
	};
};
