import type { AxiosResponse } from "axios";
import toast from "solid-toast";
import { Spacer } from "~/components/Space";
import { isDevelopment } from "./env";

export const displayServerError = (e: { response?: AxiosResponse }) => {
	const message = e?.response?.data?.body;
	let title = e?.response?.data?.title;
	if (e.response?.status === 413) {
		title = "File too large - 4MB limit";
	}
	if (!title) {
		return;
	}
	toast.error(
		() => {
			return (
				<div class="flex !flex-col">
					<p class="text-lg font-bold">{title}</p>
					{message && (
						<>
							<Spacer height={12} />
							<p>{message}</p>
						</>
					)}
				</div>
			);
		},
		{ duration: isDevelopment ? 100000 : undefined },
	);
};
