import { onMount } from "solid-js";
import { Match, Switch } from "solid-js";
import { APP_STATE, UI, quick, useVisualizationState } from "~/utils/app_state";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { NavBreadcrumbs, SidebarLayout } from "./SidebarLayout";
import { Spacer } from "./Space";

import { useNavigate } from "@solidjs/router";
import { clsx } from "~/utils/classes";
import { trackEvent } from "~/utils/trackEvent";
import { AnalyzeOnLichessButton } from "./AnalyzeOnLichessButton";
import { BackSection } from "./BackSection";
import { BlunderPuzzleTraining } from "./BlunderPuzzleTraining";
import { SettingsButtons } from "./Settings";
import { SidebarStack } from "./SidebarStack";
import { SidebarTemplate } from "./SidebarTemplate";
import { VisionTraining } from "./VisionTraining";
import { VisualizationTraining } from "./VisualizationTraining";
import { PiecesSvgSheet } from "./chessboard/Chessboard";
import { OpeningTrainerRedirect } from "./chessmadra/OpeningTrainerRedirect";

export const ChessMadra = (props: { initialTool?: string }) => {
	onMount(() => {
		if (props.initialTool === "visualization") {
			quick((_s) => {
				UI().pushView(VisualizationTraining);
			});
		} else if (props.initialTool === "vision") {
			quick((_s) => {
				UI().pushView(VisionTraining);
			});
		} else if (props.initialTool === "blunder-puzzles") {
			quick((_s) => {
				UI().pushView(BlunderPuzzleTraining);
			});
		}
	});
	const responsive = useResponsiveV2();
	const activeTool = () => APP_STATE().trainersState.getActiveTool();
	const view = () => UI().currentView();
	const sidebarContent = (
		<>
			<Switch fallback={<DirectorySidebar />}>
				<Match when={view()}>
					<SidebarStack stack={UI().sidebarStack}></SidebarStack>
				</Match>
			</Switch>
		</>
	);

	const chessboard = () =>
		activeTool() === "board-vision"
			? APP_STATE().trainersState.visionState.chessboard
			: activeTool() === "blunder-puzzles"
				? APP_STATE().trainersState.blunderPuzzlesState.chessboard
				: APP_STATE().trainersState.visualizationState.chessboard;
	const [isPlaying] = useVisualizationState((s) => [s.isPlaying]);
	const [startedSolvingVis] = useVisualizationState((s) => [s.startedSolving]);
	const [flashPlayButton] = useVisualizationState((s) => [s.pulsePlay]);
	const belowChessboard = () => (
		<div class="row items-center w-full gap-4 justify-end">
			<Switch>
				<Match when={activeTool() === "visualization" && !startedSolvingVis()}>
					<>
						<div
							class={clsx(
								"row items-stretch w-full",
								responsive().isMobile ? "padding-sidebar" : "",
							)}
						>
							<button
								class={clsx(
									"grow h-[60px] py-0 text-[22px] overflow-hidden",
									"row w-full cursor-pointer items-center justify-center rounded-sm bg-blue-50",
									flashPlayButton() && "animate-pulse",
								)}
								use:onClick={() => {
									trackEvent("visualization.play_hidden_moves");
									quick((s) => {
										s.trainersState.visualizationState.visualizeHiddenMoves();
									});
								}}
							>
								<i class={`text-primary fa-sharp ${isPlaying() ? "fa-pause" : "fa-play"}`} />
							</button>
						</div>
						<Spacer height={12} />
					</>
				</Match>
			</Switch>
			<AnalyzeOnLichessButton chessboard={chessboard()} short={activeTool() === "visualization"} />
		</div>
	);

	return (
		<SidebarLayout
			loading={false}
			breadcrumbs={<NavBreadcrumbs />}
			sidebarContent={sidebarContent}
			settings={<SettingsButtons />}
			chessboardInterface={chessboard()}
			backSection={<BackSection />}
			belowChessboard={belowChessboard()}
		/>
	);
};

export const DirectorySidebar = () => {
	const navigate = useNavigate();
	return (
		<>
			<PiecesSvgSheet />
			<SidebarTemplate
				header="Welcome to Chess Madra!"
				bodyPadding={true}
				actions={[
					{
						onPress: () => {
							navigate("/blunder-puzzles");
							// console.log("pushing!");
							// UI().pushView(BlunderPuzzleTraining);
						},
						style: "wide",
						text: "Blunderbash",
					},
					{
						onPress: () => {
							navigate("/visualization");
							UI().pushView(VisualizationTraining);
						},
						style: "wide",
						text: "Visualization",
					},
					{
						onPress: () => {
							navigate("/vision");
							UI().pushView(VisionTraining);
						},
						style: "wide",
						text: "Board Vision",
					},
					{
						onPress: () => {
							UI().pushView(OpeningTrainerRedirect);
						},
						style: "wide",
						text: "Opening Builder",
					},
				]}
			>
				<p class={"body-text"}>Check out some of our training tools!</p>
				<Spacer height={12} />
			</SidebarTemplate>
		</>
	);
};
