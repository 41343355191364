import { type JSXElement, Show } from "solid-js";
import { clsx } from "~/utils/classes";
import { Spacer } from "./Space";

export const TextAndIcon = (props: {
	text: JSXElement | string;
	icon: JSXElement | string;
	class?: string;
}) => {
	return (
		<div class={clsx("row items-center", props.class)}>
			<Show when={typeof props.text === "string"} fallback={props.text}>
				<p class="">{props.text}</p>
			</Show>
			<Spacer width={8} />
			<Show when={typeof props.icon === "string"} fallback={props.icon}>
				<i class={props.icon as string} />
			</Show>
		</div>
	);
};
