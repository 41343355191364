import { c, stylex } from "~/utils/styles";

export const OfficalRepertoireTag = () => {
	return (
		<div class="row space-x-2 items-center">
			<i
				style={stylex(c.duotone(c.gray[95], c.blue[30]))}
				class="fa-duotone fa-badge-check text-sm"
			></i>
			<p class="font-medium text-primary text-xs">Official Repertoire</p>
		</div>
	);
};
