import type { AppState } from "./app_state";
import { createQuick } from "./quick";
import type { StateGetter, StateSetter } from "./state_setters_getters";

export interface DebugState {
	debugUi: boolean;
	underConstruction?: boolean;
	quick: (fn: (_: DebugState) => void) => void;
}

export const getInitialDebugState = (
	_set: StateSetter<AppState, any>,
	_get: StateGetter<AppState, any>,
) => {
	// const _set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
	// 	return _set((s) => fn([s.debugState, s]));
	// };
	const setOnly = <T,>(fn: (stack: DebugState) => T, _id?: string): T => {
		return _set((s) => fn(s.debugState));
	};
	// const _get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
	// 	return _get((s) => fn([s.debugState, s]));
	// };
	const initialState = {
		...createQuick<DebugState>(setOnly),
		debugUi: false,
		underConstruction: false,
	} as DebugState;

	return initialState;
};
