import { isNil, toNumber } from "lodash-es";
import type { Side } from "./Side";
import type { StockfishReport } from "./StockfishReport";

export function formatStockfishEval(stockfish: StockfishEval) {
	let x = "";
	if (!isNil(stockfish?.eval)) {
		const rounded = (stockfish.eval / 100).toFixed(1);
		if (rounded === "0.0" || rounded === "-0.0") {
			x = "=";
		} else if (stockfish.eval > 0) {
			x = `+${rounded}`;
		} else {
			x = `${rounded}`;
		}
	} else if (stockfish?.mate) {
		x = `#${stockfish.mate[1] === "white" ? "" : "-"}${stockfish.mate[0]}`;
	}
	// if (debug) {
	// 	x += ` (${stockfish.nodesK}k)`;
	// }
	return x;
}

export class StockfishEval {
	eval?: number;
	mate?: [number, Side];
	pv?: string;
	thinking = false;

	constructor(x: string | [StockfishReport, Side]) {
		if (typeof x === "string") {
			if (x.includes("M")) {
				if (x === "M-w") {
					this.mate = [0, "white"];
				} else if (x === "M-b") {
					this.mate = [0, "black"];
				} else {
					const side = x.includes("-") ? "black" : "white";
					this.mate = [Number.parseInt(x.split("M")[1]), side];
				}
			} else {
				this.eval = toNumber(x);
			}
		} else {
			this.eval = x[0].eval;
			const mateMoves = x[0].mate;
			if (!isNil(mateMoves)) {
				this.mate = [
					Math.abs(mateMoves),
					mateMoves === 0 ? x[1] : mateMoves > 0 ? "white" : "black",
				];
			}
		}
	}

	toCp(): number {
		if (!isNil(this.eval)) {
			return this.eval;
		}
		if (!isNil(this.mate)) {
			return mateToCp(this.mate[0]) * (this.mate[1] === "black" ? -1 : 1);
		}
		return 0;
	}

	getPovCp(pov: "white" | "black"): number {
		const cp = this.toCp();
		return pov === "black" ? -cp : cp;
	}

	getPovWinPercentage(pov: "white" | "black"): number {
		const cp = this.getPovCp(pov);
		return StockfishEval.toWinPercentage(cp);
	}

	static toWinPercentage(cp: number): number {
		return 0.5 + 0.5 * (2.0 / (1.0 + Math.exp(-0.00368208 * cp)) - 1.0);
	}
}

function mateToCp(mate: number): number {
	const cp = (21.0 - Math.min(mate, 10.0)) * 100.0;
	return cp;
}
