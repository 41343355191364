import type { createForm } from "@felte/solid";
import { type JSX, createEffect } from "solid-js";
import { clsx } from "~/utils/classes";

type CheckboxInputProps = {
	name: string;
	data?: ReturnType<typeof createForm>["data"];
	class?: string;
	label?: string;
	error?: string;
	setFields: (a: string, val: boolean) => void;
	errors?: Record<string, string[] | null>;
} & JSX.InputHTMLAttributes<HTMLInputElement>;

export function CheckboxInput(props: CheckboxInputProps) {
	// const error = () => {
	// 	return props.errors?.[props.name]?.[0];
	// };
	const value = () =>
		props.name
			? props.data?.(($data) => {
					return $data[props.name!];
				})
			: undefined;
	createEffect(() => {});
	return (
		<div
			class={clsx("flex row items-center gap-2 cursor-pointer select-none", props.class)}
			onClick={() => {
				props.setFields(props.name, !value());
			}}
		>
			<div
				class={clsx(
					"square-5 flex center border-solid border-1 ",
					value() ? "border-gray-50" : "border-gray-30",
				)}
			>
				<i class={clsx(value() ? "fa-solid fa-check text-xs text-gray-50" : "")}></i>
			</div>
			<p>{props.label}</p>
		</div>
	);
}
