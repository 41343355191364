import { isNil, sortBy, sumBy } from "lodash-es";
import { GameResultsDistribution } from "~/types/GameResults";
import { MoveTag } from "~/types/MoveTag";
import type { PositionReport } from "~/types/PositionReport";
import type { Side } from "~/types/Side";
import type { StockfishEval } from "~/types/StockfishEval";
import { SuggestedMove } from "~/types/SuggestedMove";
import { type ScoreTable, TableResponse } from "~/types/TableResponse";
import { isNegligiblePlayrate } from "./results_distribution";
import type { BrowsingMode } from "./ui_state";

export enum TableResponseScoreSource {
	Start = "start",
	Eval = "eval",
	Winrate = "winrate",
	Playrate = "playrate",
	Needed = "needed",
	MasterPlayrate = "masterPlayrate",
	Incidence = "incidence",
	BestMove = "bestMove",
	OneOff = "oneOff",
}

export const scoreTableResponses = (
	tableResponses: TableResponse[],
	report: PositionReport,
	bestStockfishEval: StockfishEval | undefined,
	side: Side,
	mode: BrowsingMode,
	ownSide: boolean,
	useMasters: boolean,
): TableResponse[] => {
	const positionWinRate = report
		? GameResultsDistribution.getWinRateRange(report.results, side)[0]
		: Number.NaN;

	if (mode === "browse") {
		return sortBy(tableResponses, [
			(tableResponse: TableResponse) => {
				if (tableResponse.reviewStatus) {
					return -tableResponse.reviewStatus.due;
				}
				return 0;
			},
			(tableResponse: TableResponse) => {
				if (tableResponse.reviewStatus) {
					return tableResponse.reviewStatus.earliestDue;
				}
				return Number.POSITIVE_INFINITY;
			},
		]);
	}
	const sorts: ((tableResponse: TableResponse) => number | undefined)[] = [];
	if (!ownSide) {
		sorts.push((tableResponse: TableResponse) => {
			if (!tableResponse.suggestedMove) {
				return undefined;
			}
			const playRate = tableResponse.suggestedMove.incidence ?? 0;
			return -playRate;
		});
	} else {
		if (useMasters) {
			sorts.push((tableResponse: TableResponse) => {
				if (!tableResponse.suggestedMove) {
					return undefined;
				}
				const masterPlayRate = SuggestedMove.getPlayRate(tableResponse.suggestedMove, report, true);
				if (masterPlayRate && !isNegligiblePlayrate(masterPlayRate)) {
					tableResponse.score = masterPlayRate;
					return -masterPlayRate;
				}
				return 0;
			});
		}
		sorts.push((tableResponse: TableResponse) => {
			const score = scoreTableResponseEffectiveness(
				tableResponse,
				report,
				positionWinRate,
				bestStockfishEval,
				side,
				{ mutate: !useMasters },
			);
			return -score;
		});
	}

	return sortBy(tableResponses, sorts);
};

const scoreTableResponseEffectiveness = (
	tableResponse: TableResponse,
	report: PositionReport,
	positionWinRate: number,
	bestStockfishEval: StockfishEval | undefined,
	side: Side,
	opts: { mutate: boolean } = { mutate: true },
): number => {
	const weights = EFFECTIVENESS_WEIGHTS_PEERS;
	// let san =
	//   tableResponse.suggestedMove?.sanPlus ??
	//   tableResponse.repertoireMove?.sanPlus;
	// return failOnAny(san);
	const scoreTable = { factors: [], notes: [] } as ScoreTable;
	if (isNil(report)) {
		const score = weights.startScore;
		return score;
	}
	const suggestedMove = tableResponse.suggestedMove;
	const stockfish = TableResponse.getStockfishEval(tableResponse);
	if (stockfish) {
		const mate = stockfish?.mate;
		if (mate) {
			const mateSide = mate[1];
			if (mateSide === side) {
				scoreTable.factors.push({
					source: TableResponseScoreSource.Eval,
					value: 10000,
				});
			} else {
				scoreTable.factors.push({
					source: TableResponseScoreSource.Eval,
					value: -10000,
				});
			}
		} else {
			const expectedWinBefore = bestStockfishEval?.getPovWinPercentage(side);
			const expectedWinAfter = stockfish.getPovWinPercentage(side);
			if (!isNil(expectedWinBefore) && !isNil(expectedWinAfter)) {
				const winrateChange = expectedWinAfter - expectedWinBefore;
				scoreTable.factors.push({
					source: TableResponseScoreSource.Eval,
					value: winrateChange,
				});
			}
		}
	}
	if (suggestedMove) {
		const rateAdditionalWeight = Math.min(
			GameResultsDistribution.getTotalGames(tableResponse?.suggestedMove?.results!)! / 100,
			1,
		);
		const playRate = SuggestedMove.getPlayRate(tableResponse.suggestedMove!, report)!;
		if (!isNegligiblePlayrate(playRate)) {
			const scoreForPlayrate = playRate * rateAdditionalWeight;
			scoreTable.factors.push({
				source: TableResponseScoreSource.Playrate,
				value: scoreForPlayrate,
			});
			// if (m.sanPlus === DEBUG_MOVE) {
			//   console.log(
			//     `For ${m.sanPlus}, the playrate is ${playRate}, Score change is ${scoreForPlayrate}`
			//   );
			// }
		} else if (weights[TableResponseScoreSource.Playrate] !== 0) {
			scoreTable.notes.push("Insufficient games for playrate");
			scoreTable.factors.push({
				source: TableResponseScoreSource.OneOff,
				// idk maybe this is terrible
				value: -weights.startScore,
			});
		}
		// @ts-ignore
		if (weights[TableResponseScoreSource.Needed] > 0) {
			if (tableResponse.suggestedMove?.needed) {
				scoreTable.factors.push({
					source: TableResponseScoreSource.Needed,
					value: 10000.0,
				});
			}
		}
		const [winrateLowerBound, winrateUpperBound] = GameResultsDistribution.getWinRateRange(
			tableResponse.suggestedMove?.results!,
			side,
		);
		const winrateChange = winrateLowerBound - positionWinRate;
		if (!Number.isNaN(winrateChange) && !isNil(winrateChange)) {
			scoreTable.notes.push(
				`Winrate change: ${winrateChange}, winrateLowerBound: ${winrateLowerBound}, winrateUpperBound: ${winrateUpperBound}`,
			);
			const scoreForWinrate = winrateChange;
			scoreTable.factors.push({
				source: TableResponseScoreSource.Winrate,
				value: scoreForWinrate,
				max: 0.5,
				min: -0.5,
			});
		}
	}
	scoreTable.factors.forEach((f) => {
		// @ts-ignore
		f.weight = weights[f.source] ?? 1.0;
		// @ts-ignore
		f.total = f.weight * f.value;
		if (f.max) {
			f.total = Math.min(f.total, f.max);
		}
		if (f.min) {
			f.total = Math.max(f.total, f.min);
		}
	});
	if (tableResponse.tags.includes(MoveTag.BestMove)) {
		scoreTable.factors.push({
			source: TableResponseScoreSource.BestMove,
			// weight: 1.0,
			value: 0,
			total: 10000,
		});
	}
	if (weights.startScore) {
		scoreTable.factors.push({
			source: TableResponseScoreSource.Start,
			weight: 1.0,
			value: weights.startScore,
			total: weights.startScore,
		});
	}
	const score = sumBy(scoreTable.factors, (f) => {
		return f.total || 0;
	});
	if (opts.mutate) {
		tableResponse.scoreTable = scoreTable;
		tableResponse.score = score;
	}
	return score;
};

export const EFFECTIVENESS_WEIGHTS_PEERS = {
	startScore: 2.0,
	eval: 15,
	winrate: 10.0,
	playrate: 1,
	masterPlayrate: 0.0,
};

export const shouldUsePeerRates = (pr: PositionReport) => {
	const totalGames = GameResultsDistribution.getTotalGames(pr?.masterResults);
	return !totalGames || totalGames < 10;
};
