import { SoundEffects } from "capacitor-sound-effects";
import { Howl } from "howler";
import { USER_STATE } from "./app_state";
import { getStatic } from "./assets";
import { isIos } from "./env";
import { type FrontendSettingOption, SoundSetting } from "./frontend_settings";

export type SoundFiles = {
	sound: Howl;
	filename: string;
};

export const SOUNDS: Record<"move" | "capture" | "success" | "failure" | "drop", SoundFiles> = {
	move: {
		sound: new Howl({
			src: [getStatic("/sounds/move.mp3")],
			html5: false,
		}),
		filename: "move.mp3",
	},
	capture: {
		sound: new Howl({
			src: [getStatic("/sounds/capture.mp3")],
			html5: false,
		}),
		filename: "capture.mp3",
	},
	success: {
		sound: new Howl({
			src: [getStatic("/sounds/success.mp3")],
			html5: false,
		}),
		filename: "success.mp3",
	},
	failure: {
		sound: new Howl({
			src: [getStatic("/sounds/failure.mp3")],
			html5: false,
		}),
		filename: "failure.mp3",
	},
	drop: {
		sound: new Howl({
			src: [getStatic("/sounds/drop.mp3")],
			html5: false,
		}),
		filename: "drop.mp3",
	},
};

export const playSound = async (sounds: SoundFiles, duration?: number) => {
	const sound_setting = USER_STATE().getFrontendSetting(
		"sound",
	) as FrontendSettingOption<SoundSetting>;
	if (sound_setting.value === SoundSetting.Muted) {
		return;
	}

	let sound: Howl | null = null;
	sound = sounds.sound;
	if (isIos) {
		SoundEffects.playSound({ filename: sounds.filename.replace(".mp3", ".wav") });
		return;
	}
	if (duration) {
		const soundDuration = sound.duration();
		if (soundDuration) {
			// sound.rate((soundDuration * 1000) / duration);
			sound.play();
		}
	} else {
		// sound.rate(1);
		sound.play();
		sound.once("playerror", (e) => {
			// biome-ignore lint/suspicious/noConsoleLog: <explanation>
			console.log("playerror", e);
		});
		sound.once("loaderror", (e) => {
			// biome-ignore lint/suspicious/noConsoleLog: <explanation>
			console.log("loaderror", e);
		});
		sound.once("play", (e) => {
			// biome-ignore lint/suspicious/noConsoleLog: <explanation>
			console.log("play", e);
		});
	}
};
