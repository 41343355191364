import type { GetBlunderPuzzleResponse } from "~/rspc";
import { MoveRating } from "./MoveRating";

export type BlunderPuzzle = GetBlunderPuzzleResponse["blunderPuzzles"][0];

export namespace BlunderPuzzle {
	export const getMoveRating = (puzzle: BlunderPuzzle, san: string): MoveRating => {
		let ratings = [MoveRating.Blunder, MoveRating.Inaccuracy, MoveRating.Mistake];
		for (let moveRating of ratings) {
			if (puzzle.sansByMoveRating[moveRating]?.includes(san)) {
				return moveRating;
			}
		}
		return MoveRating.Good;
	};
}
