import { useLocation } from "@solidjs/router";
import { type Component, Match, Switch, createEffect, onCleanup, onMount } from "solid-js";
import LandingPageWrapper from "~/components/LandingPageWrapper";
import { APP_STATE, CARDS_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { isChesscards, isNative } from "~/utils/env";
import { trackEvent } from "~/utils/trackEvent";
import { identifyOnce } from "~/utils/user_properties";
import { AuthStatus } from "~/utils/user_state";
import { ChessbookRoot } from "./ChessbookRoot";
import { ChesscardsRoot } from "./ChesscardsRoot";
import { OnboardingIntro, OnboardingIntroChesscards } from "./SidebarOnboarding";

export const PageWrapper = (props: { initialView?: Component }) => {
	const token = () => APP_STATE().userState.token;
	const dataLoading = () => {
		if (isChesscards) {
			return CARDS_STATE().cards === undefined;
		}
		return REPERTOIRE_STATE().repertoires === undefined;
	};
	const authStatus = () => USER_STATE().authStatus;
	const location = useLocation();

	onMount(() => {
		console.debug("Mounting page wrapper", APP_STATE());
		identifyOnce({ initial_page: location.pathname });
	});

	createEffect(() => {
		if (authStatus() === AuthStatus.Authenticated && dataLoading()) {
			quick((s) => {
				if (isChesscards) {
					s.cardsState.initState();
				} else {
					s.repertoireState.initState();
				}
			});
		}
	});
	onMount(() => {
		quick((s) => {
			if (props.initialView) {
				s.userState.pastLandingPage = true;
				s.repertoireState.onboarding.isOnboarding = false;
				UI().pushView(props.initialView);
			}
		});
	});
	onCleanup(() => {
		console.debug("Cleaning up page wrapper", APP_STATE());
	});
	const showLandingPage = () => {
		return !(token().value || USER_STATE().pastLandingPage || dataLoading());
	};
	createEffect(() => {
		if (showLandingPage() && isNative) {
			quick((s) => {
				trackEvent("onboarding.started");
				s.repertoireState.onboarding.isOnboarding = true;
				UI().pushView(OnboardingIntro);
				s.userState.pastLandingPage = true;
			});
		}
		if (showLandingPage() && isChesscards) {
			quick((s) => {
				trackEvent("onboarding.started");
				s.cardsState.onboarding = true;
				UI().pushView(OnboardingIntroChesscards);
				s.userState.pastLandingPage = true;
			});
		}
	});

	// return <SidebarLayout mode={mode()} />;
	return (
		<Switch fallback={<LandingPageWrapper />}>
			<Match when={!showLandingPage() || isNative}>
				<Switch fallback={<ChessbookRoot />}>
					<Match when={isChesscards}>
						<ChesscardsRoot />
					</Match>
				</Switch>
			</Match>
		</Switch>
	);
};
