import { type Component, For, type JSX, Show } from "solid-js";
import { Spacer } from "~/components/Space";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import { CMText } from "./CMText";
import { type SidebarAction, SidebarFullWidthButton, SidebarSectionHeader } from "./SidebarActions";

export const SidebarSelectOneOf: Component<{
	title?: string;
	description?: string;
	equality?: (choice: any, activeChoice: any) => boolean;
	choices: any[];
	activeChoice: any;
	onSelect: (_: any, i?: number) => void;
	renderChoice: (x: any, active: boolean) => JSX.Element;
	// todo: typing is hard
}> = (props) => {
	const actions = () =>
		props.choices.map((choice, i) => {
			const active = props.equality
				? props.equality(choice, props.activeChoice)
				: choice === props.activeChoice;
			return {
				style: "secondary" as SidebarAction["style"],
				text: props.renderChoice(choice, active),
				onPress: () => props.onSelect(choice, i),
				off: !active,
				right: (
					<i
						class={clsx("fa fa-check transition-opacity", !active && "opacity-0")}
						style={stylex(c.fg(c.colors.text.primary))}
					/>
				),
			} as SidebarAction;
		});
	return (
		<div style={stylex(c.column, c.fullWidth)}>
			<Show when={props.title}>
				<SidebarSectionHeader text={props.title!} class="!text-secondary" />
			</Show>
			<Show when={props.description}>
				<>
					<CMText class={"body-text padding-sidebar"}>{props.description}</CMText>
					<Spacer height={12} />
				</>
			</Show>
			<div style={stylex(c.fullWidth)} class="border-t-1 border-t-solid border-t-border">
				<For each={actions()}>
					{(action) => {
						return <SidebarFullWidthButton action={action} />;
					}}
				</For>
			</div>
		</div>
	);
};
