import anime from "animejs";
import { type Accessor, type JSX, type Setter, createSignal } from "solid-js";
import { clsx } from "~/utils/classes";
import type { StartMistakesAnimation } from "~/utils/repertoire_state";
import { LoadingSpinner } from "./LoadingSpinner";
import { MistakesStatus } from "./MistakesStatus";
import { ReviewText } from "./ReviewText";

export const createMoveReviewAnimation = (): {
	toReviewAnimation: Accessor<JSX.Element>;
	mistakesAnimation: Accessor<JSX.Element>;
	startAnimation: StartMistakesAnimation;
} => {
	const [statusRef, setStatusRef] = createSignal<HTMLDivElement | undefined>(undefined);
	const [loadingRef, setLoadingRef] = createSignal<HTMLDivElement | undefined>(undefined);
	const [correctRef, setCorrectRef] = createSignal<HTMLDivElement | undefined>(undefined);
	const [initialDueRef, setInitialDueRef] = createSignal<HTMLDivElement | undefined>(undefined);
	const [finalDueRef, setFinalDueRef] = createSignal<HTMLDivElement | undefined>(undefined);
	const Container = (props: {
		children: JSX.Element;
		ref?: Setter<HTMLDivElement | undefined>;
		visible?: boolean;
	}) => (
		<div
			class={clsx(
				"col-start-1 col-span-1 row-start-1 row-end-1 items-end justify-center",
				!props.visible && "opacity-0",
			)}
			ref={props.ref}
		>
			{props.children}
		</div>
	);
	const [initialDue, setInitialDue] = createSignal(0);
	const [finalDue, setFinalDue] = createSignal(0);
	const [earliestDue, setEarliestDue] = createSignal<string | null>(null);
	const toReviewAnimation = (
		<>
			<div class="grid grid-cols-1 grid-rows-1">
				<Container ref={setInitialDueRef} visible>
					<ReviewText numDue={initialDue()} />
				</Container>
				<Container ref={setFinalDueRef}>
					<ReviewText numDue={finalDue()} date={earliestDue()} />
				</Container>
			</div>
		</>
	);
	const [numCorrect, setNumCorrect] = createSignal(0);
	const mistakesAnimation = (
		<div class="grid grid-cols-1 grid-rows-1">
			<Container ref={setCorrectRef}>
				<ReviewText
					numDue={numCorrect()}
					text={`${numCorrect()} Correct`}
					iconColor={clsx("text-green-50")}
					textColor={clsx("text-secondary")}
					icon="fa fa-circle-check"
				/>
			</Container>
			<Container ref={setLoadingRef}>
				<LoadingSpinner class={clsx("text-[12px]")} />
			</Container>
			<Container ref={setStatusRef}>
				<MistakesStatus class={clsx("")} />
			</Container>
		</div>
	);
	const [animating, setAnimating] = createSignal(false);
	const startAnimation: StartMistakesAnimation = ({
		numCorrect,
		initialDue,
		finalEarliestDue,
		finalDue,
	}) => {
		setAnimating(true);
		setNumCorrect(numCorrect);
		setInitialDue(initialDue);
		setFinalDue(finalDue);
		setEarliestDue(finalEarliestDue);
		if (!correctRef()) return;
		if (!loadingRef()) return;
		if (!statusRef()) return;
		initialDueRef()!.style.opacity = "1";
		loadingRef()!.style.opacity = "1";
		const tl = anime.timeline({
			autoplay: true,
		});
		tl.add({
			duration: 200,
			targets: loadingRef(),
			opacity: 0,
			endDelay: 200,
			easing: "easeInOutSine",
		})
			.add({
				targets: loadingRef(),
				duration: 200,
				opacity: 0,
				easing: "easeInOutSine",
			})
			.add({
				targets: correctRef(),
				duration: 200,
				endDelay: 2200,
				opacity: 1.0,
				easing: "easeInOutSine",
			})
			.add({
				targets: initialDueRef(),
				duration: 200,
				endDelay: 200,
				opacity: 0.0,
				translateY: -10,
				easing: "easeInOutSine",
			})
			.add({
				targets: [finalDueRef()],
				duration: 200,
				opacity: 1.0,
				translateY: [10, 0],
				easing: "easeInOutSine",
			})
			.add(
				{
					targets: correctRef(),
					duration: 200,
					opacity: 0.0,
					translateY: -10,
					easing: "easeInOutSine",
				},
				"-=750",
			)
			.add(
				{
					targets: [statusRef()],
					duration: 200,
					// not needed, just for debugging
					endDelay: 1000,
					opacity: 1.0,
					translateY: [10, 0],
					easing: "easeInOutSine",
				},
				"-=200",
			)
			.finished.then(() => {
				setAnimating(false);
				tl.seek(0);
			});
	};
	return {
		toReviewAnimation: () => (animating() && initialDue() > 0 ? toReviewAnimation : null),
		mistakesAnimation: () => (animating() ? mistakesAnimation : null),
		startAnimation,
	};
};
