export function NewBadge(props: { color: any }) {
	return (
		<svg viewBox="0 0 31 13" xmlns="http://www.w3.org/2000/svg" height="100%" width="100%">
			<path
				d="m29 0h-27c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h27c1.1 0 2-.9 2-2v-9c0-1.1-.9-2-2-2zm-19.56 9.73c0 .45-.33.8-.78.8-.39 0-.58-.22-.79-.48l-3.46-4.45v4.12c0 .46-.34.81-.79.81s-.8-.35-.8-.81v-6.45c0-.45.34-.8.78-.8.4 0 .58.22.81.52l3.45 4.42v-4.13c-.01-.46.33-.81.79-.81s.79.35.79.81v6.44zm6.51.66h-3.95c-.45 0-.79-.34-.79-.79v-6.2c0-.45.34-.79.79-.79h3.81c.42 0 .73.31.73.72s-.32.72-.73.72h-3v1.58h2.06c.4 0 .7.29.7.68s-.29.67-.7.67h-2.06v1.97h3.15c.42 0 .73.32.73.72s-.32.72-.73.72zm12.78-6.74-2.38 6.34c-.13.35-.4.54-.74.54s-.61-.19-.74-.54l-1.7-4.7-1.7 4.7c-.13.35-.39.54-.73.54s-.62-.19-.75-.54l-2.38-6.34c-.07-.16-.09-.28-.09-.39 0-.44.35-.79.8-.79.34 0 .62.2.73.52l1.71 4.74 1.64-4.65c.13-.38.41-.6.78-.6s.65.22.78.6l1.65 4.65 1.68-4.72c.12-.33.39-.53.74-.53.45 0 .79.35.79.79 0 .12-.02.24-.08.39z"
				fill={props.color}
			/>
		</svg>
	);
}
