import { Capacitor } from "@capacitor/core";
import { isNil } from "lodash-es";
import { isServer } from "solid-js/web";
import { isDevelopment, isNative } from "./env";

let baseApiUrl: string | undefined = undefined;
let baseFrontendUrl: string | undefined = undefined;
if (isDevelopment) {
	baseApiUrl = `${isServer ? "http://localhost" : `http://${window.location.hostname}`}:8040`;
	if (isNative) {
		baseApiUrl = "http://localhost:8040";
	}
	if (
		Capacitor.getPlatform() === "ios" ||
		Capacitor.getPlatform() === "android" ||
		(!isServer && window.location.hostname.includes("ngrok"))
	) {
		// baseFrontendUrl = "https://chessbook.ngrok.io";
		baseApiUrl = "https://chessbook.ngrok.io";
	}
}

if (typeof window !== "undefined" && isNil(baseFrontendUrl)) {
	baseFrontendUrl = window.location.origin;
}

if (process.env.NODE_ENV === "production" && isNative) {
	baseApiUrl = "https://chessbook.com";
	baseFrontendUrl = "https://chessbook.com";
}

if (import.meta.env.VITE_API_ENV === "production") {
	baseApiUrl = "https://chessbook.com";
}

if (baseApiUrl === undefined && typeof window !== "undefined") {
	baseApiUrl = window.location.origin;
}

export const BASE_API_URL = baseApiUrl;
export const BASE_FRONTEND_URL = baseFrontendUrl;
