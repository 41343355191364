import { FetchTransport, createClient } from "@rspc/client";
import type { Procedures } from "../rspc"; // These were the bindings exported from your Rust code!
import { getAuthHeaders } from "./auth";
import { BASE_API_URL } from "./base_url";

// You must provide the generated types as a generic and create a transport (in this example we are using HTTP Fetch) so that the client knows how to communicate with your API.
export const rspcClient = createClient<Procedures>({
	// Refer to the integration your using for the correct transport.
	transport: new FetchTransport(`${BASE_API_URL ?? ""}/api/rspc`, (input: RequestInfo | URL) => {
		return fetch(input, {
			headers: getAuthHeaders(),
		});
	}),
});
