import type { Square } from "@lubert/chess.ts/dist/types";
import {
	CategoryScale,
	Chart,
	Filler,
	LineController,
	LineElement,
	LinearScale,
	PointElement,
} from "chart.js";
import { filter, isEmpty, last, nth, zip } from "lodash-es";
import {
	type Accessor,
	For,
	Index,
	type JSXElement,
	Show,
	createEffect,
	createMemo,
	createSignal,
	onMount,
} from "solid-js";
import { Portal } from "solid-js/web";
import { ChessGame } from "~/types/ChessGame";
import type { StockfishEval } from "~/types/StockfishEval";
import type { EnrichedComponent } from "~/types/View";
import { Kep } from "~/types/kep";
import { renderAnnotation } from "~/utils/annotations";
import { APP_STATE, MODEL_GAMES_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { isDevelopment } from "~/utils/env";
import type { MoveHint } from "~/utils/hints";
import { isMobile } from "~/utils/isMobile";
import { plural } from "~/utils/pluralize";
import { registerViewMode } from "~/utils/register_view_mode";
import { onBack } from "~/utils/signals/onBack";
import { onKeyPress } from "~/utils/signals/onKeyPress";
import { c, stylex } from "~/utils/styles";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { Bullet } from "./Bullet";
import { CMText } from "./CMText";
import { ChessboardArrowView } from "./ChessboardArrow";
import { ModelGameHistory } from "./ModelGameHistory";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { type AnimateSidebar, SidebarSlideContainer } from "./SidebarSlideContainer";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";
import { StockfishEvalPreview } from "./StockfishEvalPreview";

export const ModelGameViewer: EnrichedComponent<{ game: ChessGame }> = (props) => {
	const showingHint = () =>
		(MODEL_GAMES_STATE().hintStage === "hint" || MODEL_GAMES_STATE().hintStage === "arrow") &&
		!isEmpty(MODEL_GAMES_STATE().hints);
	const game = () => props.game;
	const chessboard = () => MODEL_GAMES_STATE().chessboard;
	const annotation: Accessor<string | undefined | null> = createMemo(() => {
		const epd = chessboard().get((s) => nth(s.positionHistory, -2));
		const san = chessboard().getLastMove()?.san;
		if (epd && san) {
			const kepKey = Kep.toKey({ epd, san });
			return REPERTOIRE_STATE().getAnnotation(kepKey)?.text;
		}
		return;
	});
	const numCorrect = () => MODEL_GAMES_STATE().guessedPlys.correct.size;
	const total = () =>
		MODEL_GAMES_STATE().guessedPlys.mistakes.size +
		MODEL_GAMES_STATE().guessedPlys.blunders.size +
		numCorrect();
	onMount(() => {
		quick((s) => {
			if (s.repertoireState.modelGamesState.activeGame?.id !== game().id) {
				s.repertoireState.modelGamesState.setActiveGame(game());
			}
		});
	});
	const progressIcons = () => {
		let _signal = MODEL_GAMES_STATE().totalGuesses;
		return [
			{
				icon: "fa-kit fa-tick bg-green-60 rounded-full",
				class: "",
				text: `${numCorrect()}`,
			},
			{
				icon: "fa-kit fa-inaccuracy",
				class: "bg-gray-80 rounded-full",
				text: MODEL_GAMES_STATE().guessedPlys.inaccuracies.size,
			},
			{
				icon: "fa-kit fa-mistake",
				class: "bg-orange-45 rounded-full",
				text: MODEL_GAMES_STATE().guessedPlys.mistakes.size,
			},
			{
				icon: "fa-kit fa-blunder",
				class: "bg-red-60 rounded-full",
				text: MODEL_GAMES_STATE().guessedPlys.blunders.size,
			},
		];
	};
	onKeyPress((key, event) => {
		switch (key) {
			case "ArrowLeft":
				quick((s) => s.repertoireState.modelGamesState.backOne());
				break;
			case "ArrowRight":
				event.preventDefault();
				quick((s) => s.repertoireState.modelGamesState.playNextMove());
				break;
			case "ArrowUp":
				break;
			case "ArrowDown":
				break;
		}
	});
	createEffect(() => {
		onBack(() => {
			quick((s) => {
				const lastMove = last(s.repertoireState.modelGamesState.chessboard.getMoveLog());
				if (lastMove) {
					if (isEndOfReview()) {
						animateSidebar("left");
					} else {
						if (showingHint()) {
							animateBottomSidebarSection()?.("left");
						}
					}
					s.repertoireState.modelGamesState.backOne();
				} else {
					animateSidebar("left");
					s.repertoireState.backToOverview();
				}
			});
		}, "stay");
	});
	const ecoCode = createMemo(() => REPERTOIRE_STATE().getLastEcoCode(game().epds));
	const nextMove = () => MODEL_GAMES_STATE().nextMove;
	const isStartOfReview = () => {
		return (
			MODEL_GAMES_STATE().ply === 0 ||
			(MODEL_GAMES_STATE().activeGame?.result === "black" && MODEL_GAMES_STATE().ply === 1)
		);
	};
	const isEndOfReview = () => {
		return MODEL_GAMES_STATE().ply === game().epds.length - 1 && !MODEL_GAMES_STATE().previewing;
	};
	// has to be null-able in case we go one past the end of the game with a refutation
	// there's probably a better way to do this
	const stockfish: Accessor<StockfishEval | undefined> = () => {
		return props.game.evals[ply()];
	};
	const ply = () => MODEL_GAMES_STATE().ply;
	const endOfOfReviewBullets = createMemo(() => {
		const bullets: JSXElement[] = [];
		if (!isEndOfReview()) {
			return bullets;
		}
		bullets.push(ChessGame.formatPlayerPerformanceDescription(props.game, "white"));
		bullets.push(ChessGame.formatPlayerPerformanceDescription(props.game, "black"));
		return bullets;
	});
	const arrowsUnderneathMountPoint = APP_STATE().repertoireState.modelGamesState.chessboard.get(
		(s) => s.refs.arrowsUnderneathContainerRef,
	);
	const [animateBottomSidebarSection, setAnimateBottomSidebarSection] =
		createSignal<AnimateSidebar | null>(null);
	return (
		<SidebarTemplate
			header={
				isEndOfReview()
					? ChessGame.formatGameResultTitle(game())
					: ChessGame.formatChessGameTitle(game(), useResponsiveV2()().isMobile)
			}
			truncateHeader={isMobile()}
			headerRight={
				<Show when={!isEndOfReview() && MODEL_GAMES_STATE().totalGuesses > 0}>
					<div class={clsx("row items-center space-x-4 lg:space-x-6 shrink-0 pl-4 opacity-100")}>
						<Index each={progressIcons()}>
							{(i) => {
								return (
									<div class="row items-center text-gray-10 ">
										<p class={clsx("text-base font-semibold lg:text-lg text-secondary")}>
											{i().text}
										</p>
										<i class={clsx(i().class, i().icon, " ml-2 text-base lg:text-lg")} />
									</div>
								);
							}}
						</Index>
					</div>
				</Show>
			}
			actions={[
				{
					text: "Continue",
					hidden: !isEndOfReview(),
					style: "primary",
					onPress: () => {
						quick((s) => {
							animateSidebar("left");
							s.repertoireState.backToOverview();
						});
					},
				},
				{
					text: "Replay this game",
					hidden: !isEndOfReview(),
					style: "primary",
					onPress: () => {
						quick((s) => {
							s.repertoireState.modelGamesState.reviewGame(game());
						});
					},
				},
				{
					text: "Play through another model game",
					hidden: !isEndOfReview(),
					style: "primary",
					onPress: () => {
						quick((s) => {
							s.repertoireState.modelGamesState.fetchAndReviewNewGame(
								s.repertoireState.modelGamesState.lastFetchOptions ?? {},
							);
						});
					},
				},
			]}
		>
			<Show when={nextMove()}>
				<Portal mount={arrowsUnderneathMountPoint ?? undefined}>
					<ChessboardArrowView
						hidden={MODEL_GAMES_STATE().hintStage !== "arrow"}
						focused={false}
						faded={false}
						color={c.orange[55]}
						flipped={MODEL_GAMES_STATE().chessboard.get((s) => s.flipped)}
						toSquare={nextMove()!.to as Square}
						fromSquare={nextMove()!.from as Square}
					/>
				</Portal>
			</Show>
			<div class="gap-4 lg:gap-8">
				<Show when={!isEndOfReview()}>
					<div class="w-full h-[80px] md:h-[100px] padding-sidebar">
						<div class="w-full h-full relative">
							<EvalsChart game={game()} currentPly={ply()} />
							<div class="absolute w-full h-[0.5px] bg-gray-50 translate-y-[-50%] top-1/2"></div>
							<div
								class="absolute  transition-all w-px bg-blue-50 inset-y-0 opacity-60"
								style={stylex(c.left(`${(ply() / game().sans.length) * 100}%`))}
							></div>
							<div
								class="absolute transition-all"
								style={stylex(c.left(`${(ply() / game().sans.length) * 100}%`), {
									transform: `translateX(-${50}%)`,
								})}
							>
								<div
									class={clsx(
										"border-solid border-gray-20 border rounded-sm overflow-hidden  transition-all",
										ply() === 0 ? "opacity-0" : "opacity-100",
									)}
								>
									<Show when={stockfish()}>
										<StockfishEvalPreview stockfish={stockfish()!} />
									</Show>
								</div>
							</div>
						</div>
					</div>
				</Show>
				<Show when={!isEndOfReview()}>
					<SideBySideActions
						actions={[
							{
								text: "Play next move",
								style: "focus",
								onPress: () => {
									quick((s) => {
										if (showingHint() || isStartOfReview()) {
											animateBottomSidebarSection()?.("right");
										}
										s.repertoireState.modelGamesState.playNextMove();
									});
								},
							},
							{
								text:
									MODEL_GAMES_STATE().hintStage === null ? "Give me a hint" : "Show me the move",
								disabled: MODEL_GAMES_STATE().hintStage === "arrow",
								style: "secondary",
								onPress: () => {
									quick((s) => {
										animateBottomSidebarSection()?.("right");
										s.repertoireState.modelGamesState.showNextHint();
									});
								},
							},
						]}
					/>
				</Show>
				<SidebarSlideContainer setAnimateSidebar={(fn) => setAnimateBottomSidebarSection(() => fn)}>
					<div class="gap-4 lg:gap-8">
						<Show when={isEndOfReview()}>
							<div class="w-full padding-sidebar">
								<CMText class={clsx("text-primay font-bold")}>Stats from this game:</CMText>
								<Spacer between={["body-text", "bullets"]} />
								<div class={"space-y-2"}>
									<For each={endOfOfReviewBullets()}>{(bullet) => <Bullet>{bullet}</Bullet>}</For>
									{total() > 0 && (
										<Bullet>
											You guessed{" "}
											<span class="text-highlight font-bold">
												{numCorrect()} of {total()}
											</span>{" "}
											moves correctly ({Math.round((numCorrect() / total()) * 100)}
											%)
										</Bullet>
									)}
									<div class="space-y-2 pl-6">
										<Show when={MODEL_GAMES_STATE().guessedPlys.inaccuracies.size > 0}>
											<Bullet>
												You played{" "}
												<span class="text-highlight font-bold">
													{MODEL_GAMES_STATE().guessedPlys.inaccuracies.size}
												</span>{" "}
												{plural(
													MODEL_GAMES_STATE().guessedPlys.inaccuracies.size,
													"inaccuracy",
													"inaccuracies",
												)}
											</Bullet>
										</Show>
										<Show when={MODEL_GAMES_STATE().guessedPlys.mistakes.size > 0}>
											<Bullet>
												You made{" "}
												<span class="text-highlight font-bold">
													{MODEL_GAMES_STATE().guessedPlys.mistakes.size}
												</span>{" "}
												{plural(MODEL_GAMES_STATE().guessedPlys.mistakes.size, "mistake")}
											</Bullet>
										</Show>
										<Show when={MODEL_GAMES_STATE().guessedPlys.blunders.size > 0}>
											<Bullet>
												You blundered{" "}
												<span class="text-highlight font-bold">
													{MODEL_GAMES_STATE().guessedPlys.blunders.size}
												</span>{" "}
												{plural(MODEL_GAMES_STATE().guessedPlys.blunders.size, "time")}
											</Bullet>
										</Show>
									</div>
								</div>
							</div>
						</Show>
						<Show when={annotation() && !isStartOfReview()}>
							<div>
								<p class="text-primary font-medium padding-sidebar pb-2">Understanding this move</p>
								<p class="padding-sidebar body-text">{renderAnnotation(annotation()!)}</p>
							</div>
						</Show>
						<Show when={isStartOfReview() && ecoCode()}>
							<div class="w-full padding-sidebar">
								<p class=" text-primary pb-4 font-medium">
									{ecoCode() && (
										<>
											This game follows your repertoire in the {ecoCode()!.fullName}.
											{game().adjusted &&
												" The move order in the opening has been adjusted slighty to match your repertoire."}
										</>
									)}{" "}
									Move the pieces on the board to guess the move at any point.
								</p>
								<p class="body-text">{ChessGame.formatGameDescription(game())}</p>
							</div>
						</Show>
						<Show when={showingHint() && !isEndOfReview()}>
							<div class="padding-sidebar">
								<HintsList hints={MODEL_GAMES_STATE().hints!} />
							</div>
						</Show>
						<SidebarActions
							actions={[
								{
									hidden: !isStartOfReview(),
									text: "View previous model games",
									style: "secondary",
									onPress: () => {
										quick((_s) => {
											UI().popView();
											UI().pushView(ModelGameHistory);
										});
									},
								},
								...(isDevelopment
									? [
											{
												hidden: !isStartOfReview(),
												text: "Skip to end",
												style: "secondary",
												onPress: () => {
													MODEL_GAMES_STATE().skipToEnd();
												},
											} as SidebarAction,
										]
									: []),
							]}
						/>
					</div>
				</SidebarSlideContainer>
			</div>
		</SidebarTemplate>
	);
};

registerViewMode(ModelGameViewer, "model_games");

// Important: missing any of these will cause prod to crash but not local
Chart.register(
	LineController,
	LinearScale,
	PointElement,
	LineElement,
	// Tooltip,
	Filler,
	// ChartDataLabels,
	CategoryScale,
	// BarElement,
);

const EvalsChart = (props: { game: ChessGame; currentPly: number }) => {
	const [getChart, setChart] = createSignal<Chart<"line"> | null>(null);
	onMount(() => {
		// const data = [
		// 	{ year: 2010, count: 10 },
		// 	{ year: 2011, count: 20 },
		// 	{ year: 2012, count: 15 },
		// 	{ year: 2013, count: 25 },
		// 	{ year: 2014, count: 22 },
		// 	{ year: 2015, count: 30 },
		// 	{ year: 2016, count: 28 },
		// ];

		const chart = new Chart(document.getElementById("eval-chart")! as any, {
			type: "line",
			options: {
				responsive: true,
				maintainAspectRatio: false,

				scales: {
					y: {
						min: -0.5,
						max: 0.5,
						display: false,
					},
					x: {
						display: false,
					},
				},
			},
			data: {
				datasets: [],
			},
		});
		setChart(chart);
	});

	createEffect(() => {
		const chart = getChart();

		if (chart) {
			const data = zip(props.game.epds, props.game.evals).map(([_move, stockfish_eval], i) => {
				return {
					ply: i,
					// san: move,
					stockfish_eval: stockfish_eval!.getPovWinPercentage("white") - 0.5,
				};
			});
			chart.data.labels = data.map((row) => row.ply);
			chart.data.datasets = filter(
				chart.data.datasets,
				(dataset) => dataset.label === "Evaluation",
			);
			chart.data.datasets = [
				{
					label: "Evaluation",
					data: data.map((row) => row.stockfish_eval),
					fill: {
						target: "origin",
						below: c.gray[30],
						above: c.gray[80],
					},
					borderWidth: 0,
					// borderColor: c.green[30],
					// borderWidth: 0.8,
					order: 1,
					tension: 0.0,
					animation: false,
					pointRadius: 0,
					pointStyle: "line",
				},
				// ...lines,
			];
			chart.update();
		}
	});

	return (
		<div class="w-full h-full">
			<canvas id="eval-chart" class="w-full h-full"></canvas>
		</div>
	);
};

const HintsList = (props: { hints: MoveHint[] }) => {
	const firstHint = () => props.hints[0];
	return (
		<div class="w-full h-full">
			<p>
				<span class="font-bold">Hint: </span> {firstHint()?.description}
			</p>
		</div>
	);
};

const SideBySideActions = (props: { actions: SidebarAction[] }) => {
	return (
		<div class="padding-sidebar flex flex-row w-full justify-between gap-3 lg:gap-[18px]">
			<For each={props.actions}>
				{(action) => (
					<div
						class={clsx(
							"cursor-pointer grow center h-[42px]  basis-0 &hover:bg-sidebar_button_primary_hover bg-sidebar_button_primary transition-all font-medium select-none",
							action.disabled && "opacity-50 pointer-events-none",
						)}
						use:onClick={() => action.onPress!()}
					>
						{action.text}
					</div>
				)}
			</For>
		</div>
	);
};
