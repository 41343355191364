import { createEffect, createSignal, onCleanup } from "solid-js";
import { UI, quick } from "~/utils/app_state";
import { c, stylex } from "~/utils/styles";
import { SidebarTemplate } from "./SidebarTemplate";
import { TextInput } from "./TextInput";

import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-yup";
import { Chess } from "@lubert/chess.ts";
import toast from "solid-toast";
import * as yup from "yup";
import { START_EPD } from "~/utils/chess";
import { rspcClient } from "~/utils/rspc_client";
import { Spacer } from "./Space";

type Form = {
	epd: string;
};

export const AddCard = () => {
	const onSubmit = (values: Form) => {
		setServerError("");
		rspcClient
			.query(["cards.addCard", { epd: values.epd }])
			.then((_d) => {
				quick((s) => {
					s.cardsState.processingState.processingCards++;
				});
				UI().backOne();
				toast.success("Processing card!");
			})
			.catch((err) => {
				setServerError(err?.response?.data?.error ?? "Something went wrong");
			});
	};
	onCleanup(() => {
		quick((s) => {
			s.cardsState.chessboardOverview.setPosition(new Chess());
		});
	});
	const { form, setFields, errors, createSubmitHandler, data } = createForm<Form>({
		initialValues: { epd: "" },
		onSubmit: onSubmit,
		extend: [
			validator({
				schema: yup.object({
					epd: yup
						.string()
						.label("EPD")
						.required()
						.test("is-valid-epd", "Invalid EPD", (value) => {
							// todo check with chess ts whether this epd/fen is valid
							try {
								new Chess(value);
							} catch (_e) {
								return false;
							}

							return true;
						}),
				}),
			}),
		],
	});
	const handleSubmit = createSubmitHandler({
		onSubmit,
	});

	const [_serverError, setServerError] = createSignal("");
	createEffect(() => {
		let epd = data("epd");
		try {
			let chess = new Chess(epd);
			quick((s) => {
				s.cardsState.chessboardOverview.setPosition(chess);
				s.cardsState.chessboardOverview.setPerspective(chess.turn() === "w" ? "white" : "black");
			});
		} catch (_e) {
			setServerError("Invalid EPD");
		}
	});
	return (
		<>
			<SidebarTemplate
				actions={[
					{
						onPress: () => {
							handleSubmit();
						},
						text: "Create a card with this position",
						style: "focus",
					},
				]}
				header={"Create your own card"}
			>
				<div class="col items-center">
					<p class="body-text padding-sidebar">
						Just enter a FEN, and we'll run the position through Stockfish, generating a card which
						you can review.
					</p>
					<Spacer between={["body-text", "form"]} />
					<div class={" w-full self-stretch"}>
						<div style={stylex(c.br(4), c.px(0), c.py(0))}>
							<form ref={form} class={"col gap-8"}>
								<div class="padding-sidebar">
									<TextInput
										placeholder={START_EPD}
										setFields={setFields}
										type="text"
										name="epd"
										label="Position in FEN notation"
										errors={errors()}
									/>
								</div>
								<input type="submit" hidden />
							</form>
						</div>
					</div>
				</div>
			</SidebarTemplate>
		</>
	);
};
