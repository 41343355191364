import { createSignal } from "solid-js";
import { BROWSING_STATE, UI, quick } from "~/utils/app_state";
import client from "~/utils/client";
import { c, stylex } from "~/utils/styles";
import { SidebarTemplate } from "./SidebarTemplate";
import { TextInput } from "./TextInput";
import { InputError } from "./forms/InputError";

import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-yup";
import { find } from "lodash-es";
import * as yup from "yup";
import type { FetchRepertoireResponse } from "~/utils/repertoire_state";

type Form = {
	name: string;
};

export const RenameRepertoire = () => {
	const repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	const onSubmit = (values: Form) => {
		setServerError("");
		client
			.post("/api/v1/openings/rename", {
				name: values.name,
				repertoireId: repertoire().id,
			})
			.then(({ data }: { data: FetchRepertoireResponse }) => {
				quick((s) => {
					s.repertoireState.repertoireFetched(data);
					UI().clearViews();
					const newRepertoire = find(data.repertoires, (r) => r.name === values.name);
					if (!newRepertoire) {
						console.error("New repertoire not found!");
						return;
					}
					UI().backOne();
				});
			})
			.catch((err) => {
				setServerError(err?.response?.data?.error ?? "Something went wrong");
			});
	};
	const { form, setFields, errors, createSubmitHandler } = createForm<Form>({
		initialValues: { name: repertoire().name },
		onSubmit: onSubmit,
		extend: [
			validator({
				schema: yup.object({
					name: yup.string().label("Name").max(255).min(4).required(),
				}),
			}),
		],
	});
	const handleSubmit = createSubmitHandler({
		onSubmit,
	});

	const [serverError, setServerError] = createSignal("");
	return (
		<>
			<SidebarTemplate
				actions={[
					{
						onPress: () => {
							handleSubmit();
						},
						text: "Rename",
						style: "focus",
					},
				]}
				header={"Rename this repertoire?"}
			>
				<div class="col items-center">
					<div class={" w-full self-stretch"}>
						<div style={stylex(c.br(4), c.px(0), c.py(0))}>
							<form ref={form} class={"col gap-8"}>
								<div class="padding-sidebar">
									<TextInput
										placeholder={repertoire().name}
										setFields={setFields}
										type="text"
										name="name"
										label="New name"
										errors={errors()}
									/>
								</div>
								<InputError name={"Server error"} error={serverError()} class={"inline-block"} />
								<input type="submit" hidden />
							</form>
						</div>
					</div>
				</div>
			</SidebarTemplate>
		</>
	);
};
