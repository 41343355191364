import { some } from "lodash-es";

export function renderAnnotation(_annotation: string) {
	const annotation = _annotation?.trim();
	const stops = ["!", "?", "."];
	if (annotation) {
		if (some(stops, (stop) => annotation.endsWith(stop))) {
			return annotation;
		}
		return `${annotation}.`;
	}
}
