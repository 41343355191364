import { BROWSING_STATE, UI, quick } from "~/utils/app_state";
import { trackEvent } from "~/utils/trackEvent";
import { CMText } from "./CMText";
import { SidebarTemplate } from "./SidebarTemplate";

export const ConfirmDeleteRepertoire = function DeleteLineView() {
	const repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	return (
		<SidebarTemplate
			bodyPadding={true}
			header="Are you sure?"
			actions={[
				{
					text: `Yes, delete my ${repertoire().name} repertoire`,
					style: "primary",
					onPress: () => {
						quick((s) => {
							s.repertoireState.deleteRepertoire(repertoire().id!);
							UI().backOne();
							trackEvent("repertoire.deleted");
						});
					},
				},
			]}
		>
			<CMText>
				This will permanently delete your "{repertoire().name}" repertoire. This cannot be undone.
			</CMText>
		</SidebarTemplate>
	);
};
