import { forEach, isEmpty, map, some } from "lodash-es";
import type { CourseMoveDTO } from "~/rspc";
import type { Repertoire } from "./Repertoire";

export type CourseRepertoire = Record<string, CourseMoveDTO[]>;

export namespace Course {
	export function countMovesFrom(
		courseRepertoire: CourseRepertoire,
		epd: string,
		toRepertoire?: Repertoire | undefined,
	): { totalMoves: number; existingMoves: number; newMoves: number } {
		const seenMoves = new Set<string>();
		// const seenMyMoves = new Set<string>();
		const seenEpds = new Set<string>();
		let existingMoves = 0;
		const recurse = (epd: string) => {
			let responses: CourseMoveDTO[] = [...(courseRepertoire[epd] ?? [])];
			let isMine = some(responses, (r) => r.mine);
			if (isMine) {
				forEach(responses, (r) => {
					seenEpds.add(r.epd);
					seenMoves.add(r.id);
				});
			}
			if (!isEmpty(responses) && isMine) {
				let myResponses = toRepertoire?.positionResponses[epd] ?? [];
				if (!isEmpty(myResponses) && some(myResponses, (m) => !m.isDisabled)) {
					existingMoves++;
				}
			}

			map(responses, (m) => {
				if (!seenEpds.has(m.epdAfter)) {
					recurse(m.epdAfter);
				}
			});
		};
		recurse(epd);

		return {
			totalMoves: seenMoves.size,
			existingMoves: existingMoves,
			newMoves: seenMoves.size - existingMoves,
		};
	}
}
