export const Logo = (props: any) => (
	<svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" viewBox="0 0 200 164.87" {...props}>
		<defs>
			<style>
				{
					".cls-1,.cls-2,.cls-3,.cls-4{fill:#fff}.cls-2{opacity:.5}.cls-3{opacity:.75}.cls-4{opacity:.25}"
				}
			</style>
		</defs>
		<g id="black">
			<path d="m150 142.48-50 22.39v-60l50-22.39v60z" class="cls-4" />
			<path
				d="m200 119.97-50 22.39v-60l50-22.39v60zM150 82.55l-50 22.38v-60l50-22.38v60z"
				class="cls-3"
			/>
			<path d="m200 60.03-50 22.39v-60L200 .03v60z" class="cls-4" />
			<path d="m50 142.45 50 22.39v-60L50 82.45v60z" class="cls-1" />
			<path
				d="m0 119.94 50 22.38v-60L0 59.94v60zM50 82.52l50 22.38v-60L50 22.52v60z"
				class="cls-2"
			/>
			<path d="m0 60 50 22.39v-60L0 0v60z" class="cls-1" />
		</g>
	</svg>
);
