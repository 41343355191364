import { dropRight, last } from "lodash-es";
import type { JSXElement } from "solid-js";
import type { ClientChessGame } from "~/rspc";
import { REPERTOIRE_STATE } from "~/utils/app_state";
import { intersperse } from "~/utils/intersperse";
import { pluralize } from "~/utils/pluralize";
import { EcoCode } from "./EcoCode";
import type { BySide, Side } from "./Side";
import { StockfishEval } from "./StockfishEval";
import type { Uuid } from "./Uuid";

export interface ChessGame {
	id: Uuid;
	epds: string[];
	sans: string[];
	evals: StockfishEval[];
	players: BySide<ChessGamePlayer>;
	performances: BySide<ChessGamePerformance>;
	result: Side | null;
	year: number | null;
	playedAt: string | null;
	eventName: string | null;
	// aiDescription: string | null;
	// source: "Lichess" | "Twic" | "Historical" | null;
	reviewed: boolean;
	createdAt: string;
	adjusted: boolean;
	source: "Lichess" | "Twic" | "Historical" | null;
	link: string | null;
}

export interface ChessGamePerformance {
	inaccuracies: number;
	mistakes: number;
	blunders: number;
}

export interface ChessGamePlayer {
	name: string;
	lastName: string;
	title: string | null;
	elo: number | null;
}

export namespace ChessGame {
	export const fromDto = (dto: ClientChessGame): ChessGame => {
		const game = {
			...(dto as Omit<ChessGame, "evals">),
			evals: dto?.evals?.map((e) => new StockfishEval(e)) ?? [],
		};
		return game;
	};

	export const formatChessGameTitle = (game: ChessGame, short?: boolean) => {
		let title = `${game.players.white.lastName} vs. ${game.players.black.lastName}`;
		if (game.year && !short && game.source !== "Lichess") {
			title += `, ${game.year}`;
		}
		return title;
	};

	export const getEcoCodeName = (game: ChessGame): string | null => {
		const ecoCode = REPERTOIRE_STATE().getLastEcoCode(game.epds.slice(0, 20));
		if (ecoCode) {
			return EcoCode.getNameEcoCodeIdentifier(ecoCode.fullName);
		}
		return null;
	};

	export const formatPlayerPerformanceDescription = (game: ChessGame, side: Side): JSXElement => {
		const player = game.players[side];
		const { inaccuracies, mistakes, blunders } = game.performances[side];
		const elem: JSXElement[] = [`${player.lastName}`];
		const performanceStrings: string[] = [];
		if (inaccuracies) {
			performanceStrings.push(`${pluralize(inaccuracies, "inaccuracy", "inaccuracies")}`);
		}
		if (mistakes) {
			performanceStrings.push(`${pluralize(mistakes, "mistake")}`);
		}
		if (blunders) {
			performanceStrings.push(`${pluralize(blunders, "blunder")}`);
		}
		const performanceElems = performanceStrings.map((s) => (
			<span class="text-highlight font-bold">{s}</span>
		));
		if (performanceElems.length > 0) {
			if (performanceElems.length < 2) {
				elem.push(
					<>
						: <span class="text-highlight font-bold">{performanceElems[0]}</span>
					</>,
				);
			} else {
				elem.push(
					<>
						: {intersperse(dropRight(performanceElems, 1), () => ", ")} and {last(performanceElems)}
					</>,
				);
			}
		} else {
			elem.push(" played a perfect game, with no inaccuracies, mistakes, or blunders");
		}
		return elem;
	};

	export const formatGameResultTitle = (game: ChessGame) => {
		const winner = game.players[game.result!];
		const loser = game.players[game.result === "white" ? "black" : "white"];
		if (last(game.sans)?.includes("#")) {
			return `${winner.lastName} won by checkmate`;
		}
		return `In this position, ${loser.lastName} resigned`;
	};

	export const formatGameDescription = (game: ChessGame) => {
		let description = "";
		// In this game, played between Carlsen and Caruana in 1973, at the "Candidates 2023" event, Carslen defeated Caruana in 72 moves.
		// if (game.source === "Lichess") {
		// 	description += "In this online game played on Lichess ";
		// } else {
		if (game.source === "Lichess") {
			description += "In this online game played on Lichess ";
		} else {
			description += "In this game ";
		}
		// }
		const formatPlayerTitle = (player: ChessGamePlayer) => {
			if (!player.elo) {
				return player.lastName;
			}
			return `${player.title ? `${player.title} ` : ""}${player.lastName} (${player.elo})`;
		};
		description += `between ${formatPlayerTitle(game.players.white)} and ${formatPlayerTitle(
			game.players.black,
		)}`;
		if (game.year && game.source !== "Lichess") {
			description += ` in ${game.year}`;
		}

		if (game.eventName && game.source !== "Lichess") {
			description += ` at the "${game.eventName}"`;
		}
		description += ", ";

		const winner = game.players[game.result!];
		const loser = game.players[game.result === "white" ? "black" : "white"];
		description += `${winner.lastName} defeated ${loser.lastName} in ${Math.round(
			game.sans.length / 2,
		)} moves.`;
		return description;
	};
}
