import { Browser } from "@capacitor/browser";
import type { JSXElement } from "solid-js";
import { memoChildren } from "~/utils/children";
import { isNative, isWeb } from "~/utils/env";

export const InAppBrowserLink = (props: {
	href: string;
	newTab?: boolean;
	children: JSXElement;
	style?: any;
	class?: string;
	onClick?: () => void;
}) => {
	const newTab = () => props.newTab ?? true;
	const children = memoChildren(() => props.children);
	if (isWeb) {
		return (
			<a
				href={props.href}
				target={newTab() ? "_blank" : "_self"}
				use:onClick={props.onClick}
				rel="noreferrer"
				style={props.style}
				class={props.class}
			>
				{children()}
			</a>
		);
	}
	if (isNative) {
		return (
			<div
				use:onClick={async () => {
					props.onClick?.();
					await Browser.open({ url: props.href });
				}}
				style={props.style}
				class={props.class}
			>
				{children()}
			</div>
		);
	}
};
