import { dropRight } from "lodash-es";
import { createSignal, onCleanup, useContext } from "solid-js";
import { SidebarStackContext } from "~/components/SidebarStack";

export type BackBehavior = "animate" | "stay" | "hide";

export type BackEntry = {
	fn: () => void;
	behavior: BackBehavior;
};

export const [backStack, setBackStack] = createSignal<BackEntry[]>([]);

export const onBack = (fn: () => void, behavior?: BackBehavior) => {
	const stackIndex = useContext(SidebarStackContext)!;
	if (stackIndex === undefined) {
		console.warn("onBack called outside of SidebarStack");
		return;
	}

	setBackStack((backStack) => {
		let newStack = [...backStack];
		const currentLen = backStack.length;
		if (currentLen < stackIndex + 1) {
			newStack = newStack.concat(new Array(stackIndex + 1 - currentLen).fill(null));
		}
		newStack[stackIndex] = { fn, behavior: behavior ?? "animate" };
		return newStack;
	});
	onCleanup(() => {
		setBackStack(dropRight(backStack(), 1));
	});
};

export const BACK_STACK = backStack;
