export class MultiCallback<T extends (...args: any[]) => any> {
	private callbacks: T[] = [];

	add(callback: T) {
		this.callbacks.push(callback);
	}

	callAndClear(...args: Parameters<T>) {
		this.call(...args);
		this.clear();
	}

	call(...args: Parameters<T>) {
		this.callbacks.forEach((cb) => cb(...(args ?? [])));
	}

	clear() {
		this.callbacks = [];
	}

	remove(callback: T) {
		this.callbacks = this.callbacks.filter((cb) => cb !== callback);
	}

	hasListeners() {
		return this.callbacks.length > 0;
	}
}
