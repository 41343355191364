import { onMount } from "solid-js";
import { THRESHOLD_OPTIONS } from "~/threshold_options";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, USER_STATE } from "~/utils/app_state";
import { renderThreshold } from "~/utils/threshold";
import { trackEvent } from "~/utils/trackEvent";
import { floatEqual } from "~/utils/utils";
import { SidebarTemplate } from "./SidebarTemplate";

export const IncreaseCoverageGoal = (props: { source: "bar" | "check_in" | "review" }) => {
	const thresholdSource = () => {
		if (BROWSING_STATE().getActiveRepertoire()?.coverageTarget) {
			return "repertoire";
		}
		return "user";
	};
	const nextThreshold = () => {
		let currentThreshold = REPERTOIRE_STATE().getRepertoireThreshold(
			BROWSING_STATE().activeRepertoireId ?? null,
		);
		let currentThresholdIndex = THRESHOLD_OPTIONS.findIndex((t) =>
			floatEqual(t.value, currentThreshold),
		);
		let nextThresholdIndex = currentThresholdIndex + 1;
		return THRESHOLD_OPTIONS[nextThresholdIndex].value;
	};
	onMount(() => {
		trackEvent("increase_coverage_goal.shown", { source: props.source });
	});
	return (
		<SidebarTemplate
			header="Increase your coverage goal?"
			bodyPadding={true}
			actions={[
				{
					text: `Increase to ${renderThreshold(nextThreshold())} games`,
					onPress: () => {
						trackEvent("increase_coverage_goal.increase", { source: props.source });
						let task =
							thresholdSource() === "user"
								? () => {
										return USER_STATE().setTargetDepth(nextThreshold());
									}
								: () => {
										return REPERTOIRE_STATE().setRepertoireThreshold(
											BROWSING_STATE().activeRepertoireId!,
											nextThreshold(),
										);
									};
						UI()
							.loadUntil(task())
							.then(() => {
								UI().backOne();
							});
					},
					style: "primary",
				},
			]}
		>
			<p class="body-text">
				Your current coverage goal is{" "}
				<b>
					{renderThreshold(
						REPERTOIRE_STATE().getRepertoireThreshold(BROWSING_STATE().activeRepertoireId ?? null),
					)}{" "}
					games
				</b>
				. You can increase this to have an even better chance of out-prepping your opponents.
			</p>
		</SidebarTemplate>
	);
};
