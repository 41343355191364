import { isNil } from "lodash-es";
import { createSignal } from "solid-js";
import { BROWSING_STATE, UI, quick } from "~/utils/app_state";
import { stylex } from "~/utils/styles";
import { CMText } from "./CMText";
import type { SidebarAction } from "./SidebarActions";
import { SidebarTemplate } from "./SidebarTemplate";

export const DeleteLineView = function DeleteLineView() {
	const move = () => BROWSING_STATE().deleteLineState.move!;
	const [deleting, setDeleting] = createSignal(false);
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	if (isNil(move())) {
		return null;
	}

	return (
		<SidebarTemplate
			bodyPadding={true}
			header={"Are you sure?"}
			loading={deleting()}
			actions={[
				{
					onPress: () => {
						if (deleting()) {
							return;
						}
						setDeleting(true);
						quick((s) => {
							s.repertoireState.deleteMove(move(), activeRepertoireId()).then(() => {
								quick((_s) => {
									UI().backOne();
									// todo: update check in screen for deleted move
									// UI().cutView();
									// UI().pushView(RepertoireCheckInView, {
									// 	props: {
									// 		deletedMove: move(),
									// 		loading: () => false,
									// 		justCompleted: () => false,
									// 	},
									// });
								});
							});
						});
					},
					style: "primary" as SidebarAction["style"],
					text: `Yes I'm sure, delete ${move().sanPlus}`,
				},
				{
					onPress: () => {
						quick((_s) => {
							UI().backOne();
						});
					},
					style: "primary",
					text: `No, I've changed my mind`,
				},
			]}
		>
			<div>
				<CMText style={stylex()}>
					{"This will also delete any moves past this one. This cannot be undone."}
				</CMText>
			</div>
		</SidebarTemplate>
	);
};
