import type { AxiosResponse } from "axios";
import type { OpeningsReport } from "~/types/OpeningsReport";
import { type AppState, OPENINGS_REPORT_STATE, USER_STATE, quick } from "./app_state";
import { createChessboardInterface } from "./chessboard_interface";
import client from "./client";
import type { OpeningsReportTimeRange } from "./frontend_settings";
import type { RepertoireState } from "./repertoire_state";

export type OpeningsReportState = ReturnType<typeof getInitialOpeningsReportState>;

type Stack = [OpeningsReportState, RepertoireState, AppState];

export const getInitialOpeningsReportState = () => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string) => {
		return quick((s) => {
			return fn([s.repertoireState.openingReportsState, s.repertoireState, s]);
		});
	};
	const initialState = {
		chessboard: createChessboardInterface()[1],
		openingsReportsByTimeRange: {} as Record<string, OpeningsReport[] | null>,
		getCurrentOpeningsReport: (): OpeningsReport[] | null => {
			return (
				OPENINGS_REPORT_STATE().openingsReportsByTimeRange[
					USER_STATE().getFrontendSetting("openingsTimeRange").value as OpeningsReportTimeRange
				] ?? null
			);
		},
		fetchOpeningsReport: () =>
			set(([_s, _rs, gs]) => {
				const timeRange = gs.userState.getFrontendSetting("openingsTimeRange")
					.value as OpeningsReportTimeRange;
				client
					.post(`/api/v2/openings_report`, {
						timeRange,
					})
					.then((resp: AxiosResponse<OpeningsReport[]>) => {
						set(([s]) => {
							s.openingsReportsByTimeRange[timeRange] = resp.data;
						});
					});
			}),
	};
	return initialState;
};
