import { type Component, type JSXElement, Show, mergeProps } from "solid-js";
import { Puff } from "solid-spinner";
import { Spacer } from "~/components/Space";
import { memoChildren } from "~/utils/children";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { CMText } from "./CMText";
import { SidebarHeader } from "./RepertoireEditingHeader";
import { type SidebarAction, SidebarActions } from "./SidebarActions";

export const SidebarTemplate: Component<{
	header: string | null;
	truncateHeader?: boolean;
	headerRight?: JSXElement;
	children?: any;
	loading?: string | boolean | null;
	loadingOverlay?: boolean;
	bodyPadding?: boolean;
	actionsPadding?: boolean;
	actions: SidebarAction[];
	class?: string;
}> = (props) => {
	props = mergeProps(
		{
			actionsPadding: true,
		},
		props,
	);
	const responsive = useResponsiveV2();
	const children = memoChildren(() => props.children);
	return (
		<div style={stylex(c.column)}>
			<Show when={props.header}>
				<div class={"padding-sidebar"}>
					<SidebarHeader right={props.headerRight} truncate={props.truncateHeader}>
						{props.header}
					</SidebarHeader>
				</div>

				<div class={"h-4 lg:h-6"} />
			</Show>
			<Show
				when={!props.loading}
				fallback={
					<div style={stylex(c.selfCenter, c.pt(48), c.center)}>
						<CMText style={stylex(c.fontSize(14), c.weightSemiBold, c.fg(c.gray[75]))}>
							<Puff color={c.primaries[65]} />
							{/*
							<Show when={typeof props.loading === "string"}>
								<p class="text-tertiary pt-4">{props.loading}</p>
							</Show>
              */}
						</CMText>
					</div>
				}
			>
				<div
					style={stylex(
						c.column,
						props.bodyPadding && c.px(c.getSidebarPadding(responsive())),
						c.zIndex(2),
						c.relative,
					)}
					class={props.class}
				>
					<div
						class={clsx(
							`absolute top-0 left-0 right-0 bottom-0 z-10 bg-gray-14 transition-all`,
							props.loadingOverlay ? "opacity-80" : "opacity-0 pointer-events-none",
						)}
						style={stylex(c.zIndex(10))}
					>
						<div class="absolute inset-0 center">
							<Puff color={c.primaries[65]} />
						</div>
					</div>
					{children()}
				</div>
				<Spacer
					height={children() && props.actionsPadding ? (responsive().isMobile ? 24 : 36) : 0}
				/>
				<SidebarActions actions={props.actions} />
			</Show>
		</div>
	);
};
