import type { ChessCardDTO } from "~/rspc";
import { MoveRating } from "./MoveRating";

export namespace ChessCard {
	export const getMoveRating = (card: ChessCardDTO, san: string): MoveRating => {
		let ratings = [MoveRating.Blunder, MoveRating.Inaccuracy, MoveRating.Mistake];
		for (let moveRating of ratings) {
			if (card.sansByMoveRating[moveRating]?.includes(san)) {
				return moveRating;
			}
		}
		return MoveRating.Good;
	};
}
