import type { JSXElement } from "solid-js";
import type { SidebarAction } from "./SidebarActions";
import { SidebarTemplate } from "./SidebarTemplate";

export const TemplateSidebarView = (props: {
	text?: string | JSXElement;
	header: string;
	actions: SidebarAction[];
}) => {
	return (
		<SidebarTemplate
			actions={props.actions}
			bodyPadding={true}
			header={props.header}
			loading={false}
		>
			{props.text && <p class="body-text">{props.text}</p>}
		</SidebarTemplate>
	);
};
