import confetti from "canvas-confetti";
import { createSignal } from "solid-js";
import { isMobile } from "~/utils/isMobile";

const [confettiInstance, setConfettiInstance] = createSignal<typeof confetti>();

export const showerSuccessConfetti = () => {
	const colors = ["#ff4f6a", "#f68820", "#54cbcb", "#64b550", "#ffb316"];

	const duration = Date.now() + 500;
	const confetti = confettiInstance();
	const screenWidth = window.innerWidth;
	if (!confetti) {
		return;
	}
	const startVelocity = Math.min(screenWidth / 15, 50);

	let angle = -40;
	if (isMobile()) {
		angle = -70;
	}
	(function f(): void {
		confetti({
			particleCount: 5,
			angle: angle,
			spread: 75,
			startVelocity,
			origin: { x: 0, y: 0 },
			colors,
		});
		confetti({
			particleCount: 5,
			angle: 180 - angle,
			spread: 75,
			startVelocity,
			origin: { x: 1, y: 0 },
			colors,
		});

		if (Date.now() < duration) {
			requestAnimationFrame(f);
		}
	})();
};

export const Confetti = () => {
	return (
		<>
			<canvas
				id="confetti"
				ref={(canvas) => {
					setConfettiInstance(
						() =>
							confetti.create(canvas, {
								resize: true,
								useWorker: true,
							}) as typeof confetti,
					);
				}}
				class="z-50 fixed inset-0 pointer-events-none w-full h-full"
			/>
		</>
	);
};
