import { destructure } from "@solid-primitives/destructure";
import { isNil } from "lodash-es";
import { type JSX, Show, createEffect } from "solid-js";
import { BROWSING_STATE, REPERTOIRE_STATE } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { memoChildren } from "~/utils/children";
import { clsx } from "~/utils/classes";
import { AnimatedBarPiece, type LabeledBarIdentifier, genLabeledBarKey } from "./AnimatedBarPiece";
import { initTooltip } from "./Tooltip";

export const RepertoireCharacteristics = (props: {
	repertoireId: string;
	exclude?: LabeledBarIdentifier[];
	class?: string;
}) => {
	const repertoire = () => REPERTOIRE_STATE().repertoires?.[props.repertoireId];
	const grade = () => REPERTOIRE_STATE().repertoireGrades[props.repertoireId];
	const numMoves = () => REPERTOIRE_STATE().numMyMoves?.[props.repertoireId] ?? 0;
	const empty = () => numMoves() === 0;
	createEffect(() => {});
	const genBarKey = (name: LabeledBarIdentifier) => genLabeledBarKey(name, props.repertoireId);
	const _progressState = () => BROWSING_STATE().repertoireProgressState[props.repertoireId];
	const _mastery = () => REPERTOIRE_STATE().masteryFromEpd[props.repertoireId][START_EPD];
	const _separator = () => <div class="h-full w-px bg-gray-20 self-center" />;
	// const [animateEffectiveness, setAnimateEffectiveness] = createSignal(0.5);
	// let interval = setInterval(() => {
	// 	setAnimateEffectiveness(
	// 		clamp((animateEffectiveness() ?? 0) + Math.random() * 0.3 - 0.15, 0, 1),
	// 	);
	// }, 2000);
	// onCleanup(() => {
	// 	clearInterval(interval);
	// });
	const BarContainer = (props: { children: JSX.Element; class?: string }) => {
		const children = memoChildren(() => props.children);
		return (
			<div
				class={clsx(
					"flex basis-0 grow flex-row h-1.5 w-full  relative bg-gray-22 rounded-full",
					props.class,
				)}
			>
				{children()}
			</div>
		);
	};
	const Section = (props: { children: JSX.Element; header: string; tip?: string }) => {
		const children = memoChildren(() => props.children);
		return (
			<div
				class={clsx("flex basis-0 flex-row gap-2 items-center")}
				ref={(ref) => {
					initTooltip({
						ref,
						content: () => {
							return <p class="">{props.tip}</p>;
						},
						maxWidth: 240,
					});
				}}
			>
				<p class="text-secondary text-xs min-w-[90px]">{props.header}</p>
				<div class={clsx("flex flex-row gap-2 items-center grow-1")}>{children()}</div>
			</div>
		);
	};
	const [_myColor, theirColor] = destructure(() => {
		let colors = ["gray-100", "gray-0"];
		if (repertoire()?.side === "white") {
			return colors;
		}
		colors.reverse();
		return colors;
	});
	const _stockfishProgressValue = () => {
		let cp = grade().characteristics?.eval;
		if (isNil(cp)) {
			return 0;
		}
		if (repertoire()?.side === "black") {
			cp *= -1;
		}
		cp = 0.5 + cp / 2;
		console.debug(`For eval of ${grade().characteristics?.eval}, cp is ${cp}`);
		return Math.max(0, Math.min(1, cp));
	};
	const textClasses = `text-xs font-semibold`;
	const barClasses = `bg-gray-60 rounded-full`;
	return (
		<div class={clsx("gap-8 w-full", props.class)}>
			<Show when={!empty()}>
				<Show when={grade().characteristics}>
					<Show when={!props.exclude?.includes("effectiveness")}>
						<Section
							header="Effectiveness"
							tip={`Your repertoire has a higher expected winrate  than ${(grade().characteristics!.effectiveness * 100).toFixed(0)}% of players at your level.`}
						>
							<BarContainer>
								<AnimatedBarPiece
									showFloatingValue
									value={grade().characteristics!.effectiveness}
									hideValue
									classBar={barClasses}
									classText={clsx(`text-${theirColor()}`, textClasses)}
									key={genBarKey("wins")}
								/>
							</BarContainer>
						</Section>
					</Show>
					<Show when={!props.exclude?.includes("soundness")}>
						<Section
							header="Soundness"
							tip={`Your repertoire has a higher expected Stockfish evaluation than ${(grade().characteristics!.soundness * 100).toFixed(0)}% of players at your level.`}
						>
							<BarContainer>
								<AnimatedBarPiece
									showFloatingValue
									// decimalPlaces={2}
									value={grade().characteristics!.soundness}
									hideValue
									classBar={barClasses}
									// formatValue={(x) => {
									// 	// if (x.toFixed(2) === "0.00") {
									// 	// 	return "=";
									// 	// }
									// 	if (x > 0 && repertoire()?.side === "black") {
									// 		return `+${x.toFixed(2)}`;
									// 	}
									// 	if (x.toFixed(2) === "-0.00") {
									// 		return "0.0";
									// 	}
									// 	// to get rid of trailing zeros
									// 	return `${Number.parseFloat(x.toFixed(2))}`;
									// }}
									// classText={clsx(`text-${theirColor()}`, textClasses)}
									key={genBarKey("eval")}
								/>
							</BarContainer>
						</Section>
					</Show>
					<Show when={!props.exclude?.includes("sharpness")}>
						<Section header="Sharpness">
							<BarContainer class={clsx("bg-gray-22")}>
								<AnimatedBarPiece
									showFloatingValue
									value={grade().characteristics!.sharpness}
									hideValue
									key={genBarKey("sharpness")}
									classBar={barClasses}
									rounded
									classText={clsx(textClasses)}
								/>
							</BarContainer>
						</Section>
					</Show>
					<Show
						when={!props.exclude?.includes("learnability") && grade().characteristics!.learnability}
					>
						<Section
							header="Learnability"
							tip={`Your repertoire is expected to be easier to learn than ${(grade().characteristics!.learnability! * 100).toFixed(0)}% of players at your level.`}
						>
							<BarContainer class={clsx("bg-gray-22")}>
								<AnimatedBarPiece
									value={grade().characteristics!.learnability!}
									hideValue
									key={genBarKey("learnability")}
									rounded
									showFloatingValue
									classBar={barClasses}
									classText={clsx(textClasses)}
								/>
							</BarContainer>
						</Section>
					</Show>
				</Show>
			</Show>
		</div>
	);
};
