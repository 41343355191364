import { clsx } from "~/utils/classes";

export const LoadingSpinner = (props: {
	class?: string;
	ref?: HTMLDivElement | undefined;
}) => {
	return (
		<div>
			<i
				class={clsx("fa fa-spin fa-spinner-third animate-spin block", props.class)}
				ref={props.ref}
			/>
		</div>
	);
};
