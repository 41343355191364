import { isEmpty } from "lodash-es";
import { type Accessor, Show } from "solid-js";
import { Spacer } from "~/components/Space";

import {} from "~/types/OpeningsReport";
import { CARDS_STATE, UI, USER_STATE, quick } from "~/utils/app_state";

import { isChesscards } from "~/utils/env";
import { pluralize } from "~/utils/pluralize";

import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";

import { FeedbackView } from "./FeedbackView";

import { ReviewText } from "./ReviewText";

import { type SidebarAction, SidebarActions, SidebarSectionHeader } from "./SidebarActions";

import { AddCard } from "./AddCard";
import { LoadingSpinner } from "./LoadingSpinner";
import { SidebarTemplate } from "./SidebarTemplate";

export const CardsHome = () => {
	const overallActions: Accessor<SidebarAction[]> = () => {
		const actions: SidebarAction[] = [];
		let cards = CARDS_STATE().cards;
		if (cards) {
			let newCards = cards.filter((c) => c.srs.firstReview);
			if (newCards.length > 0) {
				actions.push({
					text: "Review new mistakes",
					right: (
						<ReviewText
							descriptor="New"
							date={CARDS_STATE().earliestDue}
							numDue={newCards.length}
							iconColor="text-blue-60"
							icon="fa-solid fa-inbox"
						/>
					),
					style: "primary",
					onPress: () => {
						trackEvent("cards_home.practice", { filter: "new" });

						quick((s) => {
							s.cardsState.reviewState.startReview({
								filter: "new",
							});
							return;
						});
						return;
					},
				} as SidebarAction);
			}
		}

		(["opening", "middlegame", "endgame"] as const).forEach((phase) => {
			let phaseCards = cards!.filter((c) => !c.srs.firstReview && c.gamePhase === phase);
			if (phaseCards.length > 0) {
				actions.push({
					text: `Practice ${phase === "opening" ? "opening" : phase === "middlegame" ? "middle-game" : "end-game"} cards`,
					right: (
						<ReviewText
							pluralizeDescriptor={true}
							descriptor={"Card"}
							numDue={phaseCards.length}
							iconColor="text-secondary"
							icon="fa-duotone fa-cards-blank"
						/>
					),
					style: "primary",
					onPress: () => {
						trackEvent("cards_home.practice", { filter: phase });
						quick((s) => {
							s.cardsState.reviewState.startReview({ filter: (c) => c.gamePhase === phase });
							return;
						});
						return;
					},
				} as SidebarAction);
			}
		});

		actions.push({
			text: "Practice due cards",
			right: <ReviewText date={CARDS_STATE().earliestDue} numDue={CARDS_STATE().totalDue} />,
			style: "primary",
			disabled: CARDS_STATE().totalDue === 0,
			onPress: () => {
				trackEvent("cards_home.practice", { filter: "due" });
				quick((s) => {
					s.cardsState.reviewState.startReview({
						filter: "due",
					});
					return;
				});
				return;
			},
		} as SidebarAction);
		return actions;
	};

	const hasCards = () => (CARDS_STATE().cards?.length ?? 0) > 0;
	const loadingMessage = () => {
		// if (isDevelopment) {
		// 	return "Processing 42 games...";
		// }
		if (CARDS_STATE().processingState.processingGames > 0) {
			return `Processing ${pluralize(CARDS_STATE().processingState.processingGames, "game")}...`;
		}
		if (CARDS_STATE().processingState.isFetching) {
			return "Fetching games...";
		}
		if (CARDS_STATE().processingState.processingCards > 0) {
			return `Analyzing ${pluralize(CARDS_STATE().processingState.processingCards, "position")}...`;
		}
		return null;
	};

	const showBigLoader = () => {
		// if (isDevelopment) {
		// 	return true;
		// }
		return !hasCards();
	};
	return (
		<>
			<Show when={showBigLoader()}>
				<div class="flex flex-col items-center justify-center pt-8 md:h-[400px]">
					<LoadingSpinner class="text-4xl text-tertiary" />
					<Show when={loadingMessage()}>
						<p class="text-xs text-tertiary transition-colors  text-center pt-4">
							{loadingMessage()}
						</p>
					</Show>
				</div>
			</Show>
			<Show when={USER_STATE().user && !showBigLoader()}>
				<SidebarTemplate header={null} actions={[]} bodyPadding={false}>
					<SidebarSectionHeader
						text="Learn and review"
						right={
							<Show when={loadingMessage()}>
								<div class="text-xs text-tertiary transition-colors animate-pulse">
									<p>{loadingMessage()}</p>
								</div>
							</Show>
						}
					/>

					<Show when={hasCards()}>
						<div style={stylex(c.column, c.fullWidth, c.gap("10px"))}>
							<Show when={!isEmpty(overallActions())}>
								<SidebarActions actions={overallActions()} />
							</Show>
						</div>
					</Show>
					<Spacer between={["table", "table-header"]} />

					<SidebarSectionHeader text="Manage your cards" />
					<SidebarActions
						actions={[
							{
								onPress: () => {
									quick((_s) => {
										UI().pushView(AddCard);
									});
								},
								text: "Add a card",
								style: "primary",
							},
						]}
					/>
					<Spacer between={["table", "table-header"]} />
					<SidebarSectionHeader text="Contact us" />
					<SidebarActions
						actions={[
							{
								onPress: () => {
									trackEvent("home.contact.discord");
									window.open("https://discord.gg/vNzfu5VetQ", "_blank");
								},
								text: isChesscards ? "Join the Discord" : "Join the Chessbook Discord",
								style: "secondary",
							} as SidebarAction,
							// {
							// 	onPress: () => {
							// 		quick((s) => {
							// 			trackEvent("home.contact.twitter");
							// 			window.open("https://twitter.com/chessbookcom", "_blank");
							// 		});
							// 	},
							// 	text: "Follow us on Twitter",
							// 	style: "secondary",
							// } as SidebarAction,
							{
								onPress: () => {
									quick((_s) => {
										trackEvent("home.contact.feedback");
										UI().pushView(FeedbackView);
									});
								},
								text: "Share your feedback",
								style: "secondary",
							} as SidebarAction,
							// {
							// 	onPress: () => {
							// 		quick((_s) => {
							// 			trackEvent("home.contact.get_sponsored");
							// 			UI().pushView(TemplateSidebarView, {
							// 				props: {
							// 					text: (
							// 						<>
							// 							We're looking for people to help get the word out about Chessbook!
							// 							<Spacer between={["body-text", "body-text"]} />
							// 							If you make chess content and enjoy using this site, please reach out to
							// 							us! We'd love to sponsor you. You can email us at{" "}
							// 							<a href="mailto:marketing@chessbook.com" class="text-primary">
							// 								marcus@chessbook.com
							// 							</a>
							// 							<Spacer between={["body-text", "body-text"]} />
							// 							If you want to earn money from referring people to Chessbook, sign up as
							// 							an affiliate! You'll earn money from every referral that signs up for
							// 							Chessbook. Just go to the{" "}
							// 							<a href="https://chessbook.tolt.io/" class="text-primary">
							// 								chessbook.tolt.io
							// 							</a>
							// 						</>
							// 					),
							// 					header: "Help us spread the word!",
							// 					actions: [],
							// 				},
							// 			});
							// 		});
							// 	},
							// 	text: "Help us spread the word",
							// 	style: "secondary",
							// } as SidebarAction,
						]}
					/>
				</SidebarTemplate>
			</Show>
		</>
	);
};
