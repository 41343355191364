import type { Color } from "@lubert/chess.ts";

export type Side = "black" | "white";

export const SIDES: Side[] = ["white", "black"];

export interface BySide<T> {
	white: T;
	black: T;
}

export function otherSide(side: Side) {
	if (side === "white") {
		return "black";
	}
	return "white";
}

export namespace Side {
	export const toColor = (side: Side): Color => {
		if (side === "white") {
			return "w";
		}
		return "b";
	};

	export const flip = (side: Side): Side => {
		if (side === "white") {
			return "black";
		}
		return "white";
	};

	export const fromColor = (color: Color) => {
		if (color === "w") {
			return "white";
		}
		return "black";
	};
}
