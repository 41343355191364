import type { createForm } from "@felte/solid";
import { type JSX, createEffect, createSignal, splitProps } from "solid-js";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import { FadeInOut } from "./FadeInOut";
import { InputError } from "./forms/InputError";
import { InputLabel } from "./forms/InputLabel";

type TextInputProps = {
	name: string;
	data?: ReturnType<typeof createForm>["data"];
	required?: boolean;
	class?: string;
	inputClass?: string;
	label?: string;
	error?: string;
	setFields: (a: string, val: string) => void;
	padding?: "none";
	errors?: Record<string, string[] | null>;
} & JSX.InputHTMLAttributes<HTMLInputElement>;

type TextAreaProps = {
	ref?: (element: HTMLTextAreaElement) => void;
	data?: ReturnType<typeof createForm>["data"];
	name?: string;
	onBlur?: JSX.EventHandler<HTMLTextAreaElement, FocusEvent>;
	placeholder?: string;
	lines?: number;
	required?: boolean;
	class?: string;
	inputClass?: string;
	label?: string;
	maxLength?: number;
	errors?: Record<string, string[] | null>;
	padding?: "none";
} & Partial<JSX.TextareaHTMLAttributes<HTMLTextAreaElement>>;

/**
 * Text input field that users can type into. Various decorations can be
 * displayed in or around the field to communicate the entry requirements.
 */
export function TextInput(props: TextInputProps) {
	const error = () => {
		return props.errors?.[props.name]?.[0];
	};

	return (
		<div class={clsx(!props.padding && "", props.class)}>
			<InputLabel name={props.name} label={props.label} required={props.required} />
			<input
				onInput={(e) => {
					props.setFields(props.name, e.target.value);
				}}
				onChange={(e) => {
					props.setFields(props.name, e.target.value);
				}}
				class={clsx(
					"bg-gray-4 md:text-md w-full rounded border-2 p-4 placeholder:text-gray-50",
					props.error
						? "border-red-600/50 dark:border-red-400/50"
						: "&hover:border-slate-300 dark:&hover:border-slate-700 border-slate-200 focus:border-sky-600/50 dark:border-slate-800 dark:focus:border-sky-400/50",
					props.inputClass,
				)}
				aria-invalid={!!props.error}
				aria-errormessage={`${props.name}-error`}
				{...props}
			/>
			<InputError name={props.name} error={error()} class={"inline-block"} />
		</div>
	);
}

export function TextArea(props: TextAreaProps) {
	const error = () => {
		if (!props.name) {
			return null;
		}
		return props.errors?.[props.name]?.[0];
	};
	const [, inputProps] = splitProps(props, ["class", "inputClass", "label", "padding"]);
	const [focus, setFocus] = createSignal(false);
	const value = () => (props.name ? props.data?.(($data) => $data[props.name!]) : undefined);
	createEffect(() => {});
	return (
		<div class={clsx(!props.padding && "", props.class)}>
			<InputLabel name={props.name ?? ""} label={props.label} required={props.required} />
			<div class="relative">
				<textarea
					{...inputProps}
					name={props.name}
					class={clsx(
						"md:text-md w-full  border-1 p-4 placeholder:text-gray-50 border-solid",
						error()
							? "border-red-600/50 dark:border-red-400/50"
							: "border-gray-30  focus:border-gray-50",
						props.inputClass,
					)}
					id={props.name}
					aria-invalid={!!error()}
					aria-errormessage={`${props.name}-error`}
					onFocus={() => {
						setFocus(true);
					}}
					onBlur={() => {
						setFocus(false);
					}}
				/>

				<FadeInOut open={!!(props.maxLength && (value()?.length ?? 0) > 0)}>
					<div
						style={stylex(
							c.absolute,
							c.bottom(12),
							c.zIndex(100),
							c.right(12),
							c.row,
							c.justifyBetween,
							c.opacity(focus() ? 100 : 0),
						)}
					>
						<p
							style={stylex(
								value()?.length > props.maxLength! && c.weightBold,
								c.fg(value()?.length > props.maxLength! ? c.red[60] : c.gray[50]),
							)}
						>
							{value()?.length ?? 0}/{props.maxLength}
						</p>
					</div>
				</FadeInOut>
			</div>
			<InputError name={props.name!} error={error()} class={"inline-block"} />
		</div>
	);
}
