import type { CourseMoveDTO } from "~/rspc";
import type { StockfishMove } from "~/utils/stockfish";
import type { TableResponseScoreSource } from "~/utils/table_scoring";
import type { MoveRating } from "./MoveRating";
import type { MoveTag } from "./MoveTag";
import type { PositionReport } from "./PositionReport";
import type { RepertoireMiss } from "./RepertoireGrade";
import type { RepertoireMove } from "./RepertoireMove";
import type { Side } from "./Side";
import type { StockfishEval } from "./StockfishEval";
import { SuggestedMove } from "./SuggestedMove";

export interface TableResponse {
	repertoireMove?: RepertoireMove;
	suggestedMove?: Readonly<SuggestedMove>;
	courseMove?: Readonly<CourseMoveDTO>;
	reviewStatus?: { earliestDue: string; due: number };
	transposes?: boolean;
	lowConfidence?: boolean;
	biggestMiss?: RepertoireMiss;
	coverage?: number;
	moveRating?: MoveRating;
	stockfishMove?: StockfishMove;
	score?: number;
	scoreTable?: ScoreTable;
	side: Side;
	tags: MoveTag[];
	reviewInfo?: {
		hideSan: boolean;
		hideAnnotations: boolean;
		learning?: boolean;
	};
}

export interface ScoreTable {
	factors: ScoreFactor[];
	notes: string[];
}

export interface ScoreFactor {
	weight?: number;
	max?: number;
	min?: number;
	value: number;
	source: TableResponseScoreSource;
	total?: number;
}

export type StockfishSource = "local" | "database";

export namespace TableResponse {
	export const getSan = (tr: TableResponse) => {
		return (
			tr.suggestedMove?.sanPlus ??
			tr.repertoireMove?.sanPlus ??
			tr.courseMove?.sanPlus ??
			tr.stockfishMove?.san!
		);
	};
	export const getEpdAfter = (tr: TableResponse) => {
		return tr.suggestedMove?.epdAfter ?? tr.repertoireMove?.epdAfter ?? tr.courseMove?.epdAfter;
	};
	export const getStockfishEval = (tr: TableResponse): StockfishEval | undefined => {
		return tr.suggestedMove?.stockfish ?? tr.stockfishMove?.stockfishEval;
	};
	export const getStockfishEvalSource = (tr: TableResponse): StockfishSource | undefined => {
		if (tr.suggestedMove?.stockfish) {
			return "database";
		}
		if (tr.stockfishMove?.stockfishEval) {
			return "local";
		}
	};

	export const getPlayRate = (
		tr: TableResponse,
		positionReport: PositionReport,
		opts: { usePeerRates: boolean },
	): number | null | undefined => {
		if (!tr.suggestedMove) {
			return null;
		}
		return SuggestedMove.getPlayRate(tr.suggestedMove, positionReport, !opts.usePeerRates);
	};
}
