import client from "~/utils/client";
import { APP_STATE } from "./app_state";

export async function fetchUser() {
	const { data: user } = await client.get("/api/user");
	return user;
}

export function getAuthHeaders(): any {
	const headers = {} as any;
	const { token, tempUserUuid } = APP_STATE().userState;
	const { spoofedEmail } = APP_STATE().adminState;
	const spoofKey = import.meta.env.VITE_SPOOF_KEY;
	if (spoofedEmail.value) {
		headers["spoof-user-email"] = spoofedEmail.value;
		headers["spoof-key"] = spoofKey;
	}
	if (token.value) {
		headers.Authorization = token.value;
	} else if (tempUserUuid.value) {
		headers["temp-user-uuid"] = tempUserUuid.value;
	}
	return headers;
}
