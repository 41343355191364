import type { CourseOverviewDTO } from "~/rspc";
import { getStatic } from "~/utils/assets";
import { c, stylex } from "~/utils/styles";

export const CourseAvatar = (props: { course: CourseOverviewDTO; size: number }) => {
	return (
		<div style={stylex(c.size(props.size))} class="rounded-full overflow-hidden shrink-0">
			<img src={getStatic(`/course_avatars/${props.course.avatarImage}`)} />
		</div>
	);
};
